import { Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../store/app';

const ResponseDialog = (props: any) => {
  const dispatch = useDispatch();
  const responseState = useSelector((state: any) => state.app.httpResponse);
  const [open, setOpen] = useState(false);

  console.log('dd ', responseState.message);

  useEffect(() => {
    if (responseState.statusCode !== 0) {
      setOpen(true);
      setTimeout(() => {
        dispatch(appActions.resetError());
        setOpen(false);
      }, 2000);
    }
  }, [dispatch, responseState.statusCode]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      hideBackdrop={true}
      PaperProps={{ sx: { position: 'fixed', top: 10, m: 0 } }}
    >
      {(responseState.status === 'error' || responseState.status === 'fail') && (
        <Alert severity="error" onClose={() => props.setOpen(false)}>
          {responseState.message}
        </Alert>
      )}
      {responseState.status === 'success' && (
        <Alert
          onClose={() => props.setOpen(false)}
          iconMapping={{
            success: <CheckCircleOutlineIcon fontSize="inherit" />,
          }}
        >
          {responseState.message}
        </Alert>
      )}
    </Dialog>
  );
};

export default ResponseDialog;
