import { useEffect, useState } from 'react';
import { Box, Chip, Grid, Pagination, Stack, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import styles from '../index.module.scss';
import TopActions from '../../../components/TopActions';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import { appActions } from '../../../store/app';
import { useNavigate } from 'react-router-dom';
import { getPurchases, searchPurchases } from '../../../store/purchases/purchasesActions';
import { authActions } from '../../../store/auth';
import { ShopState, shopActions } from '../../../store/shop';
import PurchaseDetails from './PurchaseDetails';
import illustration from '../../../assets/images/illu_purchases.png';
import { Purchase } from '../../../interfaces/shop/purchase';
import { calculateTotalPrice } from '../../../utils/functions';
import ListLoadingItem from '../../../components/ListLoadingItem';
import NoData from '../../../components/NoData';
import NewShopPurchase from './NewPurchase';
import FilterPurchases from './FilterPurchases';
import { ChipData } from '../../../interfaces/construction/constants';
import { Supplier } from '../../../interfaces/shop/suppliers';
import FormInputs from '../../../components/inputs/FormInputs';
import { purchasesActions, PurchaseState } from '../../../store/purchases';

const Purchases = () => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isInit, setIsInit] = useState(true);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const purchasesState: PurchaseState = useSelector((state: any) => state.purchases);
  const token = Storage.getToken();
  const [purchase, setPurchase] = useState<Purchase | null>();
  const [updatePurchase, setUpdatePurchase] = useState(false);
  const navigate = useNavigate();
  const project = Storage.getProject()._id;
  const appState = useSelector((state: any) => state.app);
  const [showFilter, setShowFilter] = useState(false);
  const [chipData, setChipData] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState('');
  const [formState, setFormState] = useState({
    payment: {} as any,
    supplier: {} as Supplier | any,
    supplierText: '' as string | any,
    dateFrom: null as any,
    dateTo: null as any,
  });

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips: any[]) => chips.filter((chip: ChipData) => chip.key !== chipToDelete.key));
    if (chipToDelete.label === 'Supplier') {
      setFormState({ ...formState, supplier: {}, supplierText: '' });
    }
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value - 1);
    dispatch(purchasesActions.setFetchedPurchases(false));
  };

  const onSupplierChanged = (supplier: any | Supplier, isObject: boolean) => {
    if (isObject) {
      setFormState({ ...formState, supplier, supplierText: '' });
      let newChip = {
        key: chipData[chipData.length - 1]?.key,
        filter: supplier._id,
        id: 'supplier',
        label: 'Supplier',
        value: supplier?.name,
      };
      setChipData([...chipData, newChip]);
    } else {
      setFormState({ ...formState, supplierText: supplier, supplier: null });
    }
  };

  const dispatch = useDispatch();

  const filterPurchaseElements = [
    {
      identifier: 'supplier',
      inputComponent: 'select',
      values: shopState.suppliers,
      onChange: (supplier: Supplier | string, isObject: true) => onSupplierChanged(supplier, isObject),
      value: formState?.supplier?.name || formState.supplierText,
      name: 'name',
      id: '_id',
    },
  ];

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = '';
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(purchasesActions.setFetchedPurchases(false));
    } else if (chipData.length === 0 && query !== '') {
      setQuery('');
      dispatch(purchasesActions.setFetchedPurchases(false));
    }
  }, [chipData]);

  useEffect(() => {
    if (!purchasesState.fetchedPurchases) {
      dispatch(
        getPurchases(`?project=${project}&page=${page}&limit=${rowsPerPage}&sort=-date&action=purchase${query}`, token)
      );
    }
  }, [page, rowsPerPage, dispatch, token, purchasesState.fetchedPurchases, project]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchPurchases(`?name=${searchText}&project=${project}&action=purchase${query}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && purchasesState.fetchedPurchases && !isInit) {
          dispatch(purchasesActions.setFetchedPurchases(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token]);

  useEffect(() => {
    if (
      appState.error &&
      appState.errorStatusCode === 401 &&
      (appState.errorMessage === 'The user belonging to this user does no longer exist.' ||
        appState.errorMessage === 'Unauthorized')
    ) {
      dispatch(authActions.logout());
      dispatch(appActions.resetError());
      return navigate('/login');
    }
  }, [appState.error, appState.errorStatusCode, appState.errorMessage, dispatch, navigate]);

  let pages = Math.ceil(purchasesState.purchasesCount / count);

  return (
    <>
      <TopActions
        setNew={() => {
          setPurchase(null);
          dispatch(appActions.setOpenForm({ open: true, action: 'add' }));
        }}
        entity="Purchase"
        setSearchText={setSearchText}
        addNew={true}
      />
      {chipData.length > 0 && (
        <Stack direction="row" spacing={1} style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          {chipData.map((data: any) => {
            const value = data?.value ? data.value : data.filter;
            return (
              <Chip
                key={data.key}
                label={`${data.label}: ${value}`}
                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              />
            );
          })}
        </Stack>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="bg-white pt-3">
            <div className="flex items-center">
              <div className="ml-5 w-full">
                {!fetching && (
                  <Box className="flex">
                    {filterPurchaseElements.map((element: any, index: number) => {
                      return <FormInputs key={index} element={element} />;
                    })}
                    <div className="mr-4">
                      <FilterListIcon className="mt-4" onClick={() => setShowFilter(true)} />
                    </div>
                  </Box>
                )}
              </div>
            </div>
            <hr />
            {!appState.isFetching && (
              <div style={{ height: '73vh', overflowY: 'scroll' }}>
                {purchasesState?.purchases?.length > 0 &&
                  purchasesState?.purchases?.map((currentPurchase: Purchase) => (
                    <div
                      key={currentPurchase._id}
                      style={{
                        backgroundColor: currentPurchase._id === purchase?._id ? '#EBF6FF' : 'white',
                      }}
                      className="p-2 m-4 rounded-md cursor-pointer shadow-md"
                      onClick={() => setPurchase(currentPurchase)}
                    >
                      <div className="flex justify-between">
                        <div>{currentPurchase?.supplier?.name ?? 'Supplier'}</div>
                        <Box className={currentPurchase?.isCredit ? styles.payment_credit : styles.payment_paid}>
                          <Typography style={{ fontSize: '0.8rem !important' }}>
                            {currentPurchase?.isCredit ? 'Credit' : 'Paid'}
                          </Typography>
                        </Box>
                      </div>
                      <div>{currentPurchase.list.length} Items</div>
                      <div className="flex justify-between">
                        <div className="font-bold">{calculateTotalPrice(currentPurchase.list)} RWF</div>
                        <div>{currentPurchase.date.substring(0, 10).replaceAll('-', '.')}</div>
                      </div>
                    </div>
                  ))}
                <hr className="mb-2" />
                <div className="ms-auto w-[70%]">
                  <Pagination count={pages} page={page + 1} onChange={handlePageChange} size="small" />
                </div>
                {purchasesState?.purchases?.length === 0 && <NoData />}
              </div>
            )}
            {appState.isFetching && (
              <div style={{ height: '32.3rem' }}>
                <ListLoadingItem />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={8}>
          {purchase && <PurchaseDetails purchase={purchase} close={() => setPurchase(null)} />}
          {!purchase && (
            <div className="h-[81vh] bg-white w-full flex items-center justify-center">
              <img className="w-[18vw] h-[40vh]" src={illustration} alt="Purchase" />
            </div>
          )}
        </Grid>
      </Grid>

      {showFilter && <FilterPurchases filterHandler={filterHandler} open={showFilter} setOpen={setShowFilter} />}
      {appState.openForm.open && <NewShopPurchase purchase={purchase} close={() => dispatch(appActions.closeForm())} />}
    </>
  );
};

export default Purchases;
