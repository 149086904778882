import { User } from '../Project/user';

export interface shopSupplierColumn {
  id: 'name' | 'contact' | 'tinNumber' | 'debt' | 'action';
  label: String;
  minWidth?: number;
  dataType?: String;
  align?: 'center';
}

export const shopSupplierColumns: shopSupplierColumn[] = [
  { id: 'name', label: 'Supplier Name', align: 'center' },
  { id: 'contact', label: 'Contact', align: 'center', dataType: 'number' },
  { id: 'tinNumber', label: 'Tin Number', align: 'center', dataType: 'number' },
  { id: 'debt', label: 'Debt', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopSuppliersData {
  id: string;
  name: string;
  contact: string;
  tinNumber: string;
  debt: string;
  action: any;
}

export interface Supplier {
  _id: string;
  name: string;
  contact?: string;
  tinNumber?: number;
  debt: number;
  project?: string;
  isActive: Boolean;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

