import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { useEffect, useState } from 'react';
import { appActions } from '../../../store/app';
import GenericForm from '../../../components/GenericForm';
import { Client } from '../../../interfaces/construction/clients';
import { PaymentMethod } from '../../../interfaces/Project/paymentMethods';
import { getNumberWithCommas, numberWithCommas } from '../../../utils/functions';
import { addAction, updateAction } from '../../../store/shop/actions/globalActions';
import Storage from '../../../utils/Storage';
import { addShopExpenseService, updateShopExpenseService } from '../../../store/shop/expenses/shopExpenseService';
import { shopActions } from '../../../store/shop';
import { Form } from 'antd';
import moment from 'moment';

interface NewExpenseProps {
  open: boolean;
  close: Function;
  expense?: any;
}

export default function NewExpense(props: NewExpenseProps) {
  const dispatch = useDispatch();
  const [client, setClient] = useState({} as Client);
  const [paymentMethod, setPaymentMethod] = useState({} as PaymentMethod);

  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const [form] = Form.useForm();

  useEffect(() => {
    if (appState.openForm.action === 'update') {
      form.setFieldsValue({
        description: props?.expense?.description,
        amount: numberWithCommas(props?.expense?.amount),
        date: props?.expense?.date?.substring(0, 10),
      });
      setPaymentMethod(props?.expense?.paymentMethod);
    }
  }, []);

  const ExpensesCategories: any = [
    {
      name: 'House Rent',
      _id: 'house Rent',
    },
    {
      name: 'Food',
      _id: 'food',
    },
    {
      name: 'Electricity',
      _id: 'Electricity',
    },
    {
      name: 'Transport',
      _id: 'transport',
    },
    {
      name: 'Water Bill',
      _id: 'water Bill',
    },
    {
      name: 'Taxes',
      _id: 'taxes',
    },
    {
      name: 'Wifi',
      _id: 'wifi',
    },
    {
      name: 'Other',
      _id: 'other',
    },
  ];

  const onAmountChanged = () => {
    const amount = getNumberWithCommas(form.getFieldValue('amount'));
  };

  const submit = async (data: any) => {
    data.amount = getNumberWithCommas(data.amount);
    data.project = store.get('project')._id;
    data.date = form.getFieldValue('date')
      ? moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + new Date().toISOString().substring(10)
      : new Date().toISOString();
    if (appState.openForm.action === 'update') {
      dispatch(
        updateAction(updateShopExpenseService, props.expense._id, data, token, () =>
          dispatch(appActions.setUpdateNeeded(true))
        )
      );
    } else {
      dispatch(
        addAction(addShopExpenseService, data, token, () => dispatch(shopActions.setFetchedShopExpenses(false)))
      );
    }
  };

  const onClientChanged = (element: Client) => {
    setClient(element);
  };

  const newExpenseElements = [
    {
      label: 'Description',
      name: 'description',
      type: 'text',
      placeholder: 'Description',
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Amount',
      name: 'amount',
      type: 'text',
      placeholder: 'Cash Out',
      rules: [{ required: true, message: 'Required field' }],
    },
    {
      label: 'Select',
      name: 'select',
      type: 'select',
      options: ExpensesCategories,
      rules: [{ required: true, message: 'Please enter the your option' }],
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      placeholder: 'Enter Start Date',
      required: true,
    },
  ];

  return (
    <GenericForm
      title={appState.openForm.action === 'update' ? 'Update Expense' : 'New Shop Expense'}
      onSubmit={submit}
      formElements={newExpenseElements}
      style="horizontal"
      form={form}
      open={appState.openForm.open}
      close={() => props.close()}
    />
  );
}
