import { Customer } from './../../interfaces/shop/customers';
import { createSlice } from '@reduxjs/toolkit';
import { DashboardStats, Shop } from '../../interfaces/shop/project';
import { Manufacturer } from '../../interfaces/shop/manufacturers';
import { Supplier } from '../../interfaces/shop/suppliers';
import { Adjustment } from '../../interfaces/shop/adjustment';
import { QuickSale, QuickSalesItem } from '../../interfaces/shop/quickSales';

export interface ShopState {
  shopExpenses: [];
  fetchedShopExpenses: boolean;
  customers: Customer[];
  customer: Customer | any;
  manufacturers: [];
  payments: [];
  suppliers: Supplier[];
  supplier: Supplier | any;
  stock: [];
  outOfStock: [];
  dashboard: {
    isFetching: boolean;
    stats: DashboardStats;
  };
  fetchedManufacturers: boolean;
  fetchedSuppliers: boolean;
  fetchedCustomers: boolean;
  fetchedPayments: boolean;
  fetchedStock: boolean;
  fetchedOutOfStock: boolean;
  fetchingOutOfStock: boolean;
  selectedManufacturer: Manufacturer | {};
  selectedCustomer: {};
  addShopSales: [];
  shopCustomers: Customer[];
  shopSuppliers: Supplier[];
  shopStock: [];
  profit: [];
  adjustments: Adjustment[];
  shopManufacturers: Manufacturer[];
}

const initialState: ShopState = {
  shopExpenses: [],
  fetchedShopExpenses: false,
  customers: [],
  customer: {},
  manufacturers: [],
  payments: [],
  suppliers: [],
  supplier: {},
  stock: [],
  outOfStock: [],
  dashboard: {
    isFetching: false,
    stats: {} as DashboardStats,
  },
  fetchedManufacturers: false,
  fetchedSuppliers: false,
  fetchedCustomers: false,
  fetchedPayments: false,
  fetchedStock: false,
  fetchedOutOfStock: false,
  fetchingOutOfStock: false,
  selectedManufacturer: {},
  selectedCustomer: {},
  addShopSales: [],
  shopCustomers: [],
  shopSuppliers: [],
  shopStock: [],
  profit: [],
  adjustments: [],
  shopManufacturers: [],
};

const shopSlice = createSlice({
  name: 'shopProject',
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setCustomer(state, action) {
      state.customer = action.payload;
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    setSupplier(state, action) {
      state.supplier = action.payload;
    },
    setManufacturers(state, action) {
      state.manufacturers = action.payload;
    },
    setPayments(state, action) {
      state.payments = action.payload;
    },
    setShopExpenses(state, action) {
      state.shopExpenses = action.payload;
    },
    setFetchedShopExpenses(state, action) {
      state.fetchedShopExpenses = action.payload;
    },
    setStock(state, action) {
      state.stock = action.payload;
    },
    setOutOfStock(state, action) {
      state.outOfStock = action.payload;
    },
    setFetchedManufacturers(state, action) {
      state.fetchedManufacturers = action.payload;
    },
    setFetchedStock(state, action) {
      state.fetchedStock = action.payload;
    },
    setFetchedSuppliers(state, action) {
      state.fetchedSuppliers = action.payload;
    },
    setFetchedCustomers(state, action) {
      state.fetchedCustomers = action.payload;
    },
    setFetchedPayments(state, action) {
      state.fetchedPayments = action.payload;
    },
    setFetchedOutOfStock(state, action) {
      state.fetchedOutOfStock = action.payload;
    },
    setFetchingOutOfStock(state, action) {
      state.fetchingOutOfStock = action.payload;
    },
    setSelectedManufacturer(state, action) {
      state.selectedManufacturer = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard.stats = action.payload;
    },
    setProfit(state, action) {
      state.profit = action.payload;
    },
    setFetchingDashboard(state, action) {
      state.dashboard.isFetching = action.payload;
    },
    setAdjustments(state, action) {
      state.adjustments = action.payload;
    },
    resetShopState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
