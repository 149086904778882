import { Grid, Typography } from '@mui/material';
import styles from '../../shop/index.module.scss';

export interface UserCardValuesProps {
  label: string;
  value: string;
}

const UserCardValues = (props: UserCardValuesProps) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <div className={styles.userCardLabel}>{props.label} :</div>
      </Grid>
      <Grid item xs={9}>
        <div className="whitespace-nowrap font-Poppins truncate">{props.value}</div>
      </Grid>
    </Grid>
  );
};

export default UserCardValues;
