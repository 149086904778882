import { Box, Button, MenuItem, Select, TablePagination } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from 'antd';
import styles from '../../index.module.scss';
import { appActions } from '../../../../store/app';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import LoadingContainer from '../../../../components/LoadingContainer';
import UploadImage from '../../../../components/UploadImage';
import ConfirmModal from '../../../../components/ConfirmModal';
import ProductCard from './ProductCard';
import { uploadProductImageService } from '../../../../store/items/itemsService';
import { ShopState } from '../../../../store/shop';
import Storage from '../../../../utils/Storage';
import { Product } from '../../../../interfaces/shop/product';
import { getProducts, searchProducts } from '../../../../store/products/productActions';
import NewProduct from './NewProduct';
import { productActions, ProductsState } from '../../../../store/products';

const Products = () => {
  const [searchText, setSearchText] = useState('');
  const manufacturers = useSelector((state: any) => state.shop.manufacturers);
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const productsState: ProductsState = useSelector((state: any) => state.products);
  const [product, setProduct] = useState<Product | null>(null);
  const project = Storage.getProject()?._id;
  const [isInit, setIsInit] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [query, setQuery] = useState('');
  const token = Storage.getToken();
  const [uploadPicture, setUploadPicture] = useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    dispatch(productActions.setFetchedProducts(false));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(productActions.setFetchedProducts(false));
  };

  const uploadImageHandler = async (data: any) => {
    try {
      if (product?._id !== null) {
        setUploading(true);
        const res = await uploadProductImageService(product!._id, data, token);
        setUploading(false);
        if (res.status === 200) {
          dispatch(productActions.setFetchedProducts(false));
          setUploadPicture(false);
        }
      }
    } catch (error) {
      console.log('error ', error);
    }
  };

  useEffect(() => {
    console.log('checking parameters for reload   ', !productsState.fetchedProducts, ' and ', !searchText);
    if (!productsState.fetchedProducts && !searchText) {
      dispatch(getProducts(`?project=${project}&sort=name&page=${page}&limit=${rowsPerPage}${query}`, token));
    }
  }, [page, rowsPerPage, query, token, appState.updateNeeded, dispatch, project, productsState.fetchedProducts, query]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchProducts(`?text=${searchText}&project=${project}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && productsState.fetchedProducts && !isInit) {
          dispatch(productActions.setFetchedProducts(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token, productsState.fetchedProducts]);

  return (
    <>
      <Box className="flex w-[100%] justify-between items-center my-6">
        <Typography className="font-bold text-xl">Products</Typography>
        {/* <SearchComponent2 placeholder={'Search Products'} setSearchText={setSearchText} /> */}
        <div className="bg-white w-[35vw] flex border border-blue-500 rounded-3xl overflow-hidden">
          <input
            className="basis-11/12 rounded-3xl ps-4 outline-none"
            placeholder="Search Products"
            type="text"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setSearchText(event.target.value);
            }}
          />
          <div className="h-[1.5rem] w-[0.1rem] my-auto me-2 bg-gray-400"></div>
          <div className="w-[20vw]">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="All Categories"
              className="w-[100%] text-xs h-[35px] text-textColor ps-0 outline-none border-none border-transparent"
              value={null}
              onChange={(event: any) => {
                // setManufacturer(event.target.value as string);
              }}
            >
              {manufacturers?.map((option: any) => (
                <MenuItem key={option?._id} value={option?._id}>
                  {option['name']}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="basis-1/12 bg-blue-500 flex justify-center p-2 items-center">
            <SearchIcon style={{ color: 'white' }} />
          </div>
        </div>
        <Button
          className={styles.primaryButton}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          style={{ fontFamily: 'Poppins' }}
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: 'add' }));
          }}
        >
          Add Product
        </Button>
      </Box>
      <div className="flex justify-end mb-5">
        {/* <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} /> */}
        <div className="flex items-center me-5">
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={false}
            onClick={() => {}}
            style={{ fontFamily: 'Poppins' }}
            className={styles.exportExcelButton}
          >
            Products
          </LoadingButton>
        </div>
      </div>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="h-[64vh] overflow-y-scroll flex flex-wrap">
            {productsState?.products?.length > 0 &&
              productsState?.products?.map((item: Product) => (
                <ProductCard
                  onUpdate={() => {
                    setProduct(item);
                    dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                  }}
                  onAdjust={() => {
                    // console.log('on adjustingggggggg ');
                    // setProduct(item);
                    // setAdjustStock(true);
                  }}
                  onDelete={() => {
                    setProduct(item);
                    dispatch(appActions.setIsDeleting(true));
                  }}
                  onUpload={() => {
                    setProduct(item);
                    // setUploadPicture(true);
                  }}
                  key={item._id}
                  product={item}
                />
              ))}
          </div>
          <TablePagination
            component="div"
            count={productsState.productsCount}
            labelRowsPerPage={'Products per page'}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {appState.openForm.open && <NewProduct product={product} />}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => {
                // handleDeleteItem();
              }}
              content={`Please confirm to delete ${product?.name}`}
            />
          )}
          {uploadPicture && (
            <UploadImage
              open={uploadPicture}
              close={() => setUploadPicture(false)}
              title={'Add Product Image'}
              uploading={uploading}
              uploadHandler={uploadImageHandler}
            />
          )}
        </>
        // <ItemsTable
        //   count={count}
        //   page={page}
        //   setPage={setPage}
        //   rowsPerPage={rowsPerPage}
        //   setRowsPerPage={setRowsPerPage}
        // />
      )}
    </>
  );
};

export default Products;
