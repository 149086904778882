export interface Manufacturer {
  _id: string;
  name: string;
  supplier?: string;
  action?: any;
}

export interface shopCustomerColumn {
  id: 'name' | 'supplier' | 'action';
  label: String;
  minWidth?: number;
  dataType?: String;
  align?: 'center';
}

export const manufacturerColumns: shopCustomerColumn[] = [
  { id: 'name', label: 'Manufacturer', align: 'center' },
  { id: 'supplier', label: 'Supplier', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];
