import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getShopStockService = async (query: string, token: string) => {
    return HttpRequest.get(`${SERVER_URL}/shop/stock${query}`, token);
};

export const getOutOfStockService = async (query: string, token: string) => {
    return HttpRequest.get(`${SERVER_URL}/shop/stock/outofstock${query}`, token);
};

export const getCustomStockService = async (query: string, token: string) => {
    return HttpRequest.get(`${SERVER_URL}/shop/stock/custom${query}`, token);
};

export const getExtendedStockService = async (query: string, token: string) => {
    return HttpRequest.get(`${SERVER_URL}/shop/stock/extended${query}`, token);
};

export const searchShopStockService = async (query: string, token: string) => {
    return HttpRequest.get(`${SERVER_URL}/shop/stock/search/item${query}`, token);
};