import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../../components/index.module.scss';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import Storage from '../../../utils/Storage';
import { getShopSalesService } from '../../../store/sales/salesServices';
import { exportExcel } from '../../../utils/functions';
import { saleExportData } from '../../../interfaces/shop/payments';

const ExportSalesForm = (props: any) => {
  const [activeTab, setActiveTab] = useState(props.tabIndex || 0);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 7));
  const project = Storage.getProject();
  const token = Storage.getToken();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setDate(newValue);
  };

  const createExportableData = (
    date: string,
    customer: string,
    item: string,
    quantity: number,
    buyingPrice: number,
    totalCost: number,
    sellingPrice: number,
    totalPrice: number,
    totalPaid: number,
    profit: number
  ): saleExportData => {
    return {
      date,
      customer,
      item,
      quantity,
      buyingPrice,
      totalCost,
      sellingPrice,
      totalPrice,
      totalPaid,
      profit,
    };
  };

  const exportFileHandler = async () => {
    setLoading(true);
    var greaterThan = new Date().toISOString().substring(0, 7);
    var lessThan = new Date().toISOString().substring(0, 7);
    if (activeTab === 0) {
      greaterThan = moment(`${date}-01`).startOf('M').add(1, 'd').toISOString().substring(0, 10);
      lessThan = moment(`${date}-01`).endOf('M').add(1, 'd').toISOString().substring(0, 10);
    }
    const sales = await getShopSalesService(
      `?project=${project._id}&date[gt]=${greaterThan}&date[lt]=${lessThan}&limit=10000&sort=date&action=sales`,
      token
    );
    console.log('print done ');
    setLoading(false);
    console.log('Less than >> ', project);
    const data = sales?.data?.map((sale: any) =>
      createExportableData(
        sale.date.substring(0, 10),
        sale?.customer?.name,
        sale.item?.name,
        sale.quantity,
        sale.buyingPrice,
        sale.quantity * sale.buyingPrice,
        sale.unitPrice,
        sale.quantity * sale.unitPrice,
        sale.totalPaid,
        sale.quantity * (sale.unitPrice - sale.buyingPrice)
      )
    );
    exportExcel(data, `${project.name} ${date} Sales`);
  };

  return (
    <Dialog classes={{ paper: styles.genericForm }} open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle className={styles.genericForm_header}>
        <Box></Box>
        <Typography className={styles.form_title}>{props?.title}</Typography>

        <CloseIcon className={styles.closeButton} onClick={() => props.setOpen(false)} />
      </DialogTitle>

      <DialogContent>
        <Box sx={{ background: '#Red' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            // variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab label="Monthly Sales" />
            <Tab label="Daily Sales" />
            <Tab label="Custom" />
          </Tabs>
        </Box>
        <Divider />
        <Box>
          {activeTab === 0 && (
            <>
              <Typography>Pick A Month</Typography>
              <input
                style={{ marginLeft: '1rem', marginTop: '1rem' }}
                value={date}
                onChange={onChange}
                type="month"
              ></input>
            </>
          )}
          {activeTab === 2 && (
            <>
              <Typography>Pick A Day</Typography>
            </>
          )}
          {activeTab === 3 && (
            <>
              <Typography>Custom</Typography>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={styles.center}>
          {!loading && (
            <Button
              className={styles.confirmButton}
              onClick={() => exportFileHandler()}
              variant="contained"
              startIcon={<DownloadIcon />}
            >
              Export File
            </Button>
          )}
          {loading && (
            <Button className={styles.confirmButton}>
              <CircularProgress />
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ExportSalesForm;
