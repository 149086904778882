import { BrowserRouter } from 'react-router-dom';
import ResponseDialog from './components/ResponseDialog';
import MainRouter from './routes/MainRouter';

const App = () => {
  return (
    <BrowserRouter >
      <MainRouter />
      <ResponseDialog />
    </BrowserRouter>
  );
};

export default App;
