import { LooseObject } from '../../interfaces/construction/constants';
import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getProductsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/products${query}`, token);
};

export const getProductHistoryService = async (productId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/products/history/${productId}`, token);
};

export const searchProductsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/products/search${query}`, token);
};

export const addProductService = async (data: LooseObject, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/products`, data, token);
};

export const updateProductService = async (productId: string, data: LooseObject, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/products/${productId}`, data, token);
};

export const deleteProductService = async (productId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/products/${productId}`, token);
};

export const uploadProductImageService = async (productId: string, data: LooseObject, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/products/${productId}/uploadimage`, data, token);
};
