export interface ReportColumn {
  id: 'date' | 'name' | 'fileType' | 'action';
  label: string;
  minWidth?: number;
  align?: 'center';
}

export const reportsColumns: ReportColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'name', label: 'name', align: 'center' },
  { id: 'fileType', label: 'File Type', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface ReportRow {
  id: string;
  date: string;
  name: string;
  fileType: any;
  action: any;
}

export interface Report {
  _id: string;
  name: string;
  type: string;
  business: string;
  isPeriodic: boolean;
  fileType: string;
  period: {
    startDate: string;
    endDate: string;
  };
  projectId: any;
  shopId: any;
  createdAt: string;
  updatedAt: string;
}
