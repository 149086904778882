import Drawer from '@mui/material/Drawer';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemHistory } from '../../../store/items/itemsActions';
import Storage from '../../../utils/Storage';
import LoadingContainer from '../../../components/LoadingContainer';
import TableComponent from '../../../components/TableComponent';
import { ItemHistoryColumns } from '../../../interfaces/shop/shopItems';
import { numberWithCommas } from '../../../utils/functions';
import { deleteRed, primaryGreen } from '../../../assets/colors/colors';
import { ShopState } from '../../../store/shop';
import items, { ItemsState } from '../../../store/items';

export const createItemHistoryData = (date: string, action: string, quantity: string, stockBalance: string): any => {
  return {
    date,
    action,
    quantity: (
      <>
        {(action === 'sales' || action === 'adjustment_out') && (
          <Typography style={{ color: deleteRed, fontWeight: 'bolder !important' }}>-{quantity}</Typography>
        )}
        {(action === 'purchase' || action === 'adjustment_in') && (
          <Typography style={{ color: primaryGreen, fontWeight: 'bolder !important' }}>+{quantity}</Typography>
        )}
      </>
    ),
    stockBalance,
  };
};

interface itemViewProps {
  item: any;
  open: boolean;
  setOpen: Function;
}

export default function ItemView(props: itemViewProps) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const itemsState: ItemsState = useSelector((state: any) => state.items);

  useEffect(() => {
    dispatch(getItemHistory(props.item._id, token));
  }, []);

  const rows = itemsState?.item?.history?.map((history: any) =>
    createItemHistoryData(
      history.date.substring(0, 10),
      history.action,
      `${history.quantity / history.item.details.quantity}`,
      `${history.stockBalance / history.item.details.quantity}`
    )
  );
  return (
    <Drawer
      PaperProps={{
        sx: { width: '60vw', padding: '2rem 1rem', background: '#f4f5f7' },
      }}
      anchor={'right'}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      {appState.drawerFetching && <LoadingContainer />}
      {!appState.drawerFetching && (
        <>
          <Box className={`${styles.aRight}`}>
            <CloseIcon onClick={() => props.setOpen(false)} className={`${styles.cPointer}`} />
          </Box>
          <Typography className={`${styles.header}`}>{itemsState?.item?.name}</Typography>
          <Box className={styles.item_details}>
            <Typography className={styles.item_details_text}>Details</Typography>
            <DetailItem label="Buying Price" value={`${numberWithCommas(itemsState?.item?.buyingPrice)} Rwf`} />
            <DetailItem label="Selling Price" value={`${numberWithCommas(itemsState?.item?.sellingPrice)} Rwf`} />
            <DetailItem label="Current Stock" value={itemsState?.item?.stock} />
            <DetailItem label="Packaging " value={itemsState?.item?.bigUnit} />
            <DetailItem
              label="Measurement "
              value={`${itemsState?.item?.details?.quantity} ${itemsState?.item?.details?.measurement} /
              ${itemsState?.item?.bigUnit}`}
            />
            <DetailItem label="Creation Date" value={itemsState?.item?.createdAt?.substring(0, 10)} />
          </Box>
          <Box className={styles.item_history}>
            <Typography className={styles.item_details_text}>History</Typography>
            <TableComponent
              rows={rows}
              columns={ItemHistoryColumns}
              rowClickedHandler={() => {}}
              count={itemsState?.item?.history?.length}
              page={0}
              rowsPerPage={100}
              handlePageChange={() => {}}
              handleRowsPerPageChange={() => {}}
            />
          </Box>
        </>
      )}
    </Drawer>
  );
}

interface detailItemProps {
  label: string;
  value: any;
}

export const DetailItem = (props: detailItemProps) => {
  return (
    <>
      <Box className={styles.item_detail}>
        <Typography>{props.label}</Typography>
        <Typography>{props.value}</Typography>
      </Box>
    </>
  );
};
