import { LooseObject } from '../../../interfaces/construction/constants';
import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getShopExpensesService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/shopexpenses${query}`, token);
};

export const searchShopExpenseService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/shopexpenses/search/shopexpenses${query}`, token);
};

export const addShopExpenseService = async (data: LooseObject, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/shopexpenses`, data, token);
};

export const updateShopExpenseService = async (shopExpenseId: string, data: LooseObject, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/shopexpenses/${shopExpenseId}`, data, token);
};

export const deleteShopExpenseService = async (shopExpenseId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/shopexpenses/${shopExpenseId}`, token);
};
