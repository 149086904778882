import { useDispatch, useSelector } from 'react-redux';
import GenericForm from '../../../components/GenericForm';
import { addShopCustomerService, updateCustomerService } from '../../../store/shop/customers/customersServices';
import Storage from '../../../utils/Storage';
import { addAction, updateAction } from '../../../store/shop/actions/globalActions';
import { appActions } from '../../../store/app';
import { shopActions } from '../../../store/shop';
import { useEffect } from 'react';
import { Form } from 'antd';

export default function NewShopCustomer(props: any) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const appState = useSelector((state: any) => state.app);

  useEffect(() => {
    if (appState.openForm.action === 'update') {
      form.setFieldsValue({
        name: props?.customer?.name,
        contact: props?.customer?.contact,
        tinNumber: props?.customer?.tinNumber,
      });
    }
  }, []);

  const newCustomerElements = [
    {
      label: 'Customer Name',
      name: 'name',
      type: 'text',
      placeholder: 'Enter Item Name',
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Contact',
      name: 'contact',
      type: 'text',
      placeholder: 'Enter Customer Telephone Number',
    },
    {
      label: 'Tin Number',
      name: 'tinNumber',
      type: 'number',
      placeholder: 'Enter Customer Tin Number',
    },
  ];

  const addCustomerHandler = async (values: any) => {
    let data: any = {};
    data.name = values.name;
    data.project = Storage.getProject()._id;
    if (values.contact) data.contact = +values.contact;
    if (values.tinNumber) data.tinNumber = +values.tinNumber;

    // console.log('dataaaa ', data);

    dispatch(addAction(addShopCustomerService, data, token, () => dispatch(shopActions.setFetchedCustomers(false))));
  };

  const updateCustomerHandler = async (data: any) => {
    data.project = Storage.getProject()._id;
    if (data.tinNumber) data.tinNumber = +data.tinNumber;

    if (props.customer._id)
      dispatch(
        updateAction(updateCustomerService, props.customer._id, data, token, () =>
          dispatch(shopActions.setFetchedCustomers(false))
        )
      );
  };

  return (
    <GenericForm
      title="Customer"
      onSubmit={appState.openForm.action === 'update' ? updateCustomerHandler : addCustomerHandler}
      formElements={newCustomerElements}
      style="vertical"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: '' }));
      }}
    />
  );
}
