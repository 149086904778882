import { createSlice } from "@reduxjs/toolkit";
import { Item } from "../../interfaces/shop/shopItems";

export interface ItemsState {
    items: Item[];
    item: Item | any;
    selectedItems: Item[];
    fetchedItems: boolean;
}

const initialState: ItemsState = {
  items: [],
  selectedItems: [],
  item: {},
  fetchedItems: false,
};

const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
      state.selectedItems = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setFetchedItems: (state, action) => {
      state.fetchedItems = action.payload;
    },
    resetItemsState(state) {
      Object.assign(state, initialState);
    }
  },
});

export const itemActions = itemsSlice.actions;

export default itemsSlice.reducer;