import { Grid, Typography } from "@mui/material";
import styles from '../index.module.scss';

export interface UserCardValuesProps {
  label: string;
  value: any;
}

const SupplierCardValues = (props: UserCardValuesProps) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography className={styles.userCardLabel}>{props.label} :</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography className="truncate">{props.value}</Typography>
      </Grid>
    </Grid>
  );
};

export default SupplierCardValues;
