import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import { addAction, updateItemsStock } from '../../../store/shop/actions/globalActions';
import { appActions } from '../../../store/app';
import { useEffect, useState } from 'react';
import { getCustomStockService } from '../../../store/shop/stock/stockService';
import { LooseObject } from '../../../interfaces/construction/constants';
import { createAdjustmentService } from '../../../store/shop/stock/adjustmentService';
import { Item } from '../../../interfaces/shop/shopItems';
import GenericForm from '../../../components/GenericForm';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { ShopState } from '../../../store/shop';

interface AdjustStockProps {
  item: Item | null;
  open: boolean;
  close: Function;
}

export default function AdjustStock(props: AdjustStockProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());
  const token = Storage.getToken();
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      item: props?.item?.name,
      stock: props?.item?.stock,
      date: dayjs(),
    });
  }, []);

  useEffect(() => {
    let timer = setTimeout(async () => {
      console.log('date changeddd 1 ');
      if (
        form.getFieldValue('date').toDate().toISOString().substring(0, 10) !== new Date().toISOString().substring(0, 10)
      ) {
        console.log('adjustinggggggg ');
        setLoading(true);
        const stock = await getCustomStockService(
          `?upToDate=${form.getFieldValue('date')}&_id=${props.item?._id}`,
          token
        );
        console.log('adjustinggggggg 222222222222222 ', stock);
        form.setFieldValue('stock', stock?.data[0]?.stock);
        setLoading(false);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, date, form.getFieldValue('date'), props?.item?._id, token]);

  const adjustStockElements = [
    {
      label: 'Item',
      name: 'item',
      type: 'text',
      disabled: true,
    },
    {
      label: 'Current Stock',
      name: 'stock',
      type: loading ? 'loading' : 'text',
      loading,
      disabled: true,
      placeholder: 'Enter Customer Telephone Number',
    },
    {
      label: 'Adjust Stock To',
      name: 'stockBalance',
      type: 'number',
      loading: appState.fetchingFormItems,
      placeholder: 'Stock Ingane Na: ...',
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text',
      loading,
      placeholder: 'Enter Reason for adjusting',
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      placeholder: 'Enter Customer Tin Number',
      onChange: (date: any) => {
        setDate(date);
      },
    },
  ];

  const adjustStockHandler = async (values: any) => {
    if (values.stockBalance !== null) {
      const data: LooseObject = {};
      data.date = values?.date?.toDate() ?? new Date();
      data.project = Storage.getProject()._id;
      if (values.description) data.description = values.description;
      data.quantity = Math.abs(values.stockBalance - values.stock);
      data.item = props?.item?._id;
      data.type = values.stockBalance < values.stock ? 'out' : 'in';
      delete data.stock;
      delete data.stockBalance;

      dispatch(
        addAction(
          createAdjustmentService,
          data,
          token,
          () => {
            props.close();
          },
          () => dispatch(appActions.setUpdateNeeded(true))
        )
      );
    }
  };

  return (
    <GenericForm
      title="Adjust Stock"
      onSubmit={adjustStockHandler}
      formElements={adjustStockElements}
      style="vertical"
      form={form}
      open={props.open}
      close={() => props.close()}
    />
  );
}
