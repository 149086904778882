import { List } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import styles from './index.module.scss';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import NavItem from './NavItem';
import i18n from '../../i18n';

const ShopNavMenu = (props: any) => {
  const { select } = props;
  const location = useLocation();
  const pathName = location.pathname;

  const NavItems = [
    // { label: 'Home', icon: <HomeIcon style={{ color: 'currentColor' }} />, path: '/' },
    {
      label: i18n.t('dashboard'),
      icon: <DashboardIcon style={{ color: 'currentColor' }} />,
      path: `/shop/dashboard`,
    },
    {
      label: i18n.t('sales'),
      icon: <ShoppingCartIcon style={{ color: 'currentColor' }} />,
      path: `/shop/sales/sales`,
    },
    {
      label: i18n.t('purchases'),
      icon: <AddShoppingCartIcon style={{ color: 'currentColor' }} />,
      path: `/shop/purchases/purchases`,
    },
    {
      label: i18n.t('quicksales'),
      icon: <SellOutlinedIcon style={{ color: 'currentColor' }} />,
      path: `/shop/quicksales/quicksales`,
    },
    {
      label: i18n.t('items'),
      icon: <Inventory2Icon style={{ color: 'currentColor' }} />,
      path: `/shop/items`,
    },
    {
      label: i18n.t('report'),
      icon: <DescriptionIcon style={{ color: 'currentColor' }} />,
      path: `/shop/report`,
    },
    {
      label: i18n.t('shopExpenses'),
      icon: <AttachMoneyIcon style={{ color: 'currentColor' }} />,
      path: `/shop/expenses`,
    },
    {
      label: i18n.t('payments'),
      icon: <AttachMoneyIcon style={{ color: 'currentColor' }} />,
      path: `/shop/payments`,
    },
    {
      label: 'Settings',
      icon: <SettingsIcon style={{ color: 'currentColor' }} />,
      path: `/shop/settings/profile`,
    },
  ];

  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link key={index} to={nav.path} className={styles.link}>
          <NavItem nav={nav} selected={pathName.split('/')[2] === nav.path.split('/')[2]} />
        </Link>
      ))}
    </List>
  );
};

export default ShopNavMenu;
