import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getAdjustmentsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/adjustments${query}`, token);
};

export const updateAdjustmentsService = async (adjustmentId: string, data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/shop/adjustments/${adjustmentId}`, data, token);
};

export const deleteAdjustmentsService = async (adjustmentId: string, token: string) => {
  return HttpRequest.delete(`${SERVER_URL}/shop/adjustments/${adjustmentId}`, token);
};
