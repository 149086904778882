import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../interfaces/shop/product";
import { QuickSalesItem } from "../../interfaces/shop/quickSales";

export interface ProductsState {
    products: Product[];
    productsCount: number;
    product: Product | any;
    fetchedProducts: boolean;
}

const initialState: ProductsState = {
    products: [],
    productsCount: 0,
    product: {},
    fetchedProducts: false,
};

const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setProductsCount: (state, action) => {
            state.productsCount = action.payload;
        },
        setProduct: (state, action) => {
            state.product = action.payload;
        },
        setFetchedProducts: (state, action) => {
            state.fetchedProducts = action.payload;
        },
        resetProductsState(state) {
            Object.assign(state, initialState);
        }
    },
});

export const productActions = productsSlice.actions;

export default productsSlice.reducer;