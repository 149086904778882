import { Box, Grid, Typography } from '@mui/material';
import styles from './index.module.scss';

const ReceiptItem = (props: any) => {
  return (
    <Box onClick={() => props.onItemClicked(props.index)} className={styles.receiptItem}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={`${styles.receipt_items_text}`}>{`${props.index + 1}. ${props.item}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>{props.quantity}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>{props.price}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
            {props.quantity * props.price}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReceiptItem;
