import { NewSalesData } from '../../interfaces/shop/sales';
import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getShopSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/sales${query}`, token);
};

export const getShopSalesNewService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/sales/salesnew${query}`, token);
};

export const addSalesService = async (data: NewSalesData, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/sales`, data, token);
};

export const addToSalesListService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/sales/add/${saleId}`, data, token);
};

export const updateSalesService = async (saleId: string, data: string, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/sales/${saleId}`, data, token);
};

export const updateSalesDateService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/sales/date/${saleId}`, data, token);
};

export const deleteSellService = async (saleId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/sales/${saleId}`, token);
};

export const deleteSaleItemService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/sales/remove/${saleId}`, data, token);
};

export const searchSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/sales/search_item${query}`, token);
};
