import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { appActions } from '../../../store/app';
import Storage from '../../../utils/Storage';
import { Manufacturer } from '../../../interfaces/shop/manufacturers';
import GenericForm from '../../../components/GenericForm';
import { LooseObject } from '../../../interfaces/construction/constants';
import { addAction, updateAction } from '../../../store/shop/actions/globalActions';
import { addItemService, updateItemService } from '../../../store/items/itemsService';
import { shopActions } from '../../../store/shop';
import { Item } from '../../../interfaces/shop/shopItems';
import { Form } from 'antd';
import { itemActions } from '../../../store/items';

interface SelectElement {
  name: string;
}

interface NewShopItemProps {
  item?: any;
}

export default function NewShopItem(props: NewShopItemProps) {
  const [manufacturer, setManufacturer] = useState({} as Manufacturer);
  const manufacturers = useSelector((state: any) => state.shop.manufacturers);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const appState = useSelector((state: any) => state.app);

  const token = Storage.getToken();

  const measurements = [
    {
      name: 'Bucket',
    },
    {
      name: 'Piece',
    },
    {
      name: 'Box',
    },
    {
      name: 'Sack',
    },
  ];

  const smallUnits = [
    {
      name: 'None',
    },
    {
      name: 'Liter',
    },
    {
      name: 'Kg',
    },
    {
      name: 'Meter',
    },
    {
      name: 'Meter2',
    },
  ];

  useEffect(() => {
    if (appState?.openForm?.action === 'update') {
      console.log('props itemmmmmmmmmmmmmm ', props?.item);
      form.setFieldsValue({
        name: props?.item?.name?.toUpperCase(),
        buyingPrice: props?.item?.buyingPrice,
        sellingPrice: props?.item?.sellingPrice,
        barCode: props?.item?.barCode,
        manufacturer: props?.item?.manufacturer as Manufacturer | any,
        manufacturerText: '' as string | any,
      });
    }
  }, []);

  const newItemFormElements = [
    {
      label: 'Item Name',
      name: 'name',
      type: 'text',
      placeholder: 'Enter Item Name',
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Buying Price',
      name: 'buyingPrice',
      type: 'number',
      placeholder: 'Price yo kurangura',
    },
    {
      label: 'Selling Price',
      name: 'sellingPrice',
      type: 'number',
      placeholder: 'Price yo gucuruza',
    },
    {
      label: 'Bar Code',
      name: 'barCode',
      type: 'text',
      placeholder: 'Enter Number on product bar code',
    },
    {
      label: 'Manufacturer',
      type: 'select',
      name: 'manufacturer',
      options: manufacturers,
    },
    // {
    //   label: 'Packaging',
    //   type: 'select',
    //   name: 'bigUnit',
    //   value: formState?.measurement?.name || formState.measurementText,
    //   values: measurements,
    //   onChange: (measurement: any, found: boolean) => onMeasurementChanged(measurement, found),
    // },
    // {
    //   label: 'Measurement',
    //   name: 'measurement',
    //   type: 'select',
    //   value: formState.smallUnit.name || formState.smallUnitText,
    //   values: smallUnits,
    //   onChange: (smallUnit: any, found: boolean) => onSmallQuantityChanged(smallUnit, found),
    //   id: 'name',
    //   name: 'name',
    //   required: false,
    // },
    // {
    //   label: 'Quantity / Unit',
    //   name: 'quantity',
    //   type: 'number',
    //   type: 'text'
    //   placeholder: 'e.g. Indobo Igizwe na Litiro zingahe?',
    //   onChange: (quantity: any) => setFormState({ ...formState, quantity }),
    //   required: true,
    // },
  ];

  const submit = async (values: any) => {
    let data: LooseObject = {};
    data.name = values.name.toLowerCase();
    data.buyingPrice = +values.buyingPrice;
    data.sellingPrice = +values.sellingPrice;
    data.project = Storage.getProject()._id;

    data.bigUnit = 'bucket';
    data.details = {};
    data.details.measurement = 'liter';
    data.details.quantity = 1;
    if (values?.manufacturer) data.manufacturer = values.manufacturer;
    if (values?.barCode) data.barCode = values.barCode;

    if (appState.openForm.action === 'add') {
      dispatch(addAction(addItemService, data, token, () => dispatch(itemActions.setFetchedItems(false))));
    } else {
      if (props?.item?._id)
        dispatch(
          updateAction(updateItemService, props.item._id, data, token, () =>
            dispatch(itemActions.setFetchedItems(false))
          )
        );
    }
  };

  return (
    <GenericForm
      title={appState.openForm.action === 'add' ? 'New Item' : 'Update Item'}
      onSubmit={submit}
      form={form}
      formElements={newItemFormElements}
      open={appState.openForm.open}
      style="horizontal"
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: '' }));
      }}
    />
  );
}
