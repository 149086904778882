import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { shopActions, ShopState } from '../../../store/shop';
import { addAction } from '../../../store/shop/actions/globalActions';
import { paySupplierService } from '../../../store/shop/suppliers/suppliersService';
import { getNumberWithCommas } from '../../../utils/functions';
import Storage from '../../../utils/Storage';
import GenericForm from '../../../components/GenericForm';
import { Form } from 'antd';
import { useEffect } from 'react';
import dayjs from 'dayjs';

interface paySupplierProps {
  open: boolean;
  supplier: Supplier;
  setOpen: Function;
}

export default function PaySupplier(props: paySupplierProps) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const shopState: ShopState = useSelector((state: any) => state.shop);

  const onAmountPaidChanged = (value: any) => {
    const remaining = props.supplier.debt - +form.getFieldValue('amount');
    form.setFieldValue('remaining', remaining);
  };

  useEffect(() => {
    form.setFieldsValue({
      supplier: shopState?.supplier?.name,
      date: dayjs(),
      debt: shopState?.supplier?.debt,
    });
  }, []);

  const newCustomerElements = [
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      required: true,
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Supplier',
      name: 'supplier',
      type: 'text',
      disabled: true,
    },
    {
      label: 'Debt',
      name: 'debt',
      type: 'text',
      defaultValue: props.supplier.debt,
      disabled: true,
    },
    {
      label: 'Amount Paid',
      name: 'amount',
      type: 'text',
      onChange: onAmountPaidChanged,
      placeholder: 'Enter Supplier Tin Number',
      rules: [{ required: true, message: 'Amount is Required field' }],
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text',
      placeholder: 'Description of payment, if any',
    },
  ];

  const paymentHandler = async (data: any) => {
    data.supplier = props.supplier._id;
    data.debt = props.supplier.debt;
    data.amount = getNumberWithCommas(data.amount);
    if (!data.description) delete data.description;

    dispatch(
      addAction(paySupplierService, data, token, () => {
        props.setOpen(false);
        dispatch(shopActions.setFetchedSuppliers(false));
      })
    );
  };

  return (
    <GenericForm
      title="Pay Supplier"
      onSubmit={paymentHandler}
      formElements={newCustomerElements}
      style="horizontal"
      form={form}
      open={props.open}
      close={() => {
        props.setOpen(false);
      }}
    />
  );
}
