import { Typography } from 'antd';
import { ShopState } from '../../../store/shop';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import Storage from '../../../utils/Storage';
import {
  calculateAccumulatedBalance,
  renderMoney,
  roundToTwoDecimalPlaces,
} from '../../../utils/functions';
import items, { ItemsState } from '../../../store/items';

const ItemSpecs = () => {
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const itemsState: ItemsState = useSelector((state: any) => state.items);
  let stockBalance: number[] = [];
  const { t } = useTranslation();

  if (itemsState?.item?.history?.length > 0) {
    stockBalance = calculateAccumulatedBalance(itemsState?.item?.history);
  }

  return (
    <div className="w-[70%] mx-auto">
      <Typography className="font-bold text-2xl text-blue-500 mb-2">{itemsState.item?.name}</Typography>
      <hr className="mb-4" />
      {/* {shopState.product?.variants.map((variant: any) => (
                          <div className="mt-1" key={variant?.label}>
                            <div>{variant?.label}</div>
                            <div className="flex mb-2">
                              {variant?.value?.map((value: any) => (
                                <div
                                  className="border rounded-md m-2 px-2 py-1"
                                  key={value}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                            <hr />
                          </div>
                        ))} */}
      <div className="flex justify-between items-center">
        <div className="my-4 flex items-end">
          <Typography className="text-secondaryTextColor font-semibold">Buying Price:</Typography>
          <Typography className=" font-bold ms-4 text-xl">{renderMoney(itemsState.item.buyingPrice)}</Typography>
        </div>
        <div className="my-4 flex items-end">
          <Typography className="text-secondaryTextColor font-semibold">Selling Price:</Typography>
          <Typography className=" font-bold ms-4 text-xl">{renderMoney(itemsState.item.sellingPrice)}</Typography>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-end">
          <Typography className="text-secondaryTextColor font-semibold">Current Stock:</Typography>
          <Typography className=" font-bold ms-12 text-xl">{itemsState?.item?.stock}</Typography>
        </div>
        <div className="flex items-end">
          <Typography className="text-secondaryTextColor font-semibold">
            {Storage.getProject().usesBarCode ? 'Barcode' : 'Brand'}
          </Typography>
          <Typography className=" font-bold ms-12 text-xl">
            {Storage.getProject().usesBarCode ? itemsState?.item?.barCode : itemsState?.item?.manufacturer?.name}
          </Typography>
        </div>
      </div>
      <div className="flex mt-6 py-2 font-semibold">
        <div className="basis-1/12 text-center">Nbr</div>
        <div className="basis-3/12 text-center">Date</div>
        <div className="basis-3/12 text-center">Action</div>
        <div className="basis-3/12 text-center">Quantity</div>
        <div className="basis-2/12 text-center">Balance</div>
      </div>
      <div className="h-[40vh] overflow-y-scroll border">
        {itemsState?.item?.history?.length > 0 &&
          itemsState?.item?.history?.map((historyItem: any, index: number) => (
            <div
              key={index}
              style={{
                background: `${index % 2 === 0 ? '#f5faff' : 'white'}`,
              }}
              className="flex py-2 border-b-2 border-gray-100"
            >
              <div className="basis-1/12 text-center">{index + 1}</div>
              <div className="basis-3/12 text-center">{historyItem.date.substring(0, 10)}</div>
              <div className="basis-3/12 text-center">{t(historyItem.action)}</div>
              <div
                className="basis-3/12 text-center"
                style={{
                  color: historyItem.action === 'purchase' || historyItem.action === 'adj_in' ? 'green' : 'red',
                }}
              >
                {roundToTwoDecimalPlaces(historyItem.quantity)}
              </div>
              <div className="basis-2/12 text-center">{roundToTwoDecimalPlaces(stockBalance[index])}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ItemSpecs;
