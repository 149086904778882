import { Box, Button, Container, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styles from '../../../../components/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReceiptComponent from '../../../../components/ReceiptComponent2';
import { ShopState } from '../../../../store/shop';
import CloseIcon from '@mui/icons-material/Close';
import { addAction } from '../../../../store/shop/actions/globalActions';
import Storage from '../../../../utils/Storage';
import {
  addToSalesListService,
  deleteSaleItemService,
  updateSalesDateService,
} from '../../../../store/sales/salesServices';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { primaryColor } from '../../../../assets/colors/colors';
import { LoadingButton } from '@mui/lab';
import { updateSaleItemService } from '../../../../store/sales/salesItemService';
import { LayoutType, layout } from '../../../../components/GenericForm';
import FormHeader from '../../../../components/FormHeader';
import { Customer } from '../../../../interfaces/shop/customers';
import { Product } from '../../../../interfaces/shop/product';
import { NewQuickSalesData, NewQuickSalesItem } from '../../../../interfaces/shop/quickSales';
import { getProducts } from '../../../../store/products/productActions';
import { getShopCustomers } from '../../../../store/shop/customers/customersActions';
import { addQuickSalesService, addToQuickSalesListService } from '../../../../store/quicksales/quickSalesService';
import { ProductsState } from '../../../../store/products';
import { quickSalesActions, QuickSalesState } from '../../../../store/quicksales';
import { newQuickSale } from '../../../../store/quicksales/quickSalesActions';

interface newPurchaseProps {
  close: Function;
  sale?: any;
}

export default function NewQuickSale(props: newPurchaseProps) {
  const [product, setProduct] = useState({} as Product | undefined);
  const [customer, setCustomer] = useState({} as Customer | any);
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const currentDate = dayjs();
  const [form] = Form.useForm();
  const [index, setIndex] = useState(-1);
  const [list, setList] = useState([] as NewQuickSalesItem[]);
  const [updatingSale, setUpdatingSale] = useState(false);
  const [updatingSaleItem, setUpdatingSaleItem] = useState(false);
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const project = Storage.getProject();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const quickSalesState: QuickSalesState = useSelector((state: any) => state.quicksales);
  const productsState: ProductsState = useSelector((state: any) => state.products);
  const updating = appState.openForm.action === 'update';
  const { Option } = Select;

  const resetFields = (allFields: Boolean = false) => {
    form.resetFields(['product', 'quantity', 'unitPrice', 'totalPrice', 'totalPaid']);
  };

  const addSaleHandler = async () => {
    const data: NewQuickSalesData = {
      date: form.getFieldValue('date')
        ? moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + new Date().toISOString().substring(10)
        : new Date().toISOString(),
      customer: form.getFieldValue('customer'),
      project: project._id,
      list: list,
    };

    dispatch(newQuickSale(data, quickSalesState.quickSales, customer, token));
  };

  const addOneSaleHandler = async (values: any) => {
    delete values.stock;
    delete values.manufacturer;
    values.product = product;
    values.date = form.getFieldValue('date')?.toDate() ?? new Date();
    values.project = project._id;
    values.sales = props.sale?._id;
    setList([...list, values as NewQuickSalesItem]);
    var res = await addToQuickSalesListService(props.sale?._id, values, token);
    dispatch(quickSalesActions.setFetchedQuickSales(false));
    resetFields();
  };

  const validatePositiveNumber = (_: any, value: string) => {
    if (parseFloat(value) <= 0) {
      return Promise.reject('Item out of stock, Please add more in order to sell');
    }
    return Promise.resolve();
  };

  const validateTotalPaid = (_: any, value: string) => {
    if (parseFloat(value) > +form.getFieldValue('totalPrice')) {
      return Promise.reject('Amound paid can not exceed total price');
    } else if (parseFloat(value) < 0) {
      return Promise.reject('Amound paid can not be less than 0');
    }
    return Promise.resolve();
  };

  // const updateSaleItem = async () => {
  //   if (index >= 0) {
  //     setFormLoading(true);
  //     await updatePurchaseItemService(
  //       props?.sale?.list[index]._id,
  //       form.getFieldsValue(),
  //       token
  //     );
  //     setFormLoading(false);

  //     newPurchaseItems[index] = {
  //       ...newPurchaseItems[index],
  //       quantity: +form.getFieldValue("quantity"),
  //       unit: form.getFieldValue("unit"),
  //       unitPrice: form.getFieldValue("unitPrice"),
  //       totalPrice:
  //         +form.getFieldValue("quantity") * +form.getFieldValue("unitPrice"),
  //     };
  //     setNewPurchaseItems([...newPurchaseItems]);
  //   }
  // };

  const updateSaleItem = async () => {
    setUpdatingSaleItem(true);

    if (index >= 0) {
      list[index] = {
        ...list[index],
        product: productsState.products.find((currentItem: Product) => currentItem._id === form.getFieldValue('item'))!,
        quantity: form.getFieldValue('quantity'),
        unitPrice: form.getFieldValue('unitPrice'),
        totalPaid: +form.getFieldValue('totalPaid'),
      };
      // var newList = props?.sale?.list.filter((element: SalesItem) => element;)

      var data = {
        old: props.sale?.list[index],
        new: list[index],
      };
      const res = await updateSaleItemService(props.sale?.list[index]._id, data, token);
      const totalPaid = list.reduce((accumulator, s) => {
        return accumulator + (s?.totalPaid || 0);
      }, 0);

      const totalPrice = list.reduce((accumulator, s) => {
        return accumulator + s.quantity * (s?.unitPrice || 1);
      }, 0);
      const rres = await updateSalesDateService(
        props.sale?._id,
        {
          customer: form.getFieldValue('customer'),
          date: moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + props?.sale?.date.substring(10, 25),
          isCredit: totalPaid < totalPrice,
        },
        token
      );
      setIndex(-1);
      setUpdatingSale(false);
      setList(list.slice());
      resetFields();
      setUpdatingSaleItem(false);
    }
  };

  const deleteSaleItem = async () => {
    if (index >= 0) {
      list.splice(index, 1);
      // var newList = props?.sale?.list.filter((element: SalesItem) => element;)
      setDeleting(true);
      await deleteSaleItemService(props?.sale?._id, props?.sale?.list[index], token);
      setList(list.slice());
      resetFields();
      setDeleting(false);
      setUpdatingSale(false);
    }
  };

  const onItemClicked = async (inIndex: number) => {
    setUpdatingSale(true);
    // getProductItems(newPurchaseItems[inIndex].product);
    setIndex(inIndex);
    form.setFieldsValue({
      item: list?.[inIndex].product?._id,
      quantity: list?.[inIndex].quantity,
      unitPrice: list?.[inIndex].unitPrice,
      totalPrice: list?.[inIndex].unitPrice * list?.[inIndex].quantity,
      totalPaid: list?.[inIndex].totalPaid,
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onSubmit = (values: any) => {
    values.product = product;
    // values.date = values.date?.toDate() ?? new Date();
    // values.project = project._id;
    delete values.stock;
    delete values?.manufacturer;
    delete values.date;
    delete values?.barCode;
    delete values.customer;
    var existingItem = list.findIndex((item) => {
      return item.product._id === values.product._id && item.unitPrice === +values.unitPrice;
    });
    if (existingItem > -1) {
      list[existingItem].quantity += values.quantity;
      list[existingItem].totalPaid += values.totalPaid;
      setList(list.slice());
    } else {
      console.log('values ', values);
      setList([...list, values as NewQuickSalesItem]);
    }

    resetFields();
  };

  const formElements = [
    {
      name: 'customer',
      label: 'Customer',
      type: 'select',
      loading: appState.fetching,
      options: shopState.customers,
      handleSelectChange: (value: any) => {
        let customer: Customer | undefined = shopState?.customers.find(
          (currentItem: Customer) => currentItem?._id === value
        );
        if (customer) {
          setCustomer(customer);
          console.log(' customer ', customer);
        }
      },
      rules: [{ required: true, message: 'Please enter the customer' }],
    },
    {
      name: 'product',
      label: 'Product',
      type: 'select',
      loading: appState.fetching,
      options: productsState.products,
      handleSelectChange: (value: any) => {
        let product: Product | undefined = productsState?.products.find(
          (currentItem: Product) => currentItem?._id === value
        );
        if (product) {
          setProduct(product);
          form.setFieldsValue({
            unitPrice: product.unitPrice,
            quantity: 1,
            totalPaid: product.unitPrice,
            totalPrice: product.unitPrice,
          });
        }
      },
      rules: [{ required: true, message: 'Product is required' }],
    },
    {
      name: 'unitPrice',
      label: 'Unit Price',
      type: 'number',
      rules: [{ required: true, message: 'Please enter the buying Price' }],
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'number',
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue('unitPrice'),
          totalPaid: +value * +form.getFieldValue('unitPrice'),
        });
      },
      rules: [{ required: true, message: 'Please enter the quantity sold' }],
    },
    {
      name: 'totalPrice',
      label: 'Total Price',
      type: 'number',
      onChange: (value: any) => {
        form.setFieldsValue({
          unitPrice: +value / +form.getFieldValue('quantity'),
          totalPaid: +value,
        });
      },
    },
    {
      name: 'totalPaid',
      label: 'Total Paid',
      type: 'number',
      rules: [{ required: true, message: 'Please enter the amount received' }, { validator: validateTotalPaid }],
    },
    {
      name: 'date',
      label: 'Date',
      type: 'date',
    },
  ];

  useEffect(() => {
    if (!productsState.fetchedProducts) {
      dispatch(getProducts(`?project=${project._id}&limit=20000`, token));
    }
    if (!productsState.fetchedProducts) {
      dispatch(getShopCustomers(`?fields=name,tinNumber&debts=false&project=${project._id}`, token));
    }

    if (appState.openForm.action === 'update') {
      setList([...props?.sale?.list]);
      form.setFieldsValue({
        customer: props?.sale?.customer?._id,
        date: dayjs(props?.sale?.date),
      });
    }
    return () => {
      resetFields(true);
    };
  }, []);

  console.log('Listttttttttttttt ', list);

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog
        classes={{ paper: styles.paper }}
        open={appState.openForm.open}
        onClose={() => {
          props.close();
          resetFields(true);
        }}
      >
        <FormHeader
          title={appState.openForm.action === 'add' ? 'Add Sales' : 'Update Sale'}
          close={() => {
            props.close();
            resetFields(true);
          }}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Box className={styles.genericForm_column}>
                <Form
                  {...layout}
                  layout={formLayout}
                  form={form}
                  name="control-hooks"
                  onFinish={updating ? addOneSaleHandler : onSubmit}
                  className="my_horizontal_form"
                >
                  <Box style={{ margin: '0 auto' }}>
                    {formElements.map((element: any) => (
                      <Box key={element.name} className={styles.forms_input_horizontal}>
                        <Form.Item name={element.name} label={element.label} rules={element.rules ? element.rules : []}>
                          {element.type === 'number' && (
                            <InputNumber
                              onKeyDown={handleKeyDown}
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === 'text' && <Input disabled={element?.disabled ?? false} />}
                          {element.type === 'date' && <DatePicker defaultValue={currentDate} />}
                          {element.type === 'select' && (
                            <Select
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={element?.handleSelectChange}
                              filterOption={(input, option) =>
                                (option?.children?.toString() as string).toLowerCase().includes(input.toLowerCase())
                              }
                            >
                              {element.options.map((opt: any) => (
                                <Option key={opt._id} value={opt._id}>
                                  {opt.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                          {element.type === 'barcode' && (
                            <Select
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={element?.handleSelectChange}
                              filterOption={(input, option) =>
                                (option?.children?.toString() as string).toLowerCase().includes(input.toLowerCase())
                              }
                            >
                              {element.options.map((opt: any) => (
                                <Option key={opt.barCode} value={opt.barCode}>
                                  {opt.barCode}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                  </Box>
                  <Box className={styles.center}>
                    {!updatingSale && (
                      <Button type="submit" className={styles.confirmButton} variant="contained">
                        Add
                      </Button>
                    )}
                    {updatingSale && (
                      <>
                        <LoadingButton
                          onClick={() => updateSaleItem()}
                          loading={updatingSaleItem}
                          className={styles.confirmButton}
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                        <LoadingButton
                          loading={deleting}
                          onClick={() => deleteSaleItem()}
                          className={styles.confirmButton}
                          variant="contained"
                        >
                          Delete
                        </LoadingButton>
                        <div
                          className="bg-gray-200 p-1 ms-3 rounded-lg mt-6 cursor-pointer"
                          onClick={() => {
                            setUpdatingSale(false);
                            setIndex(-1);
                            resetFields();
                          }}
                        >
                          <CloseIcon style={{ color: primaryColor }} />
                        </div>
                      </>
                    )}
                  </Box>
                </Form>
                {/* {newSaleElements.map((element: any, index: number) => {
                  return <FormInputs key={index} element={element} style="horizontal" />;
                })} */}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <ReceiptComponent
                title="Quick Sale"
                updating={updating}
                name={customer?.name}
                tin={customer?.tinNumber}
                onItemClicked={onItemClicked}
                list={list.map((item: any) => {
                  return { ...item, item: item?.product };
                })}
                onSave={addSaleHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
