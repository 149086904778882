import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getAdjustmentsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/adjustments${query}`, token);
};

export const getAdjustmentService = async (adjustmentId: String, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/adjustments/${adjustmentId}`, token);
};

export const createAdjustmentService = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/shop/adjustments`, data, token);
};

export const updateAdjustmentService = async (adjustmentId: String, data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/shop/adjustments/${adjustmentId}`, data, token);
};

export const deteAdjustmentService = async (adjustmentId: String, token: string) => {
  return HttpRequest.delete(`${SERVER_URL}/shop/adjustments/${adjustmentId}`, token);
};
