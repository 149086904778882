import styles from '../index.module.scss';
import { Box, Typography, Avatar, Button, Tabs, Divider, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import LoadingContainer from '../../../components/LoadingContainer';
import { numberWithCommas, setResponseAction } from '../../../utils/functions';
import { customerSalesColumns } from '../../../interfaces/shop/customers';
import TableComponent from '../../../components/TableComponent';
import { appActions } from '../../../store/app';
import { RiArrowLeftCircleLine } from 'react-icons/ri';
import { User } from '../../../interfaces/Project/user';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { FiCreditCard } from 'react-icons/fi';
import { PiShareFatFill } from 'react-icons/pi';
import { DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { Purchase } from '../../../interfaces/shop/purchase';
import React from 'react';
import NewShopSupplier from './NewSupplier';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { deleteSupplierService } from '../../../store/shop/suppliers/suppliersService';
import { shopActions, ShopState } from '../../../store/shop';
import PaySupplier from './PaySupplier';
import { getPurchasesService } from '../../../store/purchases/purchasesService';
import { PurchaseItem } from '../../../interfaces/shop/purchaseItem';

interface itemViewProps {
  supplier: Supplier;
  open: boolean;
  setOpen: Function;
}

const SupplierDetail = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const user: User = Storage.getUser();
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [paySupplier, setPaySupplier] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(250);
  const [count, setCount] = useState(25);
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [purchases, setPurchases] = useState<PurchaseItem[]>([]);
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({} as Supplier);

  useEffect(() => {
    const fetchPurchases = async () => {
      setLoading(true);
      const res = await getPurchasesService(`?supplier=${shopState.supplier?._id}&sort=-date`, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        setPurchases(res.data);
      }
      setLoading(false);
    };

    fetchPurchases();
  }, []);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSupplierHandler = () => {
    dispatch(
      deleteAction(deleteSupplierService, supplier._id, token, 'Supplier', () =>
        dispatch(shopActions.setFetchedSuppliers(false))
      )
    );
  };

  // for( const movement of createData){
  //   if(movement> 0){
  //     console.log(`You depost ${movement}`)
  //   }else{
  //     console.log(`loose${movement}`)
  //   }
  // }

  const createData = (
    date: string,
    item: string,
    quantity: number,
    unitPrice: string,
    totalPrice: string,
    totalPaid: string
  ): any => {
    return {
      date,
      item,
      quantity,
      unitPrice,
      totalPrice,
      totalPaid,
    };
  };

  const rows = purchases?.map((purchase: PurchaseItem) =>
    createData(
      purchase.date?.substring(0, 10),
      purchase.item?.name,
      purchase?.quantity / (purchase?.item?.details?.quantity || 1),
      numberWithCommas(purchase?.unitPrice),
      numberWithCommas((purchase?.quantity / (purchase?.item?.details?.quantity || 1)) * purchase?.unitPrice),
      numberWithCommas(purchase?.totalPaid)
    )
  );

  return (
    <div className="h-[85vh] overflow-y-scroll">
      {appState.drawerFetching && <LoadingContainer />}
      {!appState.drawerFetching && (
        <>
          <Box className="bg-white   rounded-lg mt-6">
            <div className="text-2xl flex font-bold gap-3 text-#403E3C ml-6 mt-2 ">
              <RiArrowLeftCircleLine
                className="w-22 h-8 font-bold mt-3 text-[#8A8A8A]"
                onClick={() => {
                  navigate('/shop/purchases/suppliers');
                }}
              />
              <div className="text-[#403E3C] text-base mt-4 mb-4">Transaction history</div>
            </div>
            <Box className=" px-6 ml-6 mr-6 py-9  flex gap-7 rounded-lg border-2 border-gray-200">
              <div className="basis-[10%]">
                <Avatar
                  style={{
                    height: '7rem',
                    width: '7rem',
                  }}
                >
                  <img className={styles.profilePicture} src={user?.image?.url} alt="profile" />
                </Avatar>
              </div>
              <div className="basis-[20%] ">
                <Typography className={`${styles.CustomerName}`}>{shopState?.supplier.name}</Typography>
                <Typography className="flex gap-2">
                  <BsTelephone className="h-7 text-gray-600" />
                  {shopState?.supplier?.contact || 'N/A'}
                </Typography>
                <Typography className="flex gap-2">
                  <AiOutlineMail className="h-7 text-gray-600" />
                  {shopState?.supplier?.tinNumber || 'N/A'}
                </Typography>
                <Typography className="flex gap-2">
                  <IoLocationOutline className="h-7 w-5 text-gray-600" />
                  {`${numberWithCommas(shopState?.supplier.debt)} Rwf`}
                </Typography>
                <Typography className="flex gap-2">
                  <FiCreditCard className="h-7 w-5 text-gray-600" />
                  {shopState?.supplier.createdAt?.substring(0, 10)}
                </Typography>
              </div>
              <div className="flex gap-3 h-10 ml-auto">
                <Button
                  className="w-12  basis-[50%] rounded-lg"
                  variant="outlined"
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                  }}
                >
                  Edit
                </Button>

                <Button
                  className="text-2xl font-serif"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(appActions.setIsDeleting(true));
                  }}
                >
                  Delete
                </Button>
              </div>
            </Box>
            <Typography className={`${styles.headerc}`}>Transaction history</Typography>
            <div className="bg-white  rounded-lg">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="All Transactions" />
                <Tab label="Payment Summary" />
              </Tabs>
              <Divider />
              <Box className="">
                {activeTab === 0 && (
                  <>
                    <div className="flex gap-6 mt-5 ml-5">
                      <Card sx={{ width: 160, background: '#F8F8F8', height: 80 }}>
                        <CardContent>
                          <Typography className="text-[#757D8A]">Total Amount</Typography>
                          <h1 className="text-[#404D61] font-bold">RWF 1,743,000</h1>
                        </CardContent>
                      </Card>
                      <Card sx={{ width: 160, background: '#F044380D' }}>
                        <CardContent>
                          <Typography>Total Debts</Typography>
                          <h1 className="text-[#F04438] font-bold">
                            RWF {`${numberWithCommas(shopState?.supplier.debt)}`}
                          </h1>
                        </CardContent>
                      </Card>
                      <Card sx={{ width: 160, background: '#12B76A0D' }}>
                        <CardContent>
                          <Typography className="text-[#757D8A] font-serif text-base ">Total Amount Paid</Typography>
                          <h1 className="text-[#12B76A] font-bold">
                            Total
                          </h1>
                        </CardContent>
                      </Card>
                      <div className="gap-1 ml-80 flex">
                        <button className="w-24 h-11 ml-4 bg-[#319DFF] rounded-lg flex pt-3 pl-5 font-serif text-[#FFFF]">
                          <PiShareFatFill className="h-4 w-5 mt-1" />
                          Bill
                        </button>
                        <button
                          className=' className="text-2xl font-serif rounded-lg bg-[#12B76A] w-24 h-11 text-[#FFFF]'
                          onClick={() => {
                            setAnchorEl(null);
                            setPaySupplier(true);
                          }}
                        >
                          Pay
                        </button>
                      </div>
                    </div>

                    <div style={{ display: 'flex', marginLeft: '50rem' }}>
                      <div className="pr-4  font-bold text-[#403E3C]">
                        <div className="text-[#403E3C] text-sm mb-4 mt-2  ">Custom range:</div>
                      </div>
                      <div>
                        <RangePicker style={{ width: '16rem' }} />
                      </div>
                      {/* {appState.openForm.open && <NewShopCustomer />} */}
                    </div>
                  </>
                )}

                <TableComponent
                  rows={rows}
                  columns={customerSalesColumns}
                  rowClickedHandler={() => {}}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                />
                {appState.openForm.open && <NewShopSupplier />}
                <ConfirmModal
                  onConfirm={() => {
                    deleteSupplierHandler();
                  }}
                  content={`Please confirm to delete ${shopState?.supplier.name} Supplier`}
                />
                {paySupplier && <PaySupplier open={paySupplier} supplier={supplier} setOpen={setPaySupplier} />}
              </Box>
              {activeTab === 1 && <>{/* payment summary space */}</>}
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default SupplierDetail;
