import { createSlice } from '@reduxjs/toolkit';
import { CompanyExpense } from '../../interfaces/Project/companyExpense';
import { ExpenseCategory } from '../../interfaces/Project/expenseCategory';
import { Notification } from '../../interfaces/Project/Notification';
import { Report } from '../../interfaces/Project/report';
import { Company } from '../../interfaces/Project/company';
import { User } from '../../interfaces/Project/user';

export interface AppState {
  updateNeeded: boolean;
  isFetching: boolean;
  drawerFetching: boolean;
  fetchingFormItems: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  isExportingExcel: boolean;
  fetchedProjects: boolean;
  selectedProject: any;
  projectId: string;
  count: number;
  projects: any[];
  reports: Report[];
  fetchedReports: boolean;
  httpResponse: any;
  companyExpenses: {
    fetched: boolean;
    total: number;
    expenses: CompanyExpense[];
    stats: any;
  };
  expenseCategories: ExpenseCategory[];
  openForm: {
    open: boolean;
    action: string;
  };
  chartData: any;
  notifications: Notification[];
  fetchedNotifications: boolean;
  company: Company | undefined;
  users: User[];
  fetchedUsers: boolean;
}

const initialState: AppState = {
  updateNeeded: false,
  isFetching: false,
  drawerFetching: false,
  fetchingFormItems: false,
  isSubmitting: false,
  isDeleting: false,
  isExportingExcel: false,
  fetchedProjects: false,
  fetchedReports: false,
  selectedProject: {},
  projectId: '',
  count: 0,
  projects: [],
  reports: [],
  companyExpenses: {
    total: 0,
    expenses: [],
    fetched: false,
    stats: [],
  },
  expenseCategories: [],
  notifications: [],
  fetchedNotifications: false,
  openForm: {
    open: false,
    action: '',
  },
  chartData: {},
  httpResponse: {
    statusCode: 0,
    message: '',
    status: '',
  },
  company: undefined,
  users: [],
  fetchedUsers: false,
};

const appSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    setFetchedUsers(state, action) {
      state.fetchedUsers = action.payload;
    },
    changeSelectedProject(state, action) {
      state.selectedProject = action.payload.project;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setUpdateNeeded(state, action) {
      state.updateNeeded = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setDrawerIsFetching(state, action) {
      state.drawerFetching = action.payload;
    },
    setIsSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    setFetchingFormItems(state, action) {
      state.fetchingFormItems = action.payload;
    },
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload.count;
    },
    setProject(state, action) {
      state.projects = action.payload.projects;
      state.updateNeeded = false;
      state.isFetching = false;
    },
    setFetchedCompanyExpenses(state, action) {
      state.companyExpenses.fetched = action.payload;
    },
    setFetchedProjects(state, action) {
      state.fetchedProjects = action.payload;
    },
    setCompanyExpenses(state, action) {
      state.companyExpenses = {
        total: action.payload.total,
        expenses: action.payload.expenses,
        fetched: true,
        stats: action.payload.stats,
      };
    },
    setExpensesCategories(state, action) {
      state.expenseCategories = action.payload;
    },
    resetError(state) {
      state.httpResponse.statusCode = 0;
      state.httpResponse.message = '';
    },
    setHttpResponse(state, action) {
      state.httpResponse.statusCode = action.payload.statusCode;
      state.httpResponse.status = action.payload.status;
      state.httpResponse.message = action.payload.message;
    },
    setOpenForm(state, action) {
      state.openForm = { open: action.payload.open, action: action.payload.action };
    },
    closeForm(state) {
      state.openForm = { open: false, action: '' };
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setFetchedNotifications(state, action) {
      state.fetchedNotifications = action.payload;
    },
    setIsExportingExcel(state, action) {
      state.isExportingExcel = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setFetchedReports(state, action) {
      state.fetchedReports = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setChartData(state, action) {
      state.chartData = {
        labels: action?.payload?.map((data: any) => data._id),
        datasets: [
          {
            label: 'Profit',
            data: action?.payload?.map((data: any) => data?.profit),
            backgroundColor: '#5de89c',
            borderColor: 'orange',
            borderWidth: 2,
            tension: 0.4,
            fill: true,
          },
        ],
      };
    },
    resetAppState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
