import { Outlet, useLocation } from 'react-router-dom';
import ShopNavMenu from '../../../../components/NavMenu/ShopNavMenu';
import Layout from '../../../../components/Layout';
import Storage from '../../../../utils/Storage';

const QuickSalesMain = () => {
  // const salesRoutes = ['Purchases', 'Suppliers'];
  const quickSalesRoutes = [
    { label: 'QuickSales', link: '/shop/quicksales/quicksales' },
    { label: 'Products', link: '/shop/quicksales/products' },
  ];
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <Layout
      title={`${Storage.getProject().name} • QUICKSALES`}
      pages={quickSalesRoutes}
      navMenu={<ShopNavMenu select={3} />}
    >
      <Outlet />
    </Layout>
  );
};

export default QuickSalesMain;
