import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Auth/login';
import Shop from '../pages/shop/dashboard/Dashboard';
import Stock from '../pages/shop/stock/Stock';
import Purchases from '../pages/shop/purchases/Purchases';
import Items from '../pages/shop/items/Items';
import Payments from '../pages/shop/payments/Payments';
import Sales2 from '../pages/shop/sales/Sales2';
import Customers from '../pages/shop/customers/Customers';
import Suppliers from '../pages/shop/suppliers/Suppliers';
import Manufacturers from '../pages/shop/manufacturers/Manufacturers';
import Signup from '../pages/Auth/signup/Signup';
import SalesMain from '../pages/shop/sales/SalesMain';
import PurchasesMain from '../pages/shop/purchases/PurchasesMain';
import ShopExpenses from '../pages/shop/expenses/expenses';
import ShopReport from '../pages/shop/report/Report';
import ItemDetails from '../pages/shop/items/ItemsDetails';
import Register from '../pages/Auth/Register/Register';
import Settings from '../pages/shop/settings/Settings';
import ProfileSettings from '../pages/shop/settings/ProfileSettings';
import UsersSettings from '../pages/shop/settings/UsersSettings';
import ShopsSettings from '../pages/shop/settings/ShopsSettings';
import CustomerDetail from '../pages/shop/customers/CustomerDetail';
import CustomerView from '../pages/shop/customers/CustomerView';
import SupplierDetail from '../pages/shop/suppliers/SupplierDetail';
import QuickSalesMain from '../pages/shop/quickSales/quickSales/QuickSalesMain';
import QuickSales from '../pages/shop/quickSales/quickSales/QuickSales';
import Products from '../pages/shop/quickSales/products/Products';
import ProductDetails from '../pages/shop/quickSales/products/productDetails';



const MainRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Shop />} />
      <Route path="/shop/sales" element={<SalesMain />}>
        <Route path="/shop/sales/sales" element={<Sales2 />} />
        <Route path="/shop/sales/customers" element={<Customers />} />
        <Route path="/shop/sales/customers/customer" element={<CustomerDetail />} />
    
      </Route>
      <Route path="/shop/purchases" element={<PurchasesMain />}>
        <Route path="/shop/purchases/purchases" element={<Purchases />} />
        <Route path="/shop/purchases/suppliers" element={<Suppliers />} />
        <Route path="/shop/purchases/suppliersDetail" element={<SupplierDetail />} />
      </Route>
      <Route path="/shop/quicksales" element={<QuickSalesMain />}>
        <Route path="/shop/quicksales/quicksales" element={<QuickSales />} />
        <Route path="/shop/quicksales/products" element={<Products />} />
        <Route path="/shop/quicksales/products/details" element={<ProductDetails />} />
      </Route>
      <Route path="/shop/settings" element={<Settings />}>
        <Route path="/shop/settings/profile" element={<ProfileSettings />} />
        <Route path="/shop/settings/users" element={<UsersSettings />} />
        <Route path="/shop/settings/shops" element={<ShopsSettings />} />
      </Route>
      <Route path="/shop/stock" element={<Stock />} />
      <Route path="/shop/items" element={<Items />} />
      <Route path="/shop/items/details" element={<ItemDetails />} />
      <Route path="/shop/expenses" element={<ShopExpenses />} />
      <Route path="/shop/report" element={<ShopReport />} />
      <Route path="/shop/payments" element={<Payments />} />
      <Route path="/shop/manufacturers" element={<Manufacturers />} />
      <Route path="/shop/dashboard" element={<Shop />} />
    </Routes>
  );
};

export default MainRouter;
