const translationsKin = {
  dashboard: 'Imbonerahamwe',
  search: 'Shakisha',
  exportExcel: 'Excel',
  months: 'Amezi',
  logout: 'Gusohoka',
  report: 'Raporo',
  addNew: 'Ongeramo',
  firstName: "Izina Ry'irikrisitu",
  lastName: "Izina Ry'umuryango",
  name: 'Izina',
  nationalID: 'Indangamuntu',
  email: 'Imeli',
  position: 'Icyo Akora',
  gender: 'Igitsina',
  dob: "Itariki y'amavuko",
  action: 'Ibindi',
  delete: 'Siba',
  view: 'Reba',
  update: 'Hindura',
  cancel: 'Hagarika',
  confirmDelete: 'Emeza Gusiba',
  confirmDeleteDesc: 'Urahamya ko ushaka gusiba',
  deleteSuccess: 'basibwe neza!',
  male: 'Gabo',
  female: 'Gore',
  addStaff: 'Kwandika Umukozi',
  updateStaff: "Hindura Umwirondoro w'umukozi",
  updateReproduction: "Hindura Amakuru y'imyororokere",
  save: 'Emeza',
  beginingDate: 'Itariki yatangiriye akazi',
  filter: 'Hitamo',
  boar: 'Isekurume',
  numberOfBoars: "Umubare w'isekurume",
  sow: 'Inyagazi',
  numberOfSows: "Umubare w'inyagazi",
  date: 'Itariki',
  createdBy: 'Yinjijwe Na',
  dateOfBirth: "Itariki y'amavuko",
  dateOfMaturing: 'Itariki yo gucuka',
  createdAt: 'Igihe Yinjijwe',
  updatedAt: 'Ubwo Iheruka Guhindurwa',
  category: 'Icyiciro',
  severity: 'Ubukana',
  treatment: 'Umuti',
  work: 'Akazi',
  documentName: "Izina ry'ifishi",
  documentType: "Ubwoko bw'ifishi",
  document: 'Ifishi',
  add: 'Andika',
  days: 'Iminsi',
  cost: 'Igiciro',
  addTreatment: "Ubuzima - Ikibazo cy'itungo",
  requisition: 'Requisition',
  afterNoon: 'Umugoroba',
  morning: 'Igitondo',
  L: 'Litiro',
  Kg: 'Kilogram',
  m: 'Metero',
  welcome: 'Ikaze',
  goodMorning: 'Mwaramutse',
  goodAfterNoon: 'Wiriwe',
  goodEvening: 'Wiriwe',
  goodNight: 'Wiriwe',
  hello: 'Muraho',
  shopValue: 'Agaciro ka stock',
  monthlyProfit: 'Inyungu yuku kwezi',
  monthlyExpenses: "Depanse z'ukwezi",
  totalDebt: 'Umwenda dufite',
  totalCredit: 'Umwenda baturimo',
  profit: 'Inyungu',
  sales: 'Gucuruza',
  purchases: 'Kurangura',
  stock: 'Stock',
  items: 'Ibicuruzwa',
  customers: 'Abakiriya',
  suppliers: 'Abaturanguza',
  payments: 'Kwishyura',
  customer: 'Umukiriya',
  itemName: 'Igicuruzwa',
  quantity: 'Quantity',
  unitPrice: 'Unit Price',
  totalPrice: 'Total Price',
  paid: 'Yarishuye',
  credit: 'Ideni',
  shopExpenses: 'Depanse',
  quicksales: 'Serivisi',
};

export default translationsKin;
