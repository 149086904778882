import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getPurchasesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/purchases${query}`, token);
};

export const getPurchasesNewService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/purchases/purchasenew${query}`, token);
};

export const addPurchasesService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/purchases`, data, token);
};

export const updatePurchasesService = async (purchaseId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/purchases/${purchaseId}`, data, token);
};

export const deletePurchasesService = async (purchaseId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/purchases/${purchaseId}`, token);
};

export const searchPurchasesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/purchases/search_item${query}`, token);
};
