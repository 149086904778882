import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getSuppliersService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/suppliers${query}`, token);
};

export const searchSuppliersService = async (projectId: string, query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/suppliers/search/${projectId}${query}`, token);
};

export const addShopSupplierService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/suppliers`, data, token);
};

export const paySupplierService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/suppliers/payment`, data, token);
};

export const updateSupplierService = async (supplierId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/suppliers/${supplierId}`, data, token);
};

export const deleteSupplierService = async (supplierId: any, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/suppliers/${supplierId}`, token);
};
