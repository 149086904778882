import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../index.module.scss';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import Storage from '../../../utils/Storage';
import TopActions from '../../../components/TopActions';
import ManufacturersTable from './ManufacturersTable';
import NewManufacturer from './NewManufacturer';
import { appActions } from '../../../store/app';

const MANUFACTURERS = () => {
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  return (
    <Layout title={`${Storage.getProject().name} • MANUFACTURERS`} navMenu={<ShopNavMenu select={9} />}>
      <TopActions
        setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
        entity="Manufacturer"
        addNew={true}
      />
      <ManufacturersTable />
      {appState.openForm.open && <NewManufacturer />}
    </Layout>
  );
};

export default MANUFACTURERS;
