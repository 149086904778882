import { shopActions } from '../shop';
import { LooseObject } from '../../interfaces/construction/constants';
import { Item } from '../../interfaces/shop/shopItems';
import { exportExcel, setResponseAction } from '../../utils/functions';
import { appActions } from '../app';
import { addItemService, getItemHistoryService, getItemsService, searchItemsService } from './itemsService';
import { itemActions } from '.';

//CRUD Actions for Shop Items

// CREATE ONE
export const addItem = (data: LooseObject, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await addItemService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(
          appActions.setHttpResponse({
            statusCode: res?.response?.status,
            status: res?.response?.data?.status,
            message: res?.response?.data?.message,
          })
        );
      } else {
        dispatch(appActions.setIsFetching(false));
        dispatch(appActions.setUpdateNeeded(true));
      }
    } catch (err) {
      console.log(err);
      dispatch(appActions.setIsFetching(false));
    }
  };
};

// GET ALL
export const getShopItems = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getItemsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(itemActions.setItems(res.data));
        dispatch(appActions.setCount(res.count));
        dispatch(itemActions.setFetchedItems(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchShopItems = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchItemsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(itemActions.setItems(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(itemActions.setFetchedItems(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportShopItems = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getItemsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let exportData = res.data.map((inData: any, index: number) => {
          return {
            Number: index,
            Name: inData?.name,
            BuyingPrice: inData?.buyingPrice,
            SellingPrice: inData?.sellingPrice,
            Brand: inData?.manufacturer?.name,
          };
        });
        exportExcel(exportData, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getItemHistory = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setDrawerIsFetching(true));
    const res = await getItemHistoryService(itemId, token);
    console.log('errr', res);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(itemActions.setItem(res.data));
    }
    dispatch(appActions.setDrawerIsFetching(false));
  };
};

export const reduceItemStock = (itemId: string, allProducts: Item[], quantity: number) => {
  return async (dispatch: any) => {
    const index = allProducts.findIndex((item: Item) => item._id === itemId);
    if (index !== -1) {
      let updatedItem: Item = { ...allProducts[index] };
      updatedItem.stock -= quantity;
      let newProducts: Item[] = allProducts.slice();
      newProducts[index] = updatedItem;
      dispatch(itemActions.setItems(newProducts));
    }
  };
};

export const increaseItemStock = (itemId: string, allProducts: Item[], quantity: number) => {
  return async (dispatch: any) => {
    const index = allProducts.findIndex((item: Item) => item._id === itemId);
    if (index !== -1) {
      let updatedItem: Item = { ...allProducts[index] };
      updatedItem.stock += quantity;
      let newProducts: Item[] = allProducts.slice();
      newProducts[index] = updatedItem;
      dispatch(itemActions.setItems(newProducts));
    }
  };
};
