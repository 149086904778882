import { Box, Tab, Tabs } from '@mui/material';
import './style.css';
import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import { getOutOfStockAction } from '../../../store/shop/stock/stockActions';
import NotificationItem from './NotificationItem';
import { roundToTwoDecimalPlaces } from '../../../utils/functions';
import { ShopState } from '../../../store/shop';

const Notifications = () => {
  const dispatch = useDispatch();
  const project = Storage.getProject()._id;
  const token = Storage.getToken();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // if (!shopState.fetchedOutOfStock) {
    dispatch(getOutOfStockAction(`?project=${project}`, token));
    // }
  }, [shopState.fetchedOutOfStock, project]);

  return (
    <Box className="chatContainer">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        <Tab label="Stock" />
        <Tab label="Notifications" />
      </Tabs>

      {activeTab === 1 && <Typography>Nots</Typography>}
      {activeTab === 0 && (
        <>
          {shopState?.outOfStock?.length > 0 &&
            shopState?.outOfStock?.map((stock: any, index: number) => (
              <NotificationItem
                key={index}
                name={stock?.name}
                stock={roundToTwoDecimalPlaces(stock?.stock)}
                manufacturer={stock?.manufacturer?.name}
              />
            ))}
        </>
      )}
    </Box>
  );
};

export default Notifications;
