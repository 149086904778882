import { shopActions } from '..';
import { setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { getAdjustmentService } from '../stock/adjustmentService';

export const getAdjustmentsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    try {
      const res = await getAdjustmentService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        console.log("responseeeeeeeeeeeeeeee ", res);
        dispatch(shopActions.setAdjustments(res.data));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
