import { Box, Tab, Tabs, Typography } from '@mui/material';
import styles from '../index.module.scss';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import Storage from '../../../utils/Storage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShopPayments } from '../../../store/shop/payments/paymentsActions';
import LoadingContainer from '../../../components/LoadingContainer';
import PaymentsTable from './PaymentsInTable';
import PaymentsOutTable from './PaymentsOutTable';

const Payments = (props: any) => {
  const [activeTab, setActiveTab] = useState(props.tabIndex || 0);
  const token = Storage.getToken();
  const project = Storage.getProject()._id;
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getShopPayments(`?project=${project}`, token));
  }, []);

  return (
    <Layout title={`${Storage.getProject().name} • PAYMENTS`} navMenu={<ShopNavMenu select={9} />}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        <Tab style={{fontFamily:'Poppins'}} label="Account" />
        <Tab style={{fontFamily:'Poppins'}} label="Change Password" />
      </Tabs>

      {appState.isFetching && <LoadingContainer />}

      {!appState.isFetching && (
        <Box style={{ marginTop: '2rem' }}>
          {activeTab === 0 && (
            <>
              <PaymentsTable />
            </>
          )}
          {activeTab === 1 && (
            <>
              <PaymentsOutTable />
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default Payments;
