import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { useState } from 'react';
import store from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HandymanIcon from '@mui/icons-material/Handyman';
import StoreIcon from '@mui/icons-material/Store';
import PetsIcon from '@mui/icons-material/Pets';
import AppsIcon from '@mui/icons-material/Apps';
import LanguageIcon from '@mui/icons-material/Language';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import i18n, { useTranslation } from '../i18n';
import en from '../assets/images/uk.png';
import fr from '../assets/images/fr.png';
import kin from '../assets/images/rw.png';
import { authActions } from '../store/auth';
import { User } from '../interfaces/Project/user';
import Storage from '../utils/Storage';
import { AppState } from '../store/app';
import { ShopState } from '../store/shop';
import { changeShopProjectAction } from '../store/shop/shop/shopActions';
import { deleteRed, lightOrange, primaryGreen, primaryPurple, thirdYellow } from '../assets/colors/colors';
import { logoutAction } from '../store/auth/authActions';
import { SelectedShopState } from '../store/selectedShop';

export interface AppBarProps {
  title?: string;
  pages?: string[];
  activeTab?: number;
  setActiveTab?: Function;
}

export interface SettingsItem {
  label: string;
  icon: any;
  text: string;
}

export interface LanguageItem {
  language: string;
  id: 'en' | 'fr' | 'kin';
  flag: any;
}

const AppBar = (props: AppBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = Storage.getProject()?._id;
  const token = Storage.getToken();
  const user: User = Storage.getUser();
  const { t } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElApps, setAnchorElApps] = useState<null | HTMLElement>(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const selectedShopState: SelectedShopState = useSelector((state: any) => state.selectedshop);
  const languages: LanguageItem[] = [
    { language: 'Kinyarwanda', id: 'kin', flag: kin },
    { language: 'French', id: 'fr', flag: fr },
    { language: 'English', id: 'en', flag: en },
  ];
  const settingsItems: SettingsItem[] = [
    {
      label: t('profile'),
      icon: <AccountCircleIcon style={{ color: 'currentColor' }} />,
      text: 'Profile',
    },
    {
      label: t('language'),
      icon: <LanguageIcon style={{ color: 'currentColor' }} />,
      text: 'Language',
    },
    {
      label: t('logout'),
      icon: <LogoutIcon style={{ color: 'currentColor' }} />,
      text: 'Logout',
    },
  ];
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const shopChangedHandler = (event: any) => {
    const selectedShop = selectedShopState.shops.filter((currentShop: any) => currentShop._id === event.target.value);
    if (selectedShop.length === 1) {
      console.log('selected project ', selectedShop[0]);
      dispatch(changeShopProjectAction(selectedShop[0], token));
      // store.set('project', selectedShop[0]);
    }
  };

  const logoutHandler = () => {
    dispatch(logoutAction());
    navigate('/login');
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAppsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElApps(event.currentTarget);
  };

  const handleOpenLanguagesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };

  return (
    <Box className={styles.myAppBar}>
      <Box>{props.title && <Typography className={styles.navTitle}>{props.title}</Typography>}</Box>
      {props?.pages && (
        <List className="page_nav">
          {props?.pages.map((route: any) => (
            <ListItem
              key={route.label}
              component={Link}
              to={route.link}
              className="flex-1"
              // selected={route.link === pathName}
              classes={{ root: styles.root, selected: styles.listItemSelected }}
            >
              <ListItemText primary={route.label} className={styles.listItemText}>
                <Typography></Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
      <Box sx={{ flexGrow: 0 }}>
        <select
          className="text-blue-600 font-semibold uppercase outline-none me-4"
          onChange={shopChangedHandler}
          value={project ?? 'all'}
        >
        <option  value="all">All</option>
          {selectedShopState.shops.map((shop: any) => (
            <option style={{fontFamily:'Poppins'}} key={shop._id} value={shop._id}>
              {shop.name}
            </option>
          ))}
        </select>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Typography style={{fontFamily:'Poppins'}}>{user?.name ?? user?.username}</Typography>
            <Avatar alt={user?.username} src={user?.image?.url} style={{ margin: '0 1rem' }} />
          </IconButton>
        </Tooltip>
        {user?.company?.name === 'CEMS ltd' && (
          <Tooltip title="TraceUP Apps">
            <IconButton onClick={handleOpenAppsMenu}>
              <AppsIcon className="cursor-pointer" />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settingsItems.map((setting: SettingsItem) => (
            <MenuItem
              key={setting.text}
              onClick={(event: any) => {
                if (setting.text === 'Logout') {
                  handleCloseUserMenu();
                  logoutHandler();
                } else if (setting.text === 'Profile') {
                  handleCloseUserMenu();
                  navigate('/home/account');
                } else {
                  // handleCloseUserMenu();
                  handleOpenLanguagesMenu(event);
                }
              }}
            >
              {setting.icon}
              <Typography textAlign="center">{setting.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="languages"
          anchorEl={anchorElLanguage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElLanguage)}
          onClose={() => {
            setAnchorElLanguage(null);
            handleCloseUserMenu();
          }}
        >
          {languages.map((language: LanguageItem, index: number) => (
            <MenuItem
              key={index}
              onClick={() => {
                store.set('language', language.id);
                i18n.changeLanguage(language.id);
                setAnchorElLanguage(null);
                handleCloseUserMenu();
              }}
            >
              <img alt="flag" style={{ height: '15px', marginRight: '10px' }} src={language.flag} />
              <Typography>{language.language}</Typography>
            </MenuItem>
          ))}
        </Menu>
        {Boolean(anchorElApps) && (
          <Menu
            sx={{ mt: '45px' }}
            id="notifications"
            anchorEl={anchorElApps}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElApps)}
            onClose={() => {
              setAnchorElApps(null);
            }}
          >
            <div className="w-[20vw] py-4 px-7 flex justify-around">
              <Link to="https://main.traceup.rw" className="text-center cursor-pointer" replace>
                <StoreIcon style={{ height: '4rem', width: '4rem', color: primaryPurple }} />
                <div>Shop</div>
              </Link>
              <Link to="https://tender.traceup.rw" className="text-center cursor-pointer" replace>
                <HandymanIcon
                  style={{
                    height: '3rem',
                    width: '4rem',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    color: deleteRed,
                  }}
                />
                <div>Civil</div>
              </Link>
              <Link to="https://farmmanager.traceup.rw" className="text-center cursor-pointer" replace>
                <PetsIcon
                  style={{
                    height: '3rem',
                    width: '4rem',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    color: primaryGreen,
                  }}
                />
                <div>Farm</div>
              </Link>
            </div>
          </Menu>
        )}
      </Box>
    </Box>
  );
};

export default AppBar;
