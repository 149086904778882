import { Box, Button, Container, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styles from '../../../components/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReceiptComponent from '../../../components/ReceiptComponent2';
import { shopActions, ShopState } from '../../../store/shop';
import { Item } from '../../../interfaces/shop/shopItems';
import CloseIcon from '@mui/icons-material/Close';
import { NewPurchaseData } from '../../../interfaces/shop/shopPurchase';
import { getPurchaseRequesites } from '../../../store/purchases/purchasesActions';
import { addAction, updateItemsStock } from '../../../store/shop/actions/globalActions';
import Storage from '../../../utils/Storage';
import {
  addSalesService,
  addToSalesListService,
  deleteSaleItemService,
  updateSalesDateService,
} from '../../../store/sales/salesServices';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { NewSalesItem, SalesItem } from '../../../interfaces/shop/salesItem';
import { primaryColor } from '../../../assets/colors/colors';
import { LoadingButton } from '@mui/lab';
import { NewSalesData } from '../../../interfaces/shop/sales';
import { updateSaleItemService } from '../../../store/sales/salesItemService';
import { LayoutType, layout } from '../../../components/GenericForm';
import FormHeader from '../../../components/FormHeader';
import { reduceItemStock } from '../../../store/items/itemsActions';
import { Customer } from '../../../interfaces/shop/customers';
import { salesActions, SalesState } from '../../../store/sales';
import { itemActions, ItemsState } from '../../../store/items';
import { newSaleAction } from '../../../store/sales/salesActions';

interface newPurchaseProps {
  close: Function;
  sale?: any;
}

export default function NewSale(props: newPurchaseProps) {
  const [item, setItem] = useState({} as Item | any);
  const [customer, setCustomer] = useState({} as Customer | any);
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const currentDate = dayjs();
  const [form] = Form.useForm();
  const [index, setIndex] = useState(-1);
  const [salesItems, setSalesItems] = useState([] as NewPurchaseData[]);
  const [list, setList] = useState([] as NewSalesItem[]);
  const [updatingSale, setUpdatingSale] = useState(false);
  const [updatingSaleItem, setUpdatingSaleItem] = useState(false);
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const project = Storage.getProject();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const itemsState: ItemsState = useSelector((state: any) => state.items);
  const salesState: SalesState = useSelector((state: any) => state.sales);
  const updating = appState.openForm.action === 'update';
  const { Option } = Select;

  const resetFields = (allFields: Boolean = false) => {
    form.resetFields([
      'manufacturer',
      'barCode',
      'item',
      'quantity',
      'buyingPrice',
      'unitPrice',
      'totalPrice',
      'totalPaid',
      'stock',
    ]);
  };

  const addSaleHandler = async () => {
    const data: NewSalesData = {
      date: form.getFieldValue('date')
        ? moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + new Date().toISOString().substring(10)
        : new Date().toISOString(),
      customer: form.getFieldValue('customer'),
      project: project._id,
      list: list,
    };
    
    dispatch(newSaleAction(data, salesState.sales, customer, token));
  };

  const addOneSaleHandler = async (values: any) => {
    delete values.stock;
    delete values.manufacturer;
    values.item = itemsState.selectedItems.find((currentItem: Item) => currentItem._id === values.item);
    values.date = form.getFieldValue('date')?.toDate() ?? new Date();
    values.project = project._id;
    values.sales = props.sale?._id;
    setList([...list, values as NewSalesItem]);
    var res = await addToSalesListService(props.sale?._id, values, token);
    dispatch(salesActions.setFetchedSales(false));
    resetFields();
  };

  const validatePositiveNumber = (_: any, value: string) => {
    if (parseFloat(value) <= 0) {
      return Promise.reject('Item out of stock, Please add more in order to sell');
    }
    return Promise.resolve();
  };

  const validateSoldQuantity = (_: any, value: string) => {
    if (parseFloat(value) === 0) {
      return Promise.reject('Quantity sold must be greater than 0');
    } else if (parseFloat(value) > +form.getFieldValue('stock')) {
      return Promise.reject('Quantity sold exceeding available stock');
    }
    return Promise.resolve();
  };

  const validateTotalPaid = (_: any, value: string) => {
    if (parseFloat(value) > +form.getFieldValue('totalPrice')) {
      return Promise.reject('Amound paid can not exceed total price');
    } else if (parseFloat(value) < 0) {
      return Promise.reject('Amound paid can not be less than 0');
    }
    return Promise.resolve();
  };

  // const updateSaleItem = async () => {
  //   if (index >= 0) {
  //     setFormLoading(true);
  //     await updatePurchaseItemService(
  //       props?.sale?.list[index]._id,
  //       form.getFieldsValue(),
  //       token
  //     );
  //     setFormLoading(false);

  //     newPurchaseItems[index] = {
  //       ...newPurchaseItems[index],
  //       quantity: +form.getFieldValue("quantity"),
  //       unit: form.getFieldValue("unit"),
  //       unitPrice: form.getFieldValue("unitPrice"),
  //       totalPrice:
  //         +form.getFieldValue("quantity") * +form.getFieldValue("unitPrice"),
  //     };
  //     setNewPurchaseItems([...newPurchaseItems]);
  //   }
  // };

  const updateSaleItem = async () => {
    setUpdatingSaleItem(true);

    if (index >= 0) {
      list[index] = {
        ...list[index],
        item: itemsState.selectedItems.find((currentItem: Item) => currentItem._id === form.getFieldValue('item'))!,
        quantity: form.getFieldValue('quantity'),
        buyingPrice: form.getFieldValue('buyingPrice'),
        unitPrice: form.getFieldValue('unitPrice'),
        totalPaid: +form.getFieldValue('totalPaid'),
      };
      // var newList = props?.sale?.list.filter((element: SalesItem) => element;)

      var data = {
        old: props.sale?.list[index],
        new: list[index],
      };
      const res = await updateSaleItemService(props.sale?.list[index]._id, data, token);
      const totalPaid = list.reduce((accumulator, s) => {
        return accumulator + (s?.totalPaid || 0);
      }, 0);

      const totalPrice = list.reduce((accumulator, s) => {
        return accumulator + s.quantity * (s?.unitPrice || 1);
      }, 0);
      const rres = await updateSalesDateService(
        props.sale?._id,
        {
          customer: form.getFieldValue('customer'),
          date: moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + props?.sale?.date.substring(10, 25),
          isCredit: totalPaid < totalPrice,
        },
        token
      );
      setIndex(-1);
      setUpdatingSale(false);
      setList(list.slice());
      resetFields();
      form.getFieldInstance('barCode').focus();
      setUpdatingSaleItem(false);
    }
  };

  const deleteSaleItem = async () => {
    if (index >= 0) {
      list.splice(index, 1);
      // var newList = props?.sale?.list.filter((element: SalesItem) => element;)
      setDeleting(true);
      await deleteSaleItemService(props?.sale?._id, props?.sale?.list[index], token);
      setList(list.slice());
      resetFields();
      form.getFieldInstance('barCode').focus();
      setDeleting(false);
      setUpdatingSale(false);
    }
  };

  const onItemClicked = async (inIndex: number) => {
    setUpdatingSale(true);
    // getProductItems(newPurchaseItems[inIndex].product);
    setIndex(inIndex);
    form.setFieldsValue({
      item: list?.[inIndex].item?._id,
      quantity: list?.[inIndex].quantity,
      buyingPrice: list?.[inIndex].buyingPrice,
      unitPrice: list?.[inIndex].unitPrice,
      totalPrice: list?.[inIndex].unitPrice * list?.[inIndex].quantity,
      totalPaid: list?.[inIndex].totalPaid,
      stock: list?.[inIndex].item?.stock,
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onSubmit = (values: any) => {
    values.item = itemsState.selectedItems.find((currentItem: Item) => currentItem._id === values.item);
    // values.date = values.date?.toDate() ?? new Date();
    // values.project = project._id;
    delete values.stock;
    delete values?.manufacturer;
    delete values.date;
    delete values?.barCode;
    delete values.customer;
    var existingItem = list.findIndex((item) => {
      return item.item._id === values.item._id && item.unitPrice === +values.unitPrice;
    });
    if (existingItem > -1) {
      list[existingItem].quantity += values.quantity;
      list[existingItem].totalPaid += values.totalPaid;
      setList(list.slice());
    } else {
      setList([...list, values as NewSalesItem]);
    }

    dispatch(reduceItemStock(values.item._id, itemsState?.items, +values.quantity));
    resetFields();
    form.getFieldInstance('barCode').focus();

    // if (!values.manufacturer) delete values.manufacturer;
    // dispatch(shopActions.setNewPurchase({ item: values, total: values.totalPrice }));
    // setSalesItems([...salesItems, values as NewSalesData]);
    // dispatch(shopActions.setSelectedItems(itemsState?.items));
  };

  const formElements = [
    {
      name: 'customer',
      label: 'Customer',
      type: 'select',
      options: shopState.customers,
      handleSelectChange: (value: any) => {
        let customer: Customer | undefined = shopState?.customers.find(
          (currentItem: Customer) => currentItem?._id === value
        );
        if (customer) {
          setCustomer(customer);
        }
      },
      rules: [{ required: true, message: 'Please enter the customer' }],
    },
    project.usesBarCode
      ? {
          name: 'barCode',
          label: 'Bar Code',
          type: 'number',
          onChange: (value: any) => {
            let item: Item | undefined = itemsState?.selectedItems.find(
              (currentItem: Item) => currentItem?.barCode == value
            );
            if (item) {
              form.setFieldsValue({
                item: item._id,
                buyingPrice: item.buyingPrice,
                unitPrice: item.sellingPrice,
                stock: item.stock,
                quantity: 1,
                totalPaid: item.sellingPrice,
                totalPrice: item.sellingPrice,
              });
            }
          },
        }
      : {
          name: 'manufacturer',
          label: 'Manufacturer',
          type: 'select',
          options: shopState.manufacturers,
          handleSelectChange: (value: any) => {
            let newItems = itemsState?.items?.filter((item: Item) => item?.manufacturer?._id === value);
            dispatch(itemActions.setSelectedItems(newItems));
          },
        },
    {
      name: 'item',
      label: 'Item',
      type: 'select',
      options: itemsState.selectedItems,
      handleSelectChange: (value: any) => {
        let item: Item | undefined = itemsState?.selectedItems.find((currentItem: Item) => currentItem?._id === value);
        if (item) {
          form.setFieldsValue({
            buyingPrice: item.buyingPrice,
            unitPrice: item.sellingPrice,
            stock: item.stock,
            quantity: 1,
            totalPaid: item.sellingPrice,
            totalPrice: item.sellingPrice,
          });
        }
      },
      rules: [{ required: true, message: 'Item is required' }],
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'number',
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue('unitPrice'),
          totalPaid: +value * +form.getFieldValue('unitPrice'),
        });
      },
      rules: [{ required: true, message: 'Please enter the quantity sold' }, { validator: validateSoldQuantity }],
    },
    {
      name: 'buyingPrice',
      label: 'Buying Price',
      type: 'number',
      rules: [{ required: true, message: 'Please enter the buying Price' }],
    },
    {
      name: 'unitPrice',
      label: 'Selling Price',
      type: 'number',
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue('quantity'),
          totalPaid: +value * +form.getFieldValue('quantity'),
        });
      },
      rules: [{ required: true, message: 'Please enter the selling Price' }],
    },
    {
      name: 'totalPrice',
      label: 'Total Price',
      type: 'number',
      onChange: (value: any) => {
        form.setFieldsValue({
          unitPrice: +value / +form.getFieldValue('quantity'),
          totalPaid: +value,
        });
      },
    },
    {
      name: 'totalPaid',
      label: 'Total Paid',
      type: 'number',
      rules: [{ required: true, message: 'Please enter the amount received' }, { validator: validateTotalPaid }],
    },
    {
      name: 'date',
      label: 'Date',
      type: 'date',
    },
    {
      name: 'stock',
      label: 'In Stock',
      type: 'number',
      disabled: true,
      rules: [{ validator: validatePositiveNumber }],
    },
  ];

  useEffect(() => {
    if (!itemsState.fetchedItems || !shopState.fetchedManufacturers || !shopState.fetchedCustomers) {
      dispatch(
        getPurchaseRequesites(project._id, token, {
          fetchedItems: appState.fetchedItems,
          fetchedCustomers: appState.fetchedCustomers,
          fetchedManufacturers: appState.fetchedManufacturers,
          fetchedSuppliers: appState.fetchedSuppliers,
        })
      );
    }

    if (appState.openForm.action === 'update') {
      setList([...props?.sale?.list]);
      form.setFieldsValue({
        customer: props?.sale?.customer?._id,
        date: dayjs(props?.sale?.date),
      });
    }
    return () => {
      resetFields(true);
      dispatch(itemActions.setSelectedItems(itemsState?.items));
    };
  }, []);

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog
        classes={{ paper: styles.paper }}
        open={appState.openForm.open}
        onClose={() => {
          props.close();
          resetFields(true);
        }}
      >
        <FormHeader
          title={appState.openForm.action === 'add' ? 'Add Sales' : 'Update Sale'}
          close={() => {
            props.close();
            resetFields(true);
            dispatch(itemActions.setSelectedItems(itemsState?.items));
          }}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Box className={styles.genericForm_column}>
                <Form
                  {...layout}
                  layout={formLayout}
                  form={form}
                  name="control-hooks"
                  onFinish={updating ? addOneSaleHandler : onSubmit}
                  className="my_horizontal_form"
                >
                  <Box style={{ margin: '0 auto' }}>
                    {formElements.map((element: any) => (
                      <Box key={element.name} className={styles.forms_input_horizontal}>
                        <Form.Item name={element.name} label={element.label} rules={element.rules ? element.rules : []}>
                          {element.type === 'number' && (
                            <InputNumber
                              onKeyDown={handleKeyDown}
                              onChange={element?.onChange}
                              disabled={element?.disabled ?? false}
                            />
                          )}
                          {element.type === 'text' && <Input disabled={element?.disabled ?? false} />}
                          {element.type === 'date' && <DatePicker defaultValue={currentDate} />}
                          {element.type === 'select' && (
                            <Select
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={element?.handleSelectChange}
                              filterOption={(input, option) =>
                                (option?.children?.toString() as string).toLowerCase().includes(input.toLowerCase())
                              }
                            >
                              {element.options.map((opt: any) => (
                                <Option key={opt._id} value={opt._id}>
                                  {opt.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                          {element.type === 'barcode' && (
                            <Select
                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={element?.handleSelectChange}
                              filterOption={(input, option) =>
                                (option?.children?.toString() as string).toLowerCase().includes(input.toLowerCase())
                              }
                            >
                              {element.options.map((opt: any) => (
                                <Option key={opt.barCode} value={opt.barCode}>
                                  {opt.barCode}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Box>
                    ))}
                  </Box>
                  <Box className={styles.center}>
                    {!updatingSale && (
                      <Button type="submit" className={styles.confirmButton} variant="contained">
                        Add
                      </Button>
                    )}
                    {updatingSale && (
                      <>
                        <LoadingButton
                          onClick={() => updateSaleItem()}
                          loading={updatingSaleItem}
                          className={styles.confirmButton}
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                        <LoadingButton
                          loading={deleting}
                          onClick={() => deleteSaleItem()}
                          className={styles.confirmButton}
                          variant="contained"
                        >
                          Delete
                        </LoadingButton>
                        <div
                          className="bg-gray-200 p-1 ms-3 rounded-lg mt-6 cursor-pointer"
                          onClick={() => {
                            setUpdatingSale(false);
                            setIndex(-1);
                            resetFields();
                          }}
                        >
                          <CloseIcon style={{ color: primaryColor }} />
                        </div>
                      </>
                    )}
                  </Box>
                </Form>
                {/* {newSaleElements.map((element: any, index: number) => {
                  return <FormInputs key={index} element={element} style="horizontal" />;
                })} */}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <ReceiptComponent
                title="Sale"
                updating={updating}
                name={customer?.name}
                tin={customer?.tinNumber}
                onItemClicked={onItemClicked}
                list={list}
                onSave={addSaleHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
