import { query } from 'express';
import { shopActions } from '..';
import { exportExcel, setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import {
  getCustomStockService,
  getOutOfStockService,
  getShopStockService,
  searchShopStockService,
} from './stockService';
import { Manufacturer } from '../../../interfaces/shop/manufacturers';
import { Item } from '../../../interfaces/shop/shopItems';
import { getItemsService } from '../../items/itemsService';

export const getShopStock = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getShopStockService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setStock(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(shopActions.setFetchedStock(true));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const getOutOfStockAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(shopActions.setFetchingOutOfStock(true));
      const res = await getOutOfStockService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setOutOfStock(res.data));
      }
      dispatch(shopActions.setFetchingOutOfStock(false));
      dispatch(shopActions.setFetchedOutOfStock(true));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const getShopCustomStock = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getCustomStockService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setStock(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setUpdateNeeded(false));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const searchShopStock = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchShopStockService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setStock(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setUpdateNeeded(false));
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const exportShopStockAction = (
  query: string,
  token: string,
  manufacturers: Manufacturer[],
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      let stockData = [];
      const res = await getItemsService(query, token);
      console.log('querry ', query);
      console.log('ressssssssssssssssssssss ', res.response);
      console.log('mannn ', manufacturers);
      let remainingItems = res.data;
      for (const manufacturer of manufacturers) {
        let count = 1;
        let data = [];
        data = remainingItems.filter((item: Item) => item?.manufacturer?._id === manufacturer._id);
        data = data.map((d: any) => {
          return { Nbr: count++, name: d.name, stock: d.stock };
        });
        data = [{ Nbr: '', name: manufacturer?.name, stock: '' }, ...data];
        data.push({
          Nbr: '',
          name: '',
          stock: '',
        });
        remainingItems = remainingItems.filter((item: Item) => item?.manufacturer?._id !== manufacturer._id);
        stockData.push(...data);
      }
      exportExcel(stockData, excelName);
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    } finally {
      dispatch(appActions.setIsExportingExcel(false));
    }
  };
};
