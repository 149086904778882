import Drawer from '@mui/material/Drawer';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../../utils/Storage';
import LoadingContainer from '../../../components/LoadingContainer';
import { numberWithCommas, setResponseAction } from '../../../utils/functions';
import { customerSalesColumns } from '../../../interfaces/shop/customers';
import { DetailItem } from '../items/ItemView';
import TableComponent from '../../../components/TableComponent';
import { appActions } from '../../../store/app';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { Purchase } from '../../../interfaces/shop/purchase';
import { PurchaseItem } from '../../../interfaces/shop/purchaseItem';
import { getPurchasesService } from '../../../store/purchases/purchasesService';
import { ShopState } from '../../../store/shop';

interface itemViewProps {
  supplier: Supplier;
  open: boolean;
  setOpen: Function;
}

export default function SupplierView(props: itemViewProps) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [purchases, setPurchases] = useState<PurchaseItem[]>([]);
  const [count, setCount] = useState(25);
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);

  useEffect(() => {
    const fetchPurchases = async () => {
      setLoading(true);
      const res = await getPurchasesService(`?supplier=${props.supplier._id}&sort=-date`, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        setPurchases(res.data);
      }
      setLoading(false);
    };

    fetchPurchases();
  }, []);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (
    date: string,
    item: string,
    quantity: number,
    unitPrice: string,
    totalPrice: string,
    totalPaid: string
  ): any => {
    return {
      date,
      item,
      quantity,
      unitPrice,
      totalPrice,
      totalPaid,
    };
  };

  const rows = purchases?.map((purchase: PurchaseItem) =>
    createData(
      purchase.date?.substring(0, 10),
      purchase.item?.name,
      purchase?.quantity / (purchase?.item?.details?.quantity || 1),
      numberWithCommas(purchase?.unitPrice),
      numberWithCommas((purchase?.quantity / (purchase?.item?.details?.quantity || 1)) * purchase?.unitPrice),
      numberWithCommas(purchase?.totalPaid)
    )
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '60vw', padding: '2rem 1rem', background: '#f4f5f7' },
      }}
      anchor={'right'}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      {loading && <LoadingContainer />}
      {!loading && (
        <>
          <Box className={`${styles.aRight}`}>
            <CloseIcon onClick={() => props.setOpen(false)} className={`${styles.cPointer}`} />
          </Box>
          <Typography className={`${styles.header}`}>{props.supplier.name} SUPPLIER</Typography>
          <Box className={styles.item_details}>
            <Typography className={styles.item_details_text}>Details</Typography>
            <DetailItem label="Contact" value={props?.supplier?.contact || 'N/A'} />
            <DetailItem label="Tin Number" value={props?.supplier?.tinNumber || 'N/A'} />
            <DetailItem label="Debt" value={`${numberWithCommas(props.supplier.debt)} Rwf`} />
            <DetailItem label="Creation Date" value={props.supplier.createdAt?.substring(0, 10)} />
          </Box>
          <Box className={styles.item_history}>
            <Typography className={styles.item_details_text}>History</Typography>
            {/* <SalesTable /> */}
            {/* <SalesTable count={count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
            <TableComponent
              rows={rows}
              columns={customerSalesColumns}
              rowClickedHandler={() => {}}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </>
      )}
    </Drawer>
  );
}
