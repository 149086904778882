import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getPaymentsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/shoppayments${query}`, token);
};

export const updatePaymentsService = async (paymentId: string, data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/shop/shoppayments/${paymentId}`, data, token);
};

export const deletePaymentsService = async (manufacturerId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/shoppayments/${manufacturerId}`, token);
};
