import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import appReducer from './app/index';
import shopProjectReducer from './shop';
import storage from 'redux-persist/lib/storage';
import selectedShopReducer from './selectedShop';
import salesReducer from './sales';
import itemsReducer from './items';
import productsReducer from './products';
import purchasesReducer from './purchases';
import quickSalesReducer from './quicksales';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  shop: shopProjectReducer,
  selectedshop: selectedShopReducer,
  sales: salesReducer,
  purchases: purchasesReducer,
  items: itemsReducer,
  products: productsReducer,
  quicksales: quickSalesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['selectedshop'],
  // whitelist: ['selectedshop', 'sales', 'items', 'products', 'quicksales'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;

export const persistor = persistStore(store);
