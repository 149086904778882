import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getShopsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/projects${query}`, token);
};

export const getShopDashboardService = async (projectId: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/projects/dashboard/${projectId}`, token);
};

export const getMonthlyProfitService = async (projectId: string, month: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/projects/profit/${projectId}?month=${month}`, token);
};

export const createShopsService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/projects`, data, token);
};

export const updateShopService = async (projectId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/projects/${projectId}`, data, token);
};

export const addShopService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/projects`, data, token);
};

export const deleteShopService = async (projectId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/projects/${projectId}`, token);
};

export const addShopLogoService = async (shopId: string, data: string, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/projects/${shopId}/uploadlogo`, data, token);
};
