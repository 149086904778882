import React, { useState } from 'react';
import styles from '../index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../../components/LoadingContainer';
import { appActions } from '../../../store/app';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { numberWithCommas } from '../../../utils/functions';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Storage from '../../../utils/Storage';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { deleteSupplierService } from '../../../store/shop/suppliers/suppliersService';
import PaySupplier from './PaySupplier';
import SupplierView from './SupplierView';
import { shopSupplierColumns, shopSuppliersData, Supplier } from '../../../interfaces/shop/suppliers';
import { TableProps } from '../../../interfaces';
import { shopActions, ShopState } from '../../../store/shop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export interface SelectedSupplier {
  id: string;
  name: string;
  debt: string;
}

export default function SuppliersTable(props: TableProps) {
  const { count, page, setPage, rowsPerPage, setRowsPerPage } = props;
  const [showModal, setShowModal] = useState(false);
  const [supplierView, setSupplierView] = useState(false);
  const [paySupplier, setPaySupplier] = useState(false);
  const [query, setQuery] = useState('');
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const appState = useSelector((state: any) => state.app);
  const [supplier, setSupplier] = useState({} as Supplier);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
    setSupplierView(true);
  };

  const deleteSupplierHandler = () => {
    dispatch(
      deleteAction(deleteSupplierService, supplier._id, token, 'Supplier', () =>
        dispatch(shopActions.setFetchedSuppliers(false))
      )
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (supplier: Supplier): shopSuppliersData => {
    return {
      id: supplier._id,
      name: supplier.name,
      contact: supplier.contact ? supplier.contact : 'N/A',
      tinNumber: supplier.tinNumber ? `${supplier.tinNumber}` : 'N/A',
      debt: numberWithCommas(supplier.debt),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setSupplier(supplier);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.suppliers?.map((supplier: Supplier) => createData(supplier));

  return (
    <>
      {!appState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={shopSupplierColumns}
            rowClickedHandler={() => {}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setSupplierView(true);
                  dispatch(shopActions.setSupplier(supplier));
                  navigate('/shop/purchases/suppliersDetail');
                }}
              >
                <VisibilityIcon />
                <Typography>{t('view')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                }}
              >
                <AutorenewIcon />
                <Typography>{t('update')}</Typography>
              </MenuItem>
            </Menu>
          )}
          {supplierView && <SupplierView open={supplierView} supplier={supplier} setOpen={setSupplierView} />}
          {paySupplier && <PaySupplier open={paySupplier} supplier={supplier} setOpen={setPaySupplier} />}
        </>
      )}
      {appState.isFetching && <LoadingContainer />}
      <ConfirmModal
        onConfirm={() => {
          deleteSupplierHandler();
        }}
        content={`Please confirm to delete ${supplier.name} Supplier`}
      />
    </>
  );
}
