import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getShopCustomersService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/customers${query}`, token);
};

export const searchCustomersService = async (projectId: string, query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/customers/search/${projectId}${query}`, token);
};

export const addShopCustomerService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/customers`, data, token);
};

export const payCustomerService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/customers/payment`, data, token);
};

export const updateCustomerService = async (customerId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/customers/${customerId}`, data, token);
};

export const deleteCustomerService = async (customerId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/customers/${customerId}`, token);
};
