import shop, { shopActions } from '../shop';
import { FetchedRequisites } from '../../interfaces';
import { setResponseAction } from '../../utils/functions';
import { appActions } from '../app';
import { getShopCustomersService } from '../shop/customers/customersServices';
import { getItemsService } from '../items/itemsService';
import { getManufacturersService } from '../shop/manufacturers/manufacturersService';
import { getSuppliersService } from '../shop/suppliers/suppliersService';
import { getPurchasesNewService, getPurchasesService, searchPurchasesService } from './purchasesService';
import { purchasesActions } from '.';
import { itemActions } from '../items';

export const getSupplierPurchases = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getPurchasesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        dispatch(purchasesActions.setPurchases(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
    } catch (err: any) {}
  };
};

export const getPurchases = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getPurchasesNewService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        dispatch(purchasesActions.setPurchases(res.data));
        dispatch(purchasesActions.setPurchasesCount(res?.count ?? 1));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(purchasesActions.setFetchedPurchases(true));
    } catch (err: any) {}
  };
};

export const searchPurchases = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchPurchasesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        dispatch(purchasesActions.setPurchases(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(purchasesActions.setFetchedPurchases(true));
    } catch (err: any) {
      console.log(err.response);
    }
  };
};

export const getPurchaseRequesites = (project: string, token: string, fetched: FetchedRequisites) => {
  return async (dispatch: any) => {
    dispatch(appActions.setFetchingFormItems(true));
    try {
      if (!fetched.fetchedItems) {
        const items = (await getItemsService(`?project=${project}&limit=1000&sort=name`, token)).data;
        dispatch(itemActions.setItems(items));
        dispatch(itemActions.setFetchedItems(true));
      }
      if (!fetched.fetchedCustomers) {
        const customers = (
          await getShopCustomersService(`?fields=name,tinNumber&debts=false&project=${project}`, token)
        ).data;
        dispatch(shopActions.setCustomers(customers));
        dispatch(shopActions.setFetchedCustomers(true));
      }
      if (!fetched.fetchedSuppliers) {
        const suppliers = (await getSuppliersService(`?fields=name,tinNumber&debts=false&project=${project}`, token))
          .data;
        dispatch(shopActions.setSuppliers(suppliers));
        dispatch(shopActions.setFetchedSuppliers(true));
      }
      if (!fetched.fetchedManufacturers) {
        const manufacturers = (await getManufacturersService(`?project=${project}&fields=name`, token)).data;
        dispatch(shopActions.setManufacturers(manufacturers));
        dispatch(shopActions.setFetchedManufacturers(true));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log(err);
    }
  };
};
