import { useState } from 'react';
import ProfileSettings from './ProfileSettings';
import ShopsSettings from './ShopsSettings';
import UsersSettings from './UsersSettings';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import { Outlet } from 'react-router-dom';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const pages = ['Profile', 'Users', 'Shops'];
  const settingsRoutes = [
    { label: 'Profile', link: '/shop/settings/profile' },
    { label: 'Users', link: '/shop/settings/users' },
    { label: 'Shops', link: '/shop/settings/shops' },
  ];

  return (
    
    <Layout
      title="SETTINGS"
      pages={settingsRoutes}
      navMenu={<ShopNavMenu select={2}/>}
      activeTab={activeTab}
      style={{fontFamily:'Poppins'}}
      setActiveTab={setActiveTab}
    >
      {/* {activeTab === 0 && <ProfileSettings />}
      {activeTab === 1 && <UsersSettings />}
      {activeTab === 2 && <ShopsSettings />} */}
      <Outlet />
    </Layout>
  );
};

export default Settings;
