import { Box, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { useState } from 'react';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { initials, numberWithCommas, roundToTwoDecimalPlaces } from '../../../../utils/functions';
import { Item } from '../../../../interfaces/shop/shopItems';
import { shopActions } from '../../../../store/shop';
import { Product } from '../../../../interfaces/shop/product';
import { productActions } from '../../../../store/products';

interface ProductCardProps {
  product: Product;
  onUpload: Function;
  onAdjust: Function;
  onUpdate: Function;
  onDelete: Function;
}

const ProductCard = (props: ProductCardProps) => {
  const [anchorElProduct, setAnchorElProduct] = useState<HTMLElement | null>();

  const dispatch = useDispatch();

  return (
    <div className="bg-white mx-5 mb-9 h-[14.5rem] w-[14rem] cursor-pointer overflow-hidden rounded-2xl relative shadow-xl">
      {Boolean(anchorElProduct) && (
        <Menu
          id="basic-menu"
          anchorEl={anchorElProduct}
          open={Boolean(anchorElProduct)}
          onClose={() => setAnchorElProduct(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              props.onUpload();
              setAnchorElProduct(null);
            }}
          >
            <ImageOutlinedIcon />
            <Typography className="ms-2">Image</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onUpdate();
              setAnchorElProduct(null);
            }}
          >
            <AutorenewIcon />
            <Typography className="ms-2">Update</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.onDelete();
              setAnchorElProduct(null);
            }}
          >
            <DeleteOutlineOutlinedIcon />
            <Typography className="ms-2">Delete</Typography>
          </MenuItem>
        </Menu>
      )}
      <div className="flex justify-end">
        <MoreVertIcon
          onClick={(event: any) => {
            setAnchorElProduct(event.target);
          }}
          style={{
            marginTop: '0.5rem',
            marginRight: '0.5rem',
            position: 'absolute',
            zIndex: '1000',
          }}
        />
      </div>
      <Link
        onClick={() => {
          dispatch(productActions.setProduct(props.product));
        }}
        to={`/shop/quicksales/products/details`}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'block',
          width: '100%',
          padding: '0.3rem',
        }}
      >
        {!props?.product?.image?.url && (
          <div className="bg-gray-200 rounded-2xl h-40 w-full flex justify-center items-center me-2">
            <Typography className="whitespace-nowrap font-semibold text-3xl text-gray-500">
              {initials(props?.product?.name?.trim().toUpperCase())}
            </Typography>
          </div>
        )}
        {props?.product?.image?.url && <img className="h-36 w-full" src={props?.product?.image?.url} alt="pff" />}
        <div className="w-full">
          <div className="w-full text-center">
            <Typography className="font-Poppins text-sm mt-4 truncate">{props.product.name.toUpperCase()}</Typography>
          </div>

          <div className="mb-4">
            <Typography className="text-primaryGreen font-Poppins rounded-md px-1 w-auto text-center">
              RWF {numberWithCommas(props.product.unitPrice)}
            </Typography>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
