import { Button, Menu, MenuItem } from '@mui/material';
import { Form, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, appActions } from '../../../store/app';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styles from '../../shop/index.module.scss';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NewShop from './NewShop';
import { ShopState, shopActions } from '../../../store/shop';
import Storage from '../../../utils/Storage';
import { Shop, ShopRow, shopColumns } from '../../../interfaces/shop/project';
import DynamicListView from '../../../components/DynamicListView';
import SearchComponent2 from '../../../components/SearchComponent2';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { addShopLogoService, deleteShopService } from '../../../store/shop/shop/shopServices';
import LoadingContainer from '../../../components/LoadingContainer';
import ConfirmModal from '../../../components/ConfirmModal';
import TableComponent from '../../../components/TableComponent';
import { getShopsAction } from '../../../store/shop/shop/shopActions';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import UploadImage from '../../../components/UploadImage';
import { SelectedShopState, selectedShopActions } from '../../../store/selectedShop';

const ShopsSettings = () => {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addShop, setAddShop] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [viewGrid, setViewGrid] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [shop, setShop] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const appState: AppState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const selectedShopState: SelectedShopState = useSelector((state: any) => state.selectedshop);

  useEffect(() => {
    if (!selectedShopState.fetchedShops) {
      dispatch(getShopsAction(`?company=${Storage.getUser()?.company?._id}`, token));
    }
  }, [selectedShopState.fetchedShops]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(selectedShopActions.setFetchedShops(false));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(selectedShopActions.setFetchedShops(false));
  };

  const deleteShopHandler = () => {
    dispatch(
      deleteAction(deleteShopService, shop?._id, token, 'Shop', () => {
        // props.close();
        dispatch(selectedShopActions.setFetchedShops(false));
      })
    );
  };

  const uploadLogoHandler = async (data: any) => {
    try {
      if (shop?._id !== null) {
        setUploading(true);
        const res = await addShopLogoService(shop!._id, data, token);
        setUploading(false);
        if (res.status === 200) {
          dispatch(selectedShopActions.setFetchedShops(false));
          setUploadLogo(false);
        }
      }
    } catch (error) {
      console.log('error ', error);
    }
  };

  const createData = (shop: Shop): ShopRow => {
    return {
      name: shop.name,
      address: shop.address,
      status: shop.isActive ? 'Active' : 'Inactive',
      category: shop?.shopCategory?.toUpperCase(),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setShop(shop);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = selectedShopState?.shops?.map((shop: Shop) => createData(shop));

  return (
    <>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="flex items-center justify-between my-10">
            <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
            <SearchComponent2 placeholder="Search Shop" setSearchText={setSearchText} />
            <Button
              startIcon={<AddCircleOutlineIcon />}
              className={styles.primaryButton}
              onClick={() => setAddShop(true)}
            >
              Add Shop
            </Button>
          </div>

          <TableComponent
            rows={rows}
            columns={shopColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {addShop && (
            <NewShop
              open={addShop}
              close={() => setAddShop(false)}
              title={'Add Shop'}
              shop={shop}
              onSubmit={() => {}}
            />
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => deleteShopHandler()}
              content={`Please confirm to delete ${shop?.name} Product`}
            />
          )}
          {Boolean(anchorEl) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setUploadLogo(true);
                }}
              >
                <ImageOutlinedIcon />
                <Typography>Logo</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setAddShop(true);
                }}
              >
                <AutorenewIcon />
                <Typography>Update</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteOutlineOutlinedIcon />
                <Typography>Delete</Typography>
              </MenuItem>
            </Menu>
          )}
          {uploadLogo && (
            <UploadImage
              open={uploadLogo}
              close={() => setUploadLogo(false)}
              title={'Add Shop Logo'}
              uploading={uploading}
              uploadHandler={uploadLogoHandler}
            />
          )}
        </>
      )}
    </>
  );
};

export default ShopsSettings;
