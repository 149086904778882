import { Avatar, Box, Grid, Typography } from '@mui/material';
import { primaryPurple } from '../../../assets/colors/colors';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import styles from '../index.module.scss';

interface DashboardCardProps {
  label: string,
  metric: string,
  icon: any,
  primaryColor: string
  accentColor?: string
  info?: string
}

const DashboardCard = (props: DashboardCardProps) => {
  return (
    <Box className={`${styles.dashboardStatCard}`}>
      <Box style={{width: '0.3rem', backgroundColor: props.primaryColor }}></Box>
      <div className="flex items-center w-full">
      <Avatar style={{ height: '3rem', width: '3rem', marginLeft: "0.5rem", backgroundColor: props.accentColor }}>
                {props.icon}
              </Avatar>
              <Box className={styles.dashboard_card_main} >
                <div className={styles.dashboard_card_label}>{props.label}</div>
                <Typography className={styles.dashboard_card_metric}>{props.metric}</Typography>
              </Box>
          {/* <Grid container>
            <Grid item xs={9}>
            </Grid>
            <Grid className={styles.center} item xs={3}>
            </Grid>
          </Grid> */}
      </div>
    </Box>
  );
};

export default DashboardCard;
