import store from 'store';
import { authActions } from '.';
import { loginService } from './authServices';
import { appActions } from '../app';
import { shopActions } from '../shop';
import { selectedShopActions } from '../selectedShop';
import { salesActions } from '../sales';
import { purchasesActions } from '../purchases';
import { itemActions } from '../items';
import { quickSalesActions } from '../quicksales';
import { productActions } from '../products';

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      dispatch(authActions.login({ status: null }));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set('x-auth-token', res?.data?.token);
        store.set('user', res?.data?.data?.user);
      }
      dispatch(authActions.setIsFetching(false));
      dispatch(authActions.login({ ...res?.data, status: res?.status }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const logoutAction = () => {
  return async (dispatch: any) => {
    try {
      store.remove('x-auth-token');
      store.remove('user');
      store.remove('project');
      dispatch(authActions.logout());

      //RESET STATES;
      dispatch(appActions.resetAppState());
      dispatch(shopActions.resetShopState());
      dispatch(selectedShopActions.resetSelectedShopState());
      dispatch(salesActions.resetSalesState());
      dispatch(purchasesActions.resetPurchasesState());
      dispatch(itemActions.resetItemsState());
      dispatch(quickSalesActions.resetQuickSalesState());
      dispatch(productActions.resetProductsState());
    } catch (err) {
      console.log(err);
    }
  };
};
