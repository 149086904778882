import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import { Outlet, useLocation } from 'react-router-dom';
import Storage from '../../../utils/Storage';

const PurchasesMain = () => {
  // const salesRoutes = ['Purchases', 'Suppliers'];
  const purchaseRoutes = [
    { label: 'Purchases', link: '/shop/purchases/purchases' },
    { label: 'Suppliers', link: '/shop/purchases/suppliers' },
  ];
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <Layout
      title={`${Storage.getProject().name} • PURCHASES`}
      pages={purchaseRoutes}
      navMenu={<ShopNavMenu select={2} />}
    >
      <Outlet />
    </Layout>
  );
};

export default PurchasesMain;
