import { createSlice } from "@reduxjs/toolkit";
import { Sale } from "../../interfaces/shop/sales";
import { SalesItem } from "../../interfaces/shop/salesItem";

export interface SalesState {
    sales: Sale[],
    customerSales: SalesItem[];
    salesCount: 0,
    fetchedSales: false,
};

const initialState: SalesState = {
  sales: [],
  customerSales: [],
  salesCount: 0,
  fetchedSales: false,
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    setSales: (state, action) => {
      state.sales = action.payload;
    },
    setCustomerSales: (state, action) => {
      state.customerSales = action.payload;
    },
    setSalesCount: (state, action) => {
      state.salesCount = action.payload;
    },
    setFetchedSales: (state, action) => {
      state.fetchedSales = action.payload;
    },
    resetSalesState(state) {
      Object.assign(state, initialState);
    }
  },
});

export const salesActions = salesSlice.actions;

export default salesSlice.reducer;