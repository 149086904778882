import { createSlice } from '@reduxjs/toolkit';
import { Purchase } from '../../interfaces/shop/purchase';
import { PurchaseItem } from '../../interfaces/shop/purchaseItem';

export interface PurchaseState {
  purchases: Purchase[];
  supplierPurchases: PurchaseItem[];
  purchasesCount: number;
  fetchedPurchases: boolean;
}

const initialState: PurchaseState = {
  purchases: [],
  supplierPurchases: [],
  purchasesCount: 0,
  fetchedPurchases: false,
};

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    setSupplierPurchases: (state, action) => {
      state.supplierPurchases = action.payload;
    },
    setPurchasesCount: (state, action) => {
      state.purchasesCount = action.payload;
    },
    setFetchedPurchases: (state, action) => {
      state.fetchedPurchases = action.payload;
    },
    resetPurchasesState(state) {
      Object.assign(state, initialState);
    }
  },
});

export const purchasesActions = purchasesSlice.actions;

export default purchasesSlice.reducer;