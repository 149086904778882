import { Divider } from "@mui/material";

export interface NotificationItemProps {
  name: string;
  stock: number;
  manufacturer: string;
}

const NotificationItem = (props: NotificationItemProps) => {
  return (
    <div className="mx-2">
      <div className="flex items-center py-2">
        <div className="bg-gray-200 rounded-md me-3 p-1">{props.name.substring(0, 2).toUpperCase()}</div>
        <div className="w-full">
          <div className="flex justify-between w-full">
            <div className="text-xs font-bold">{props.name}</div>
            <div className="text-xs font-bold">{props.stock}</div>
          </div>
          <div className="text-slate-500 text-xs">{props.manufacturer}</div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default NotificationItem;
