import styles from '../index.module.scss';
import CustomersTable from './CustomersTable';
import ErrorDialog from '../../../components/ResponseDialog';
import Storage from '../../../utils/Storage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  exportCustomersAction,
  getShopCustomers,
  searchCustomers,
} from '../../../store/shop/customers/customersActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../../store/app';
import TopActions from '../../../components/TopActions';
import { shopActions, ShopState } from '../../../store/shop';
import { Typography } from 'antd';
import CardPairValues from '../../../components/CardPairValues';
import { lightBlue } from '../../../assets/colors/colors';
import { Avatar, Box } from '@mui/material';
import { Customer } from '../../../interfaces/shop/customers';
import { renderMoney, stringAvatar } from '../../../utils/functions';
import DynamicListView from '../../../components/DynamicListView';
import NewShopCustomer from './NewCustomer';
import CustomerCardValues from './CustomerCardValue';
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

const Customers = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [viewGrid, setViewGrid] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = Storage.getToken();
  const project = Storage.getProject();
  const shopState : ShopState = useSelector((state: any) => state.shop);
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (!shopState.fetchedCustomers) {
      dispatch(getShopCustomers(`?project=${project._id}&sort=name&page=${page}&limit=${rowsPerPage}`, token));
    }
  }, [page, rowsPerPage, token, dispatch, project._id, shopState.fetchedCustomers]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchCustomers(project._id, `?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !appState.updateNeeded && !isInit) {
          dispatch(shopActions.setFetchedCustomers(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, project._id, searchText, token]);

  const exportStock = () => {
    dispatch(exportCustomersAction(`?project=${project._id}&limit=1000`, token, `${project?.name} Customers`));
  };

  return (
    <>
      <ErrorDialog />
      <TopActions
        setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
        export={exportStock}
        entity="Customer"
        setSearchText={setSearchText}
        addNew={true}
      />
      <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
      {appState.openForm.open && <NewShopCustomer />}
      {viewGrid && (
        <>
          <div className="flex flex-wrap mb-20">
            ,
            {shopState?.customers?.map((customer: Customer) => (
              <Box key={customer._id} className={styles.supplierCard}>
              <Typography className={styles.userCardName}>
                {customer.name}
              </Typography>
              <CustomerCardValues
               label="Contact" value={customer?.contact ?? 'N/A'}
              />
              <CustomerCardValues
               label="TinNumber" value={customer?.tinNumber ?? 'N/A'}
              />
              <CustomerCardValues
               label="Debt" value={renderMoney(customer.debt)}
              />

              <Box style={{ display: "flex", justifyContent: "end" }}>
                <VisibilityIcon />
                <EditIcon />
                <DeleteIcon />
              </Box>
            </Box>
            ))}
          </div>
        </>
      )}
      {!viewGrid && (
        <CustomersTable
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </>
  );
};

export default Customers;
