import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LineChart from './LineChart';
import LoadingContainer from '../../../components/LoadingContainer';
import { getMonthlyProfit } from '../../../store/shop/shop/shopActions';
import Storage from '../../../utils/Storage';
import styles from '../index.module.scss';
import { useTranslation } from '../../../i18n';

const ChartComponent = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 7));
  const dispatch = useDispatch();
  const project = Storage.getProject()._id;
  const token = Storage.getToken();
  const { t } = useTranslation();

  const appState = useSelector((state: any) => state.app);
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setDate(newValue);
  };

  useEffect(() => {
    dispatch(getMonthlyProfit(project, `${date}-05`, token));
  }, [date, project]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Box className={styles.chartContainer}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab style={{fontFamily:'Poppins'}} label={t('profit')} />
          <Tab style={{fontFamily:'Poppins'}}  label="SALES & PURCHASES" />
          <Tab style={{fontFamily:'Poppins'}}  label="Debts" />
        </Tabs>
        <input style={{ marginLeft: '1rem', marginTop: '1rem' }} value={date} onChange={onChange} type="month"></input>
        {!appState.isFetching && (
          <Box className={styles.pCenter}>
            <div style={{ width: '42rem' }}>{appState?.chartData && <LineChart chartData={appState?.chartData} />}</div>
          </Box>
        )}
        {appState.isFetching && <LoadingContainer />}
      </Box>
    </Box>
  );
};

export default ChartComponent;
