import { Avatar, Box, Typography } from '@mui/material';
import styles from '../index.module.scss';
import TopActions from '../../../components/TopActions';
import { useEffect, useState } from 'react';
import SuppliersTable from './SuppliersTable';
import NewShopSupplier from './NewSupplier';
import Storage from '../../../utils/Storage';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { appActions } from '../../../store/app';
import {
  exportSuppliersAction,
  getShopSuppliers,
  searchShopSuppliers,
} from '../../../store/shop/suppliers/suppliersActions';
import { shopActions, ShopState } from '../../../store/shop';
import DynamicListView from '../../../components/DynamicListView';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { renderMoney, stringAvatar } from '../../../utils/functions';
import CardPairValues from '../../../components/CardPairValues';
import SupplierCardValues from './SupplierCardValues';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Suppliers = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [isInit, setIsInit] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState('');
  const project = Storage.getProject();
  const [viewGrid, setViewGrid] = useState(true);
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const token = Storage.getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (!shopState.fetchedSuppliers) {
      dispatch(getShopSuppliers(`?project=${project._id}&sort=-date&page=${page}&limit=${rowsPerPage}`, token));
    }
  }, [page, rowsPerPage, token, dispatch, project._id, shopState.fetchedSuppliers]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopSuppliers(project._id, `?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && !appState.updateNeeded && !isInit) {
          dispatch(shopActions.setFetchedSuppliers(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, project._id, searchText, token]);

  const exportStock = () => {
    dispatch(exportSuppliersAction(`?project=${project._id}&limit=1000`, token, `${project?.name} Customers`));
  };

  return (
    <>
      <TopActions
        setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
        entity="Supplier"
        setSearchText={setSearchText}
        export={exportStock}
        addNew={true}
      />
      <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
      {viewGrid && (
        <>
          <div className="flex flex-wrap mb-20">
            {shopState?.suppliers?.map((supplier: Supplier) => (
              <Box key={supplier._id} className={styles.supplierCard}>
                <Typography className={styles.userCardName}>{supplier.name}</Typography>
                <SupplierCardValues label="Contact" value={supplier?.contact ?? 'N/A'} />
                <SupplierCardValues label="TinNumber" value={supplier?.tinNumber ?? 'N/A'} />
                <SupplierCardValues label="Debt" value={renderMoney(supplier?.debt)} />

                <Box className="flex justify-end space-x-1">
                  <VisibilityOutlinedIcon />
                  <EditOutlinedIcon />
                  <DeleteOutlineOutlinedIcon />
                </Box>
              </Box>
            ))}
          </div>
        </>
      )}
      {!viewGrid && (
        <SuppliersTable
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
      {appState.openForm.open && <NewShopSupplier />}
    </>
  );
};

export default Suppliers;
