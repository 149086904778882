import { User } from '../Project/user';


export interface shopCustomerColumn {
  id: 'name' | 'contact' | 'tinNumber' | 'debt' | 'action';
  label: String;
  minWidth?: number;
  dataType?: String;
  align?: 'center';
}

export const shopCustomerColumns: shopCustomerColumn[] = [
  { id: 'name', label: 'Customer Name', align: 'center' },
  { id: 'contact', label: 'Contact', align: 'center', dataType: 'number' },
  { id: 'tinNumber', label: 'Tin Number', align: 'center', dataType: 'number' },
  { id: 'debt', label: 'Debt', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopCustomersData {
  id: string;
  name: string;
  contact: string;
  tinNumber: string;
  debt: string;
  action: any;
}

export interface Customer {
  _id: string;
  name: string;
  contact?: string;
  tinNumber?: number;
  rates?: number;
  debt: number;
  project?: string;
  isActive: Boolean;
  createdBy?: User;
  createdAt: string;
  updatedAt: string;
}

export interface CustomerSalesColumn {
  id: 'date' | 'item' | 'quantity' | 'unitPrice' | 'totalPrice' | 'totalPaid';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const customerSalesColumns: CustomerSalesColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'item', label: 'Item Name', align: 'center' },
  { id: 'quantity', label: 'Quantity', align: 'center' },
  { id: 'unitPrice', label: 'Unit Price', align: 'center' },
  { id: 'totalPrice', label: 'Total Price', align: 'center' },
  { id: 'totalPaid', label: 'Paid', align: 'center' },
];
