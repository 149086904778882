import { Box, Button, MenuItem, Select, TablePagination } from '@mui/material';
import styles from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LoadingContainer from '../../../components/LoadingContainer';
import { appActions, AppState } from '../../../store/app';
import { exportShopItems, getShopItems, searchShopItems } from '../../../store/items/itemsActions';
import Storage from '../../../utils/Storage';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { shopActions, ShopState } from '../../../store/shop';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { Item } from '../../../interfaces/shop/shopItems';
import ProductCard from './ItemCard';
import NewShopItem from './NewItem';
import { LoadingButton } from '@mui/lab';
import DynamicListView from '../../../components/DynamicListView';
import { Divider, Typography } from 'antd';
import AdjustStock from '../stock/AdjustStock';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { deleteItemService, uploadProductImageService } from '../../../store/items/itemsService';
import ConfirmModal from '../../../components/ConfirmModal';
import { exportShopStockAction } from '../../../store/shop/stock/stockActions';
import UploadImage from '../../../components/UploadImage';
import { itemActions, ItemsState } from '../../../store/items';

const Items = () => {
  const [searchText, setSearchText] = useState('');
  const manufacturers = useSelector((state: any) => state.shop.manufacturers);
  const token = Storage.getToken();
  const project = Storage.getProject()?._id;
  const [manufacturer, setManufacturer] = useState('');
  const [viewGrid, setViewGrid] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState('');
  const appState : AppState = useSelector((state: any) => state.app);
  const shopState : ShopState = useSelector((state: any) => state.shop);
  const itemsState : ItemsState = useSelector((state: any) => state.items);
  const dispatch = useDispatch();
  const [isInit, setIsInit] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [adjustStock, setAdjustStock] = useState(false);
  const [uploadPicture, setUploadPicture] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [product, setProduct] = useState<Item | null>(null);

  const handleChange = (index: number, category: string) => {
    setSelectedCategory(index);
    // dispatch(getImages(`category=${category}`));
  };

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (manufacturer) {
      setQuery(`&manufacturer=${manufacturer}`);
      dispatch(itemActions.setFetchedItems(false));
    }
  }, [manufacturer]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopItems(`?text=${searchText}&project=${project}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && itemsState.fetchedItems && !isInit) {
          dispatch(itemActions.setFetchedItems(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token, itemsState.fetchedItems]);

  useEffect(() => {
    if (!itemsState.fetchedItems && !searchText) {
      dispatch(getShopItems(`?project=${project}&sort=name&page=${page}&limit=${rowsPerPage}${query}`, token));
    }
  }, [page, rowsPerPage, query, token, appState.updateNeeded, dispatch, project, itemsState.fetchedItems, query]);

  const exportItems = () => {
    dispatch(
      exportShopItems(
        `?project=${Storage.getProject()._id}&limit=20000&sort=manufacturer`,
        token,
        `${Storage.getProject().name} • PRODUCTS`
      )
    );
  };

  const exportStock = async () => {
    dispatch(
      exportShopStockAction(
        `?project=${project}&limit=20000&sort=name`,
        token,
        shopState.manufacturers,
        `${Storage.getProject().name} STOCK`
      )
    );
  };

  const uploadImageHandler = async (data: any) => {
    try {
      if (product?._id !== null) {
        setUploading(true);
        const res = await uploadProductImageService(product!._id, data, token);
        setUploading(false);
        if (res.status === 200) {
          dispatch(itemActions.setFetchedItems(false));
          setUploadPicture(false);
        }
      }
    } catch (error) {
      console.log('error ', error);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    dispatch(itemActions.setFetchedItems(false));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(itemActions.setFetchedItems(false));
  };

  const handleDeleteItem = () => {
    if (product) {
      dispatch(
        deleteAction(deleteItemService, product?._id, token, 'Item', () => dispatch(itemActions.setFetchedItems(false)))
      );
    }
  };

  return (
    <Layout title={`${Storage.getProject()?.name || 'Shop'} • ITEMS`} navMenu={<ShopNavMenu />}>
      {/* <Typography className={styles.navTitle}>{`${Storage.getProject().name} • ITEMS`}</Typography> */}
      <Box className="flex w-[100%] justify-between items-center my-6">
        <Typography className="font-bold text-xl">Products</Typography>
        {/* <SearchComponent2 placeholder={'Search Products'} setSearchText={setSearchText} /> */}
        <div className="bg-white w-[35vw] flex border border-blue-500 rounded-3xl overflow-hidden">
          <input
            className="basis-11/12 rounded-3xl ps-4 outline-none"
            placeholder="Search Products"
            type="text"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setSearchText(event.target.value);
            }}
          />
          <div className="h-[1.5rem] w-[0.1rem] my-auto me-2 bg-gray-400"></div>
          <div className="w-[20vw]">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="All Categories"
              className="w-[100%] text-xs h-[35px] text-textColor ps-0 outline-none border-none border-transparent"
              value={manufacturer}
              onChange={(event: any) => {
                setManufacturer(event.target.value as string);
              }}
            >
              {manufacturers?.map((option: any) => (
                <MenuItem key={option?._id} value={option?._id}>
                  {option['name']}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="basis-1/12 bg-blue-500 flex justify-center p-2 items-center">
            <SearchIcon style={{ color: 'white' }} />
          </div>
        </div>
        <Button
          className={styles.primaryButton}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          style={{ fontFamily: 'Poppins' }}
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: 'add' }));
          }}
        >
          Add Product
        </Button>
      </Box>
      <div className="flex justify-between">
        <DynamicListView viewGrid={viewGrid} setViewGrid={setViewGrid} />
        <div className="flex items-center me-5">
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportItems}
            style={{ fontFamily: 'Poppins' }}
            className={styles.exportExcelButton}
          >
            Products
          </LoadingButton>
          <LoadingButton
            variant="contained"
            startIcon={<SaveAltIcon />}
            loading={appState.isExportingExcel}
            onClick={exportStock}
            style={{ fontFamily: 'Poppins' }}
            className={styles.exportExcelButton}
          >
            Stock
          </LoadingButton>
        </div>
      </div>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="h-[64vh] overflow-y-scroll flex flex-wrap">
            {itemsState?.items?.length > 0 &&
              itemsState?.items?.map((item: Item) => (
                <ProductCard
                  onUpdate={() => {
                    setProduct(item);
                    dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                  }}
                  onAdjust={() => {
                    console.log('on adjustingggggggg ');
                    setProduct(item);
                    setAdjustStock(true);
                  }}
                  onDelete={() => {
                    setProduct(item);
                    dispatch(appActions.setIsDeleting(true));
                  }}
                  onUpload={() => {
                    setProduct(item);
                    setUploadPicture(true);
                  }}
                  key={item._id}
                  product={item}
                />
              ))}
          </div>
          <TablePagination
            component="div"
            count={appState.count}
            labelRowsPerPage={'Products per page'}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {appState.openForm.open && <NewShopItem item={product} />}
          {adjustStock && <AdjustStock open={adjustStock} close={() => setAdjustStock(false)} item={product} />}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={() => {
                handleDeleteItem();
              }}
              content={`Please confirm to delete ${product?.name}`}
            />
          )}
          {uploadPicture && (
            <UploadImage
              open={uploadPicture}
              close={() => setUploadPicture(false)}
              title={'Add Product Image'}
              uploading={uploading}
              uploadHandler={uploadImageHandler}
            />
          )}
        </>
        // <ItemsTable
        //   count={count}
        //   page={page}
        //   setPage={setPage}
        //   rowsPerPage={rowsPerPage}
        //   setRowsPerPage={setRowsPerPage}
        // />
      )}
    </Layout>
  );
};

export default Items;
