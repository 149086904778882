import { PaymentMethod } from '../interfaces/Project/paymentMethods';

// export const SERVER_URL = 'http://127.0.0.1:4000/api/v2';

export const SERVER_URL = 'https://backend.traceup.rw/api/v2';

export const paymentMethods: PaymentMethod[] = [
  {
    name: 'Cash',
    id: 'cash',
  },
  {
    name: 'Momo',
    id: 'momo',
  },
  {
    name: 'Cheque',
    id: 'cheque',
  },
  {
    name: 'Bank',
    id: 'bank',
  },
];
