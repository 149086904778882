import React, { useState } from 'react';
import { Box, Button, CircularProgress, DialogContentText, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styles from './index.module.scss';
import deleteSvg from '../assets/images/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../store/app';
import { Customer } from '../interfaces/shop/customers';

interface ConfirmModalProps {
  onConfirm: Function;
  content: string;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState({} as Customer);

  return (
    <div>
      <Dialog
        open={appState.isDeleting}
        classes={{ paper: styles.confirmModal }}
        onClose={() => dispatch(appActions.setIsDeleting(false))}
        style={{ padding: '3rem' }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={styles.vertical}>
            <img src={deleteSvg} alt="delete" style={{ height: '10rem', width: '10rem' }} />
            <Typography className={styles.confirmModal_title}>Confirm Delete</Typography>
            <Box style={{ maxWidth: '50%', marginTop: '1rem' }}>
              <Typography className={styles.confirmModal_text}>{props.content}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.vertical}>
          {!appState.isSubmitting && (
            <Button onClick={() => props.onConfirm()} variant="contained" className={styles.confirmModal_Confirm}>
              Delete
            </Button>
          )}
          {appState.isSubmitting && (
            <Button variant="contained" className={styles.confirmModal_Confirm}>
              <CircularProgress size={30} color="inherit" className={styles.circularProgress} />
            </Button>
          )}
          <Button onClick={() => dispatch(appActions.setIsDeleting(false))} className={styles.confirmModal_Cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
