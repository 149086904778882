import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../../store/app';
import { shopActions } from '../../../store/shop';
import { addAction } from '../../../store/shop/actions/globalActions';
import { addShopSupplierService } from '../../../store/shop/suppliers/suppliersService';
import Storage from '../../../utils/Storage';
import GenericForm from '../../../components/GenericForm';

export default function NewShopSupplier() {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState = useSelector((state: any) => state.app);

  const newCustomerElements = [
    {
      label: 'Supplier Name',
      name: 'name',
      type: 'text',
      placeholder: 'Enter Supplier Name',
      rules: [{ required: true, message: 'Please enter the supplier name' }],
    },
    {
      label: 'Contact',
      name: 'contact',
      type: 'text',
      placeholder: 'Enter Customer Supplier Number',
    },
    {
      label: 'Tin Number',
      name: 'tinNumber',
      type: 'number',
      placeholder: 'Enter Supplier Tin Number',
    },
  ];

  const addSupplierHandler = async (values: any) => {
    let data: any = {};
    data.name = values.name;
    data.project = Storage.getProject()._id;
    if (values.contact) data.contact = +values.contact;
    if (values.tinNumber) data.tinNumber = +values.tinNumber;

    dispatch(addAction(addShopSupplierService, data, token, () => dispatch(shopActions.setFetchedSuppliers(false))));
  };

  return (
    <GenericForm
      title="New Supplier"
      onSubmit={addSupplierHandler}
      formElements={newCustomerElements}
      style="vertical"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: '' }));
      }}
    />
  );
}
