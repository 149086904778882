import { LooseObject } from '../construction/constants';

export interface shopPaymentColumn {
  id: 'date' | 'customer' | 'supplier' | 'debt' | 'amountPaid' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopPaymentsInColumns: shopPaymentColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'customer', label: 'Customer', align: 'center' },
  { id: 'debt', label: 'Debt', align: 'center' },
  { id: 'amountPaid', label: 'Amount Paid', align: 'center' },
];

export const shopPaymentsOutColumns: shopPaymentColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'supplier', label: 'Supplier', align: 'center' },
  { id: 'debt', label: 'Debt', align: 'center' },
  { id: 'amountPaid', label: 'Amount Paid', align: 'center' },
];

export interface shopPaymentData {
  id: string;
  amountPaid: string;
  date: string;
  supplier?: string;
  customer?: string;
  debt: string;
  action: any;
}

//Date	Customer	Item	Quantity	 buyingPrice 	 totalCost 	 SellingPrice 	 totalPrice 	 totalPaid 	 Profit
export interface saleExportData {
  date: string;
  customer: string;
  item: string;
  quantity: number;
  buyingPrice: number;
  totalCost: number;
  sellingPrice: number;
  totalPrice: number;
  totalPaid: number;
  profit: number;
}

export interface NewSalesData {
  date: string;
  item: string;
  customer?: string;
  quantity: number;
  unitPrice: number;
  buyingPrice: number;
  totalPaid: number;
  project: string;
}
