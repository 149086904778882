import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { useDispatch } from 'react-redux';
import { appActions } from '../../../store/app';
import { Typography, Menu, MenuItem, Box, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableComponent from '../../../components/TableComponent';
import { Expense, expenseColumns, expenseData } from '../../../interfaces/construction/expense';
import ConfirmModal from '../../../components/ConfirmModal';
import styles from '../../../pages/shop/index.module.scss';
import { numberWithCommas } from '../../../utils/functions';
import NewExpense from './newExpense';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import Storage from '../../../utils/Storage';
import { TableProps } from '../../../interfaces';
import { ShopExpense } from '../../../interfaces/shop/shopExpense';
import { deleteShopExpenseService } from '../../../store/shop/expenses/shopExpenseService';
import { ShopState, shopActions } from '../../../store/shop';

const ExpensesTable = (props: TableProps) => {
  const { count, page, setPage, rowsPerPage, setRowsPerPage } = props;
  const dispatch = useDispatch();
  const [expense, setExpense] = useState({} as ShopExpense);
  const token = Storage.getToken();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const appState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteExpenseHandler = () => {
    dispatch(
      deleteAction(deleteShopExpenseService, expense._id, token, 'Expense', () =>
        dispatch(shopActions.setFetchedShopExpenses(false))
      )
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const createData = (expense: ShopExpense): expenseData => {
    return {
      id: expense._id,
      description: expense.description,
      amount: numberWithCommas(expense.amount),
      date: expense.date.substring(0, 10),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setExpense(expense);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.shopExpenses?.map((expense: ShopExpense) => createData(expense));

  return (
    <>
      {appState?.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}

      {!appState?.isFetching && (
        <TableComponent
          rows={rows}
          columns={expenseColumns}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowClickedHandler={() => {}}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {appState.openForm.open && (
        <NewExpense open={appState.openForm.open} close={() => dispatch(appActions.closeForm())} expense={expense} />
      )}
      {open && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <VisibilityIcon />
            <Typography> View</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
            }}
          >
            <AutorenewIcon />
            <Typography> Update</Typography>
          </MenuItem>
          <MenuItem
            onClick={async () => {
              setAnchorEl(null);
              dispatch(appActions.setIsDeleting(true));
            }}
          >
            <DeleteIcon /> <Typography> Delete</Typography>
          </MenuItem>
        </Menu>
      )}
      <ConfirmModal
        onConfirm={async () => {
          deleteExpenseHandler();
          handleClose();
        }}
        content={`Are you sure you want to Delete ${expense.description} Expense`}
      />
    </>
  );
};

export default ExpensesTable;
