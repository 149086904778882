import { shopActions } from '..';
import store from 'store';
import { setResponseAction } from '../../../utils/functions';
import app, { appActions } from '../../app';
import { getShopCustomersService } from '../customers/customersServices';
import { getItemsService } from '../../items/itemsService';
import { getManufacturersService } from '../manufacturers/manufacturersService';
import { getSuppliersService } from '../suppliers/suppliersService';
import { getMonthlyProfitService, getShopDashboardService, getShopsService } from './shopServices';
import { selectedShopActions } from '../../selectedShop';
import { itemActions } from '../../items';

export const getShopsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getShopsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(selectedShopActions.setShops(res.data));
        store.set('project', res.data[0]);
        // dispatch(shopActions.resetShopState());
        // store.set('shops', res.data);
      }
      dispatch(appActions.setIsFetching(false));
      // store.set('fetchedShops', true);
      dispatch(selectedShopActions.setFetchedShops(true));
    } catch (err: any) {
      console.log(err.response.data);
      dispatch(setResponseAction(err));
    }
  };
};

export const changeShopProjectAction = (project: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      store.set('project', project);
      dispatch(appActions.setIsFetching(false));
    } catch (err: any) {
      console.log(err.response.data);
      dispatch(setResponseAction(err));
    }
  };
};

export const getShopProject = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      await getShopsService(query, token);
    } catch (err) {
      console.log(err);
      dispatch(setResponseAction(err));
    }
  };
};

export const getShopDashboard = (projectId: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(shopActions.setFetchingDashboard(true));
    const res = await getShopDashboardService(projectId, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(shopActions.setDashboard(res.data));
    }
    dispatch(shopActions.setFetchingDashboard(false));
  };
};

export const getMonthlyProfit = (projectId: string, month: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    const res = await getMonthlyProfitService(projectId, month, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(shopActions.setProfit(res.data));
      dispatch(appActions.setChartData(res.data));
    }
    dispatch(appActions.setIsFetching(false));
  };
};

export const setShopState = (projectId: string, token: string, navigate: Function) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    //manufacturers
    const manufacturers = await getManufacturersService(`?project=${projectId}`, token);
    dispatch(shopActions.setManufacturers(manufacturers.data));
    //items
    dispatch(itemActions.setFetchedItems(false));
    // const items = await getItemsService(`?project=${projectId}&limit=800`, token);
    // dispatch(shopActions.setItems(items.data));
    // customers
    const customers = await getShopCustomersService(`?fields=name&debts=false&project=${projectId}`, token);
    dispatch(shopActions.setCustomers(customers.data));
    // suppliers
    const suppliers = await getSuppliersService(`?fields=name&debts=false&project=${projectId}`, token);
    dispatch(shopActions.setSuppliers(suppliers.data));

    dispatch(appActions.setIsFetching(false));
    navigate();
  };
};
