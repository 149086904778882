import { Grid, Typography, Box, Divider, Avatar, Button, Tabs, Tab } from '@mui/material';
import store from 'store';
import styles from '../../shop/index.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import { User } from '../../../interfaces/Project/user';
import Storage from '../../../utils/Storage';
import { setProfilePictureService } from '../../../store/app/user/userService';
import { authActions } from '../../../store/auth';
import { successToast } from '../../../store/shop/actions/globalActions';
import UploadImage from '../../../components/UploadImage';
import FormInputs from '../../../components/inputs/FormInputs';

const ProfileSettings = () => {
  const user: User = Storage.getUser();
  const token = Storage.getToken();
  const [activeTab, setActiveTab] = useState(0);
  const [uploading, setUploading] = useState(false);
  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const [uploadPicture, setUploadPicture] = useState(false);

  const uploadProfilePictureHandler = async (data: any) => {
    try {
      setUploading(true);
      const res = await setProfilePictureService(user._id, data, token);
      setUploading(false);
      if (res.status === 200) {
        var newUser: User = { ...user, image: res.data };
        store.set('user', newUser);
        dispatch(authActions.setUser(newUser));
        dispatch(successToast('Profile set successfully!'));
        setUploadPicture(false);
      }
    } catch (error) {
      console.log('error ', error);
    }
  };

  const userAccountElements = [
    {
      label: 'User Name',
      name: 'username',
      inputComponent: "input",
      type: 'text',
      value: user?.name,
      placeholder: 'Your Last Name',
    },
    {
      label: 'Phone Number',
      name: 'contact',
      inputComponent: "input",
      type: 'text',
      value: user?.contact,
      placeholder: 'Contact',
    },
    {
      label: 'E-mail',
      name: 'email',
      inputComponent: "input",
      type: 'text',
      value: user?.email,
      placeholder: 'Enter Your email',
    },
    {
      label: 'Role',
      name: 'role',
      inputComponent: "input",
      type: 'text',
      value: user?.role,
      disabled: true,
    },
  ];

  const changePasswordElements = [
    {
      label: 'Old Password',
      name: 'username',
      inputComponent: "input",
      type: 'text',
      placeholder: 'Old Password',
    },
    {
      label: 'New Password',
      name: 'contact',
      inputComponent: "input",
      type: 'text',
      placeholder: 'New Password',
    },
    {
      label: 'Confirm New Password',
      name: 'email',
      inputComponent: "input",
      type: 'text',
      placeholder: 'Confirm New Password',
    },
  ];

  return (
    <>
      <Box className={styles.profileContainer}>
        <div className={styles.profileTitle}>Account</div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box className={styles.profilePhotoCard}>
              <Avatar
                style={{
                  height: '5rem',
                  width: '5rem',
                }}
              >
                <img className={styles.profilePicture} src={user?.image?.url} alt="profile" />
              </Avatar>
              <div className={styles.profileName}>{user?.name}</div>
              <div className={styles.profileSecondaryText}>{user?.email}</div>
              <div className={styles.profileSecondaryText}>{user?.contact}</div>
              <Divider />
              <Divider />
              <Button style={{fontFamily:'Poppins'}} onClick={() => setUploadPicture(true)}>
                Upload Image
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div className="bg-white  rounded-lg">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab style={{fontFamily:'Poppins'}} label="Profile" />
                <Tab style={{fontFamily:'Poppins'}} label="CHANGE PASSWORD" />
              </Tabs>
              <Box className={styles.profileLeftSection}>
                {activeTab === 0 && (
                  <div className="">
                    <Box className={styles.profileTopSection}>
                      <Typography style={{fontFamily:'Poppins'}} variant="h6">Profile</Typography>
                      <Typography style={{fontFamily:'Poppins'}}>The information can be edited</Typography>
                    </Box>
                    <Divider style={{ marginBottom: '2rem' }} />
                    <Box className={styles.genericForm_column}>
                      {userAccountElements.map((element: any, index: number) => {
                        return <FormInputs key={index} element={element} style="vertical" />;
                      })}
                    </Box>

                    <Divider style={{ marginTop: '0rem' }} />
                    <Box className={`${styles.right} ${styles.mg1Vertical}`}>
                      <Button startIcon={<SaveIcon />} style={{fontFamily:'Poppins'}} variant="contained">
                        Save
                      </Button>
                    </Box>
                  </div>
                )}
                {activeTab === 1 && (
                  <>
                    <Typography></Typography>
                    <Box className={`${styles.changePasswordContainer}`}>
                      {changePasswordElements.map((element: any, index: number) => {
                        return <FormInputs key={index} element={element} style="vertical" />;
                      })}

                      <Box className={styles.center}>
                        <Button sx={{ marginTop: '2rem' }} variant="contained" startIcon={<EditIcon />}>
                          Change
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
      {uploadPicture && (
        <UploadImage
          open={uploadPicture}
          close={() => setUploadPicture(false)}
          title="Set Profile Picture"
          uploading={uploading}
          uploadHandler={uploadProfilePictureHandler}
        />
      )}
    </>
  );
};

export default ProfileSettings;
