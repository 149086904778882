import { Grid, Box, Typography } from '@mui/material';
import styles from '../index.module.scss';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import Storage from '../../../utils/Storage';
import DashboardCard from './DashboardCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DiamondIcon from '@mui/icons-material/Diamond';
import { primaryBlue, primaryGreen, primaryOrange, primaryPurple } from '../../../assets/colors/colors';
import { useEffect } from 'react';
import { getShopDashboard, getShopsAction, setShopState } from '../../../store/shop/shop/shopActions';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../../utils/functions';
import { Skeleton } from '@mui/lab';
import ChartComponent from './ChartComponent';
import Greetings from '../../../components/Greeting';
import { useTranslation } from '../../../i18n';
import Notifications from './Notifications';
import { User } from '../../../interfaces/Project/user';
import { useNavigate } from 'react-router-dom';
import { ShopState } from '../../../store/shop';
import { AppState } from '../../../store/app';
import NoData from '../../../components/NoData';
import { SelectedShopState } from '../../../store/selectedShop';

const Shop = () => {
  const dispatch = useDispatch();
  const project = Storage.getProject()?._id;
  const token = Storage.getToken();
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const selectedShopState: SelectedShopState = useSelector((state: any) => state.selectedshop);
  const appState: AppState = useSelector((state: any) => state.app);
  const user: User = Storage.getUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !token ||
      (appState.httpResponse.error &&
        appState.httpResponse.errorStatusCode === 401 &&
        appState.httpResponse.errorMessage === 'Unauthorized')
    ) {
      return navigate('/login');
    }
    if (!selectedShopState.fetchedShops) {
      dispatch(getShopsAction(`?company=${user?.company?._id}&isActive=true`, token));
    }
    console.log('Yes no project, go and add one>>>>>> ', selectedShopState.fetchedShops, ' project ', project);
    if (selectedShopState.fetchedShops && !project) {
      navigate('/shop/settings/shops');
    }
  }, [
    dispatch,
    appState.updateNeeded,
    appState.httpResponse.error,
    appState.httpResponse.errorStatusCode,
    appState.httpResponse.errorMessage,
    project,
    navigate,
  ]);

  useEffect(() => {
    console.log('Ladies and gents boys and girls the project changed  ', project);
    if (project) {
      dispatch(getShopDashboard(project, token));
      dispatch(setShopState(project, token, () => {}));
    }
  }, [project]);

  return (
    <>
      {project && (
        <Layout title={`${Storage.getProject().name} • ${t('dashboard')}`} navMenu={<ShopNavMenu select={1} />}>
          <Greetings />
          {!shopState.dashboard.isFetching && (
            <>
              <div className="flex items-center mt-2 p-4  rounded-2xl text-gray-300 bg-[#252733] w-[24%] mb-2">
                <AttachMoneyIcon style={{ height: '2rem', width: '2rem' }} className="text-teal-500 me-4" />
                <div>
                  <div>{t('monthlyProfit')}</div>
                  <div className="font-semibold text-xl">{`${numberWithCommas(
                    shopState?.dashboard?.stats?.profit
                  )} Rwf`}</div>
                </div>
              </div>
              <Grid className={styles.statsCardContainer} container spacing={2}>
                <Grid item xs={3}>
                  <DashboardCard
                    label={t('shopValue')}
                    metric={`${numberWithCommas(shopState?.dashboard?.stats?.stockValue)} Rwf`}
                    primaryColor={primaryPurple}
                    accentColor="#e8e8e8"
                    icon={<DiamondIcon style={{ color: primaryPurple }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DashboardCard
                    label={t('monthlyExpenses')}
                    metric={`${numberWithCommas(shopState?.dashboard?.stats?.expenses)} Rwf`}
                    primaryColor={primaryGreen}
                    accentColor="#dff7ea"
                    icon={<AttachMoneyIcon style={{ color: primaryGreen }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DashboardCard
                    label={t('totalDebt')}
                    metric={`${numberWithCommas(shopState?.dashboard?.stats?.totalDebt)} Rwf`}
                    primaryColor={primaryBlue}
                    accentColor="#e6effa"
                    icon={<AutoAwesomeMosaicIcon style={{ color: primaryBlue }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DashboardCard
                    label={t('totalCredit')}
                    metric={`${numberWithCommas(shopState?.dashboard?.stats?.totalCredit)} Rwf`}
                    primaryColor={primaryOrange}
                    accentColor="#fce5d7"
                    icon={<AccountBalanceWalletIcon style={{ color: primaryOrange }} />}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {!project ||
            (shopState.dashboard.isFetching && (
              <Box className={styles.dashboard_skeleton_cards}>
                <Skeleton className={styles.dashboard_skeleton_card} />
                <Skeleton className={styles.dashboard_skeleton_card} />
                <Skeleton className={styles.dashboard_skeleton_card} />
                <Skeleton className={styles.dashboard_skeleton_card} />
              </Box>
            ))}
          <Grid container>
            <Grid item xs={9}>
              <ChartComponent />
            </Grid>
            <Grid item xs={3}>
              <Notifications />
            </Grid>
          </Grid>
        </Layout>
      )}
      {!project && <NoData />}
    </>
  );
};

export default Shop;
