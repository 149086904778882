import { Button, Grid, Tab, Tabs } from '@mui/material';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import Layout from '../../../components/Layout';
import Storage from '../../../utils/Storage';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ShopState, shopActions } from '../../../store/shop';
import { useDispatch, useSelector } from 'react-redux';
import { Item } from '../../../interfaces/shop/shopItems';
import { useEffect, useState } from 'react';
import ItemSpecs from './item_specs';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AppState, appActions } from '../../../store/app';
import { getItemHistory, searchShopItems } from '../../../store/items/itemsActions';
import LoadingContainer from '../../../components/LoadingContainer';
import ItemAdjustments from './item_adjustments';
import { itemActions, ItemsState } from '../../../store/items';

const ItemDetails = () => {
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const itemsState: ItemsState = useSelector((state: any) => state.items);
  const appState: AppState = useSelector((state: any) => state.app);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;
  const [searchText, setSearchText] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getItemHistory(itemsState?.item?._id, Storage.getToken()));
  }, [itemsState?.item?._id]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopItems(`?text=${searchText}&project=${project}`, token));
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token, itemsState.fetchedItems]);

  return (
    <Layout title={`${Storage.getProject().name} • ITEMS`} navMenu={<ShopNavMenu />}>
      <div className="flex items-center justify-between my-5">
        <div className="flex items-center">
          <Link to="/shop/items">
            <ArrowCircleLeftOutlinedIcon className="cursor-pointer" />
          </Link>
          <Typography className="font-bold text-xl ms-2">{`Products / ${itemsState.item.name}`}</Typography>
        </div>
        <Button
          className="bg-blue-500"
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: 'add' }));
          }}
        >
          Add Item
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="bg-white overflow-y-scroll rounded-lg p-4 mt-2 overflow-hidden h-[80vh]">
            <div className="flex border rounded-lg mx-1 p-1">
              <SearchIcon className="me-3" />
              <input
                placeholder="Search Item"
                className="w-full outline-none"
                type="text"
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSearchText(event.target.value);
                }}
              />
              {searchText !== '' && (
                <CloseIcon className="ms-2 cursor-pointer text-gray-500" onClick={() => setSearchText('')} />
              )}
            </div>
            {itemsState?.items?.length > 0 &&
              itemsState?.items?.map((stockItem: Item) => (
                <div
                  key={stockItem?._id}
                  className="cursor-pointer h-20 flex items-center px-4 py-2 border-b border-gray-200"
                  onClick={() => {
                    dispatch(itemActions.setItem(stockItem));
                  }}
                >
                  {stockItem?.image?.url && (
                    <img className="h-[70%] w-[40%] border me-2" src={stockItem?.image?.url} alt="pff" />
                  )}
                  <div>{stockItem?.name.toUpperCase()}</div>
                </div>
                // <ProductCard key={product._id} product={product} />
              ))}
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="mt-1 bg-white overflow-y-scroll h-[81vh]">
            <div className="flex justify-center">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Movement" />
                <Tab label="Adjustments" />
              </Tabs>
            </div>
            <div className="p-8">
              {!appState?.drawerFetching && (
                <>
                  {activeTab === 0 && (
                    <>
                      <ItemSpecs />
                    </>
                  )}
                  {activeTab === 1 && <ItemAdjustments />}
                </>
              )}
              {appState?.drawerFetching && (
                <>
                  <LoadingContainer />
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ItemDetails;
