const translationsEn = {
  welcome: 'Bienvenue!!',
  search: 'Search',
  exportExcel: 'Export File',
  months: 'Months',
  logout: 'Logout',
  report: 'Report',
  addNew: 'Add New',
  firstName: 'First Name',
  lastName: 'Last Name',
  name: 'Name',
  nationalID: 'National ID',
  email: 'Email',
  position: 'Position',
  gender: 'Gender',
  dob: 'Date Of Birth',
  action: 'Action',
  delete: 'Delete',
  view: 'View',
  update: 'Update',
  cancel: 'Cancel',
  confirmDelete: 'Confirm Delete',
  confirmDeleteDesc: 'Are you sure you want to delete',
  deleteSuccess: 'deleted Successfully!',
  male: 'Male',
  female: 'Female',
  save: 'Save',
  filter: 'Filter',
  photo: 'Photo',
  afterNoon: 'After Noon',
  morning: 'Morning',
  L: 'Liter',
  Kg: 'Kilogram',
  m: 'Meter',
  goodMorning: 'Good Morning',
  goodAfterNoon: 'Good Afternoon',
  goodEveing: 'Good Evening',
  hello: 'Hello',
  shopValue: 'Shop Value',
  monthlyProfit: 'Monthly Profit',
  monthlyExpenses: 'Monthly Expenses',
  totalDebt: 'Debt',
  totalCredit: 'Credit',
  profit: 'Profit',
  dashboard: 'Dashboard',
  sales: 'Sales',
  purchases: 'Purchases',
  stock: 'Stock',
  items: 'Items',
  customers: 'Customers',
  suppliers: 'Suppliers',
  payments: 'Payments',
  date: 'Date',
  customer: 'Customer',
  itemName: 'Item Name',
  quantity: 'Quantity',
  unitPrice: 'Unit Price',
  totalPrice: 'Total Price',
  paid: 'Paid',
  credit: 'Credit',
  shopExpenses: 'Expenses',
  adj_out: 'Adjustment Out',
  adj_in: 'Adjustment In',
  purchase: 'Purchase',
  quicksales: 'QuickSales',
};

export default translationsEn;
