import { LooseObject } from '../../interfaces/construction/constants';
import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getItemsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/items${query}`, token);
};

export const getItemHistoryService = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/items/history/${itemId}`, token);
};

export const searchItemsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/items/search/items${query}`, token);
};

export const addItemService = async (data: LooseObject, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/items`, data, token);
};

export const updateItemService = async (itemId: string, data: LooseObject, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/items/${itemId}`, data, token);
};

export const deleteItemService = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/items/${itemId}`, token);
};

export const uploadProductImageService = async (itemId: string, data: LooseObject, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/items/${itemId}/uploadimage`, data, token);
};
