import { Box, Typography } from '@mui/material';
import { generateGreetings } from '../utils/functions';
import Storage from '../utils/Storage';
import styles from './index.module.scss';

const Greetings = () => {
  const user = Storage.getUser();
  return (
    <Box>
      <div className={styles.greetingText}>
        {generateGreetings()}, {user?.username}
      </div>
    </Box>
  );
};

export default Greetings;
