import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { useEffect } from 'react';
import Storage from '../../../utils/Storage';
import { ShopState, shopActions } from '../../../store/shop';
import { useTranslation } from '../../../i18n';
import { addAction, updateAction } from '../../../store/shop/actions/globalActions';
import { addShopService, updateShopService } from '../../../store/shop/shop/shopServices';
import GenericForm from '../../../components/GenericForm';
import { selectedShopActions } from '../../../store/selectedShop';

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  onSubmit: Function;
  shop?: any;
  styles?: string;
  confirmText?: string;
}

const AddShop = (props: GenericFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const shopState: ShopState = useSelector((state: any) => state.shop);

  const shopCategories: any = [
    {
      name: 'Pharmacy',
      _id: 'pharmacy',
    },
    {
      name: 'Alimentation',
      _id: 'alimentation',
    },
    {
      name: 'Papeterie',
      _id: 'papeterie',
    },
    {
      name: 'Quincallerie',
      _id: 'quincallerie',
    },
  ];

  useEffect(() => {
    if (props.shop) {
      form.setFieldsValue({
        name: props?.shop.name,
        address: props?.shop.address,
        tinNumber: props?.shop.tinNumber,
        tags: props?.shop.tags,
      });
    }
  }, []);

  const onFinish = (values: any) => {
    values.company = Storage.getUser()?.company?._id;

    if (props.shop) {
      dispatch(
        updateAction(updateShopService, props?.shop?._id, values, token, () => {
          props.close();
          dispatch(selectedShopActions.setFetchedShops(false));
        })
      );
    } else {
      dispatch(
        addAction(
          addShopService,
          values,
          token,
          () => props.close(),
          () => {
            props.close();
            dispatch(selectedShopActions.setFetchedShops(false));
          }
        )
      );
    }
  };

  const formElements = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      rules: [{ required: true, message: 'Please input your username!' }],
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
    },
    {
      name: 'tinNumber',
      label: 'Tin Number',
      type: 'number',
    },
    {
      name: 'shopCategory',
      label: 'Shop Category',
      type: 'select',
      options: shopCategories,
      rules: [{ required: true, message: 'Please select shop category' }],
    },
  ];

  return (
    <GenericForm
      open={props.open}
      close={() => props.close()}
      title="New Shop"
      style="vertical"
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default AddShop;
