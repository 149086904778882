import * as React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import store from 'store';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styles from '../../../components/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReceiptComponent from '../../../components/ReceiptComponent2';
import { shopActions, ShopState } from '../../../store/shop';
import { Manufacturer } from '../../../interfaces/shop/manufacturers';
import { Item } from '../../../interfaces/shop/shopItems';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { getPurchaseRequesites } from '../../../store/purchases/purchasesActions';
import { addAction, updateItemsStock } from '../../../store/shop/actions/globalActions';
import Storage from '../../../utils/Storage';
import { appActions, AppState } from '../../../store/app';
import moment from 'moment';
import { NewPurchaseItem } from '../../../interfaces/shop/purchaseItem';
import { NewPurchase } from '../../../interfaces/shop/purchase';
import FormHeader from '../../../components/FormHeader';
import FormInputs from '../../../components/inputs/FormInputs';
import { LayoutType, layout } from '../../../components/GenericForm';
import dayjs from 'dayjs';
import { increaseItemStock } from '../../../store/items/itemsActions';
import { addPurchasesService } from '../../../store/purchases/purchasesService';
import { itemActions, ItemsState } from '../../../store/items';
import { purchasesActions } from '../../../store/purchases';

interface newPurchaseProps {
  close: Function;
  purchase?: any;
}

export default function NewShopPurchase(props: newPurchaseProps) {
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const currentDate = dayjs();
  const [supplier, setSupplier] = useState({} as Supplier | null);
  const [manufacturer, setManufacturer] = useState({} as Manufacturer | null);
  const [form] = Form.useForm();
  const [item, setItem] = useState({} as Item | any);
  const [list, setList] = useState([] as NewPurchaseItem[]);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(-1);
  const project = Storage.getProject();
  const token = Storage.getToken();
  const { Option } = Select;
  const [updatingPurchase, setUpdatingPurchase] = useState(false);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const appState: AppState = useSelector((state: any) => state.app);
  const itemsState: ItemsState = useSelector((state: any) => state.items);
  const updating = appState.openForm.action === 'update';

  const resetFields = (allFields: Boolean = false) => {
    form.resetFields(['quantity', 'item', 'barCode', 'unitPrice', 'totalPrice', 'totalPaid']);
    setItem({});
    form.getFieldInstance('barCode').focus();
    if (allFields) setSupplier(null);
  };

  const onManufacturerChanged = (element: Manufacturer) => {
    setManufacturer(element);
    let newItems = itemsState?.items?.filter((item: Item) => item?.manufacturer?._id === element?._id);
    dispatch(itemActions.setSelectedItems(newItems));
  };

  // const onItemChanged = (element: Item) => {
  //   setItem(element);
  //   setValue('unitPrice', element.buyingPrice * element.details?.quantity);
  // };

  const addPurchaseHandler = async () => {
    let data: NewPurchase = {
      date: form.getFieldValue('date')
        ? moment(form.getFieldValue('date')?.toDate()).format('yyyy-MM-DD') + new Date().toISOString().substring(10)
        : new Date().toISOString(),
      supplier: form.getFieldValue('supplier'),
      project: project._id,
      list,
    };

    dispatch(
      addAction(addPurchasesService, data, token, () => {
        // dispatch(updateItemsStock([...shopState.items], purchaseItems, 'add'));
        dispatch(purchasesActions.setFetchedPurchases(false));
      })
    );
  };

  const onItemClicked = async (inIndex: number) => {
    setUpdatingPurchase(true);
    // getProductItems(newPurchaseItems[inIndex].product);
    setIndex(inIndex);
    form.setFieldsValue({
      item: list?.[inIndex].item?._id,
      quantity: list?.[inIndex].quantity,
      buyingPrice: list?.[inIndex].buyingPrice,
      unitPrice: list?.[inIndex].unitPrice,
      totalPrice: list?.[inIndex].unitPrice * list?.[inIndex].quantity,
      totalPaid: list?.[inIndex].totalPaid,
    });
  };

  const onSubmit = (values: any) => {
    values.item = item;
    values.quantity = +values.quantity;
    delete values?.manufacturer;
    delete values?.barCode;

    var existingItem = list.findIndex((item) => {
      return item.item._id === values.item._id && item.unitPrice === +values.unitPrice;
    });
    if (existingItem > -1) {
      list[existingItem].quantity += values.quantity;
      list[existingItem].totalPaid += values.totalPaid;
      setList(list.slice());
    } else {
      setList([...list, values as NewPurchaseItem]);
    }

    dispatch(increaseItemStock(values.item._id, itemsState?.items, +values.quantity));
    resetFields();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const newPurchaseElements = [
    {
      // name: 'manufacturer',
      // label: 'Manufacturer',
      // type: 'select',
      // options: shopState.manufacturers,
      label: 'Supplier',
      name: 'supplier',
      type: 'select',
      options: shopState.suppliers,
      value: supplier,
      loading: appState.isFetching,
      handleSelectChange: (value: any) => {
        let supplier: Supplier | undefined = shopState?.suppliers.find((supplier: Supplier) => supplier?._id === value);
        if (supplier) {
          setSupplier(supplier);
        }
      },
    },
    project.usesBarCode
      ? {
          name: 'barCode',
          label: 'Bar Code',
          type: 'number',
          onChange: (value: any) => {
            let foundItem: Item | undefined = itemsState?.selectedItems.find(
              (currentItem: Item) => currentItem?.barCode == value
            );
            if (foundItem) {
              setItem(foundItem);
              form.setFieldsValue({
                item: foundItem._id,
                unitPrice: foundItem.buyingPrice,
                stock: item.stock,
                quantity: 1,
                totalPaid: foundItem.buyingPrice,
                totalPrice: foundItem.buyingPrice,
              });
            }
          },
        }
      : {
          label: 'Manufacturer',
          name: 'manufacturer',
          type: 'select',
          options: shopState.manufacturers,
          onChanged: onManufacturerChanged,
          value: manufacturer,
          loading: appState.isFetching,
          handleSelectChange: (value: any) => {
            console.log('chosenn mannn ', value);
            let newItems = itemsState?.items?.filter((item: Item) => item?.manufacturer?._id === value);
            dispatch(itemActions.setSelectedItems(newItems));
          },
        },
    {
      label: 'Item',
      name: 'item',
      type: 'select',
      options: itemsState.selectedItems,
      loading: appState.isFetching,
      value: item,
      handleSelectChange: (value: any) => {
        let item: Item | undefined = itemsState?.selectedItems.find((currentItem: Item) => currentItem?._id === value);
        if (item) {
          setItem(item);
          form.setFieldsValue({
            unitPrice: item.buyingPrice,
            stock: item.stock,
            quantity: 1,
            totalPaid: item.buyingPrice,
            totalPrice: item.buyingPrice,
          });
        }
      },
      rules: [{ required: true, message: 'Item is required' }],
    },
    {
      label: `Quantity ${item?.stock ? `(${item.stock} in stock)` : ''}`,
      name: 'quantity',
      type: 'number',
      placeholder: 'Enter Quantity Purchased',
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue('unitPrice'),
          totalPaid: +value * +form.getFieldValue('unitPrice'),
        });
      },
      rules: [{ required: true, message: 'Please enter the quantity purchased' }],
    },
    {
      label: 'Unit Price',
      name: 'unitPrice',
      type: 'number',
      placeholder: 'Enter Selling Price',
      onChange: (value: any) => {
        form.setFieldsValue({
          totalPrice: +value * +form.getFieldValue('quantity'),
          totalPaid: +value * +form.getFieldValue('quantity'),
        });
      },
    },
    {
      label: 'Total Price',
      name: 'totalPrice',
      type: 'number',
      placeholder: 'Enter Paid Amount',
      onChange: (value: any) => {
        form.setFieldsValue({
          unitPrice: +value / +form.getFieldValue('quantity'),
          totalPaid: +value,
        });
      },
    },
    {
      label: 'Total Paid',
      name: 'totalPaid',
      type: 'number',
      placeholder: 'Enter Paid Amount',
      rules: [{ required: true, message: 'Total Paid is Required field' }],
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      placeholder: 'Enter Date Of Purchase',
    },
  ];

  useEffect(() => {
    if (!itemsState.fetchedItems || !shopState.fetchedManufacturers || !shopState.fetchedSuppliers)
      dispatch(
        getPurchaseRequesites(project._id, token, {
          fetchedItems: itemsState.fetchedItems,
          fetchedCustomers: shopState.fetchedCustomers,
          fetchedManufacturers: shopState.fetchedManufacturers,
          fetchedSuppliers: shopState.fetchedSuppliers,
        })
      );

    if (appState.openForm.action === 'update') {
      setList([...props?.purchase?.list]);
      form.setFieldsValue({
        supplier: props?.purchase?.supplier?._id,
        date: dayjs(props?.purchase?.date),
      });
    }

    return () => {
      resetFields(true);
    };
  }, []);

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog classes={{ paper: styles.paper }} open={appState.openForm.open} onClose={() => props.close()}>
        <FormHeader title="New Purchase" close={props.close} />
        <DialogContent>
          <Grid container>
            <Grid item xs={8}>
              <Form
                {...layout}
                layout={formLayout}
                form={form}
                name="control-hooks"
                onFinish={onSubmit}
                className="my_horizontal_form"
              >
                <Box className={styles.genericForm_column}>
                  {newPurchaseElements.map((element: any, index: number) => (
                    <Box key={element.name} className={styles.forms_input_horizontal}>
                      <Form.Item name={element.name} label={element.label} rules={element.rules ? element.rules : []}>
                        {element.type === 'number' && (
                          <InputNumber
                            onKeyDown={handleKeyDown}
                            onChange={element?.onChange}
                            disabled={element?.disabled ?? false}
                          />
                        )}
                        {element.type === 'text' && <Input disabled={element?.disabled ?? false} />}
                        {element.type === 'date' && <DatePicker defaultValue={currentDate} />}
                        {element.type === 'select' && (
                          <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={element?.handleSelectChange}
                            filterOption={(input, option) =>
                              (option?.children?.toString() as string).toLowerCase().includes(input.toLowerCase())
                            }
                          >
                            {element.options.map((opt: any) => (
                              <Option key={opt._id} value={opt._id}>
                                {opt.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Box>
                  ))}
                </Box>
                <Box className={styles.center}>
                  <Button type="submit" className={styles.confirmButton} variant="contained">
                    Add
                  </Button>
                </Box>
              </Form>
            </Grid>
            <Grid item xs={4}>
              <ReceiptComponent
                title="Purchase"
                list={list}
                updating={updating}
                name={supplier?.name}
                tin={supplier?.tinNumber}
                onItemClicked={onItemClicked}
                onSave={addPurchaseHandler}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
