import { createSlice } from "@reduxjs/toolkit";
import { Shop } from "../../interfaces/shop/project";

export interface SelectedShopState {
  shops: Shop[];
  shop: Shop | null;
  fetchedShops: boolean;
}

const initialState: SelectedShopState = {
  shops: [],
  shop: null,
  fetchedShops: false,
};

const selectedShopSlice = createSlice({
  name: "selectedshop",
  initialState,
  reducers: {
    setShops(state, action) {
      state.shops = action.payload;
    },
    setShop(state, action) {
      state.shop = action.payload;
    },
    setFetchedShops(state, action) {
      state.fetchedShops = action.payload;
    },
    resetSelectedShopState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const selectedShopActions = selectedShopSlice.actions;

export default selectedShopSlice.reducer;
