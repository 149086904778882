import { shopActions } from '..';
import { Item } from '../../../interfaces/shop/shopItems';
import { setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { itemActions } from '../../items';

export const addAction = (
  service: Function,
  data: any,
  token: string,
  refresh: Function,
  onSuccess: Function = () => {},
) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(data, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', res.message));
      onSuccess();
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: '' }));
    refresh();
  };
};

export const updateAction = (service: Function, id: string, data: any, token: string, refresh: Function) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(id, data, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', res.message));
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: '' }));
    refresh();
  };
};

export const deleteAction = (service: Function, itemId: string, token: string, entity: string, refresh: Function) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(itemId, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', `${entity} deleted Successfully!`));
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setIsDeleting(false));
    refresh();
  };
};

export const updateItemsStock = (allItems: Item[], items: any, action: string = 'sub') => {
  return async (dispatch: any) => {
    let newItems = allItems;
    for (const item of items) {
      const index = allItems.findIndex((unModifiedItem) => {
        if (unModifiedItem?.manufacturer?.name) {
          return (
            unModifiedItem.manufacturer?.name === item?.item.manufacturer.name &&
            unModifiedItem.name === item?.item?.name
          );
        } else {
          return unModifiedItem.name === item?.item?.name;
        }
      });
      newItems[index] = {
        ...newItems[index],
        stock: action === 'add' ? newItems[index].stock + item.quantity : newItems[index].stock - item.quantity,
      };
    }
    dispatch(itemActions.setItems(allItems));
  };
};

export const successToast = (message: String) => {
  return async (dispatch: any) => {
    dispatch(
      appActions.setHttpResponse({
        statusCode: 200,
        status: "success",
        message,
      })
    );
  };
};
