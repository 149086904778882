import { createSlice } from '@reduxjs/toolkit';
import { QuickSale, QuickSalesItem } from '../../interfaces/shop/quickSales';

export interface QuickSalesState {
  quickSales: QuickSale[];
  productSales: QuickSalesItem[];
  fetchedQuickSales: boolean;
  quickSalesCount: number;
}

const initialState: QuickSalesState = {
  quickSales: [],
  productSales: [],
  fetchedQuickSales: false,
  quickSalesCount: 0,
};

const quickSalesSlice = createSlice({
  name: 'quicksales',
  initialState,
  reducers: {
    setQuickSales(state, action) {
      state.quickSales = action.payload;
    },
    setQuickSalesCount(state, action) {
      state.quickSalesCount = action.payload;
    },
    setFetchedQuickSales(state, action) {
      state.fetchedQuickSales = action.payload;
    },
    setProductSales(state, action) {
      state.productSales = action.payload;
    },
    resetQuickSalesState(state) {
      Object.assign(state, initialState);
    }
  },
});

export const quickSalesActions = quickSalesSlice.actions;

export default quickSalesSlice.reducer;
