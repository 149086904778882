import { shopActions } from '..';
import { LooseObject } from '../../../interfaces/construction/constants';
import { exportExcel, numberWithCommas, renderMoney, setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { addShopExpenseService, getShopExpensesService, searchShopExpenseService } from './shopExpenseService';

//CRUD Actions for Shop Items

// CREATE ONE
export const addItem = (data: LooseObject, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await addShopExpenseService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(
          appActions.setHttpResponse({
            statusCode: res?.response?.status,
            status: res?.response?.data?.status,
            message: res?.response?.data?.message,
          })
        );
      } else {
        dispatch(appActions.setIsFetching(false));
        dispatch(shopActions.setFetchedShopExpenses(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(appActions.setIsFetching(false));
    }
  };
};

// GET ALL
export const getShopExpenses = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getShopExpensesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setShopExpenses(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(shopActions.setFetchedShopExpenses(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchShopExpenses = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchShopExpenseService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(shopActions.setShopExpenses(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(shopActions.setFetchedShopExpenses(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportShopExpenses = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getShopExpensesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let exportData = res.data.map((inData: any, index: number) => {
          return {
            Number: index + 1,
            Date: inData?.date?.substring(0, 10),
            Description: inData?.description,
            amount: renderMoney(inData?.amount),
          };
        });
        exportExcel(exportData, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};
