import { NewQuickSalesData, QuickSale, QuickSalesItem } from '../../interfaces/shop/quickSales';
import { exportExcel, setResponseAction } from '../../utils/functions';
import { appActions } from '../app';
import { addQuickSalesService, getQuickSalesService } from './quickSalesService';
import { quickSalesActions } from '.';
import { Customer } from '../../interfaces/shop/customers';

export const getQuickSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getQuickSalesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(quickSalesActions.setQuickSales(res.data));
        dispatch(quickSalesActions.setQuickSalesCount(res.count));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(quickSalesActions.setFetchedQuickSales(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const newQuickSale = (
  data: NewQuickSalesData,
  existingQuickSales: QuickSale[],
  customer: Customer,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsSubmitting(true));
      const res = await addQuickSalesService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(quickSalesActions.setQuickSales([{ ...res.data, customer }, ...existingQuickSales]));
      }
      dispatch(appActions.setIsSubmitting(false));
      dispatch(appActions.setOpenForm({ open: false, action: '' }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductSales = (productId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getQuickSalesService(`?product=${productId}&limit=2000`, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        var list: QuickSalesItem[] = [];
        res.data.forEach((sale: QuickSale) => {
          list.push(...sale.list);
        });
        dispatch(quickSalesActions.setProductSales(list));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(quickSalesActions.setFetchedQuickSales(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeDeletedQuickSale = (allSales: QuickSale[], sellId: string) => {
  return async (dispatch: any) => {
    let newItems = allSales;
    newItems = newItems.filter((sell: any) => sell._id !== sellId);
    dispatch(quickSalesActions.setQuickSales(newItems));
  };
};

export const exportQuickSalesReport = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getQuickSalesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let salesItems: any = [];
        res.data.forEach((sale: QuickSale) => {
          var list: any = [];
          sale.list.map((item: any) => {
            list.push({
              customer: sale?.customer ?? 'Customer',
              paymentMethod: sale?.paymentMethod ?? 'N/A',
              ...item,
            });
          });
          salesItems.push(...list);
        });

        let exportData = salesItems.map((inData: any, index: number) => {
          return {
            Number: index + 1,
            Product: inData?.product?.name ?? 'Product',
            Quantity: inData?.quantity,
            UnitPrice: inData?.unitPrice,
            TotalPrice: inData?.quantity * inData?.unitPrice,
            Customer: inData?.customer?.name ?? 'Customer',
            Payment: inData?.isCredit ? 'Credit' : 'Paid',
            Profit: inData?.product?.profit * inData?.quantity,
          };
        });
        exportExcel(exportData, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};
