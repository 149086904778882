import { Menu, MenuItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../components/TableComponent';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import styles from '../index.module.scss';
import { shopStockColumns, shopStockData, Stock } from '../../../interfaces/shop/stock';
import LoadingContainer from '../../../components/LoadingContainer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ItemHistory from './ItemHistory';
import { ShopState } from '../../../store/shop';

const StockTable = (props: any) => {
  const { count, page, setPage, rowsPerPage, setRowsPerPage, handleChangePage, handleRowsPerPageChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [stock, setStock] = useState({} as Stock);
  const [adjustStock, setAdjustStock] = useState(false);
  const [itemHistory, setItemHistory] = useState(false);
  const appState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditAction = () => {
    handleClose();
  };

  const createData = (stock: Stock): shopStockData => {
    return {
      id: stock._id,
      date: stock.date?.substring(0, 10),
      item: stock.name,
      openingStock: stock?.openingStock / (stock?.details?.quantity || 1),
      purchases: stock?.purchases,
      sales: Math.round(stock?.sales * 100) / 100,
      closingStock: stock?.closingStock / (stock?.details?.quantity || 1),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setStock(stock);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.stock?.map((stock: any) => createData(stock));

  return (
    <>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <TableComponent
          rows={rows}
          columns={shopStockColumns}
          rowClickedHandler={() => {}}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {open && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setItemHistory(true);
            }}
          >
            <ReceiptLongIcon />
            <Typography>Item History</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setAdjustStock(true);
            }}
          >
            <AutorenewIcon />
            <Typography>Adjust</Typography>
          </MenuItem>
        </Menu>
      )}
      {/* {adjustStock && <AdjustStock open={adjustStock} close={() => setAdjustStock(false)} item={product} />} */}
      {itemHistory && (
        <ItemHistory
          open={itemHistory}
          close={() => setItemHistory(false)}
          item={{ id: stock._id, name: stock.name }}
        />
      )}
    </>
  );
};

export default StockTable;
