import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import styles from '../pages/shop/index.module.scss';
import NoData from './NoData';

export default function TableComponent(props: any) {
  const { rows, columns, count, page, rowsPerPage, handlePageChange, handleRowsPerPageChange, rowClickedHandler } =
    props;

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {rows?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table
              className={styles.table}
              classes={{ root: styles.customTable }}
              size="medium"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align={'left'} style={{ padding: '0px 1rem !important' }}></TableCell>
                  {columns.map((column: any, index: number) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ fontWeight: 'bold', fontFamily:'Poppins', padding: '0px 1rem !important' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: number) => (
                  <TableRow
                    hover
                    style={{ cursor: 'pointer', height: '44px', background: `${index % 2 == 0 ? '#f5faff' : 'white'}` }}
                    key={index}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align={'left'}
                      style={{ padding: '0px 1rem !important', lineHeight: '0' }}
                    >
                      {index + 1}
                    </TableCell>
                    {columns.map((column: any, index: number) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={index}
                          style={{ padding: '0px 1rem !important', fontFamily:'Poppins', lineHeight: '0', fontSize: '0.75rem !important' }}
                          align={column.align}
                          onClick={() =>
                            column.id === 'action' ? null : rowClickedHandler(row.id, row.projectId, row)
                          }
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      {rows.length === 0 && <NoData />}
    </>
  );
}
