import { useEffect, useState } from 'react';
import { productActions, ProductsState } from '../../../../store/products';
import { searchProducts } from '../../../../store/products/productActions';
import Storage from '../../../../utils/Storage';
import { ShopState } from '../../../../store/shop';
import { appActions, AppState } from '../../../../store/app';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Layout from '../../../../components/Layout';
import ShopNavMenu from '../../../../components/NavMenu/ShopNavMenu';
import { Typography } from 'antd';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import LoadingContainer from '../../../../components/LoadingContainer';
import { Product } from '../../../../interfaces/shop/product';
import { renderMoney } from '../../../../utils/functions';
import { QuickSalesItem } from '../../../../interfaces/shop/quickSales';
import { QuickSalesState } from '../../../../store/quicksales';
import { getProductSales } from '../../../../store/quicksales/quickSalesActions';

const ProductDetails = () => {
  const quickSalesState: QuickSalesState = useSelector((state: any) => state.quicksales);
  const productsState: ProductsState = useSelector((state: any) => state.products);
  const appState: AppState = useSelector((state: any) => state.app);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;
  const [searchText, setSearchText] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, tab: number) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getProductSales(productsState?.product._id, Storage.getToken()));
  }, [productsState?.product._id]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchProducts(`?text=${searchText}&project=${project}`, token));
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token, productsState.fetchedProducts]);

  return (
    <>
      <div className="flex items-center justify-between my-5">
        <div className="flex items-center">
          <Link to="/shop/quicksales/products">
            <ArrowCircleLeftOutlinedIcon className="cursor-pointer" />
          </Link>
          <Typography className="font-bold text-xl ms-2">{`Products / ${productsState.product.name}`}</Typography>
        </div>
        <Button
          className="bg-blue-500"
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          onClick={() => {
            dispatch(appActions.setOpenForm({ open: true, action: 'add' }));
          }}
        >
          Add Item
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="bg-white overflow-y-scroll rounded-lg p-4 mt-2 overflow-hidden h-[80vh]">
            <div className="flex border rounded-lg mx-1 p-1">
              <SearchIcon className="me-3" />
              <input
                placeholder="Search Item"
                className="w-full outline-none"
                type="text"
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSearchText(event.target.value);
                }}
              />
              {searchText !== '' && (
                <CloseIcon className="ms-2 cursor-pointer text-gray-500" onClick={() => setSearchText('')} />
              )}
            </div>
            {productsState.product?.length > 0 &&
              productsState.product?.map((product: Product) => (
                <div
                  key={product?._id}
                  className="cursor-pointer h-20 flex items-center px-4 py-2 border-b border-gray-200"
                  onClick={() => {
                    dispatch(productActions.setProduct(product));
                  }}
                >
                  {product?.image?.url && (
                    <img className="h-[70%] w-[40%] border me-2" src={product?.image?.url} alt="pff" />
                  )}
                  <div>{product?.name.toUpperCase()}</div>
                </div>
                // <ProductCard key={product._id} product={product} />
              ))}
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="mt-1 bg-white overflow-y-scroll h-[81vh]">
            <Typography className="font-bold text-2xl text-blue-500 mt-8 mb-3 ms-10">
              {productsState.product?.name.toUpperCase()}
            </Typography>
            <hr className="mb-4" />
            <div className="flex justify-between items-center mx-10">
              <div className="my-4 flex items-end">
                <Typography className="text-secondaryTextColor font-semibold">Buying Price:</Typography>
                <Typography className=" font-bold ms-4 text-xl">
                  {renderMoney(productsState.product.unitPrice)}
                </Typography>
              </div>
              <div className="my-4 flex items-end">
                <Typography className="text-secondaryTextColor font-semibold">Est Profit:</Typography>
                <Typography className=" font-bold ms-4 text-xl">{renderMoney(productsState.product.profit)}</Typography>
              </div>
            </div>
            <div className="p-8">
              {!appState?.drawerFetching && (
                <>
                  <div className="flex w-[70%] mx-auto py-2 font-semibold">
                    <div className="basis-1/6 text-center">Nbr</div>
                    <div className="basis-4/6 text-center">Date</div>
                    <div className="basis-3/6 text-center">Quantity</div>
                  </div>
                  <div className="h-[40vh] w-[70%] mx-auto overflow-y-scroll border">
                    {quickSalesState?.productSales?.length > 0 &&
                      quickSalesState?.productSales?.map((sale: QuickSalesItem, index: number) => (
                        <div
                          key={index}
                          style={{
                            background: `${index % 2 === 0 ? '#f5faff' : 'white'}`,
                          }}
                          className="flex py-2 border-b-2 border-gray-100"
                        >
                          <div className="basis-1/6 text-center">{index + 1}</div>
                          <div className="basis-4/6 text-center">{sale.date?.substring(0, 10)}</div>
                          <div className="basis-3/6 text-center">{sale.quantity}</div>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {appState?.drawerFetching && (
                <>
                  <LoadingContainer />
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductDetails;
