import { ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { darkBlue, textColor } from '../../assets/colors/colors';
import styles from './index.module.scss';


const NavItem = (props: any) => {
    return (
        <ListItem selected={props.selected} classes={{ root: styles.root, selected: styles.listItemSelected }}>
            <ListItemIcon style={{ color: props.selected ? darkBlue : textColor, minWidth: '40px' }}>
              {props.nav.icon}
            </ListItemIcon>
           
              <div className={styles.listItemText}>{props.nav.label}</div>
           
          </ListItem>
    );
}

export default NavItem;