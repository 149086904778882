import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { addAction } from '../../../store/shop/actions/globalActions';
import Storage from '../../../utils/Storage';
import { appActions } from '../../../store/app';
import { addUserService } from '../../../store/app/user/userService';
import { useTranslation } from '../../../i18n';
import GenericForm from '../../../components/GenericForm';

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  onSubmit: Function;
  styles?: string;
  confirmText?: string;
}

const NewUser = (props: GenericFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = Storage.getToken();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    values.company = Storage.getUser()?.company?._id;
    dispatch(
      addAction(
        addUserService,
        values,
        token,
        () => {},
        () => {
          props.close();
          dispatch(appActions.setFetchedUsers(false));
        }
      )
    );
  };

  const phoneValidator = (_: any, value: string) => {
    if (!value || /^(\+?\d+)?$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid phone number');
  };

  const formElements = [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      rules: [{ required: true, message: 'Please input your username!' }],
    },
    {
      name: 'contact',
      label: 'Phone',
      type: 'text',
      rules: [{ validator: phoneValidator }],
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      rules: [{ type: 'email', message: 'The input is not a valid email!' }],
    },
    { name: 'password', label: 'Password', type: 'password' },
    {
      name: 'role',
      label: 'Role',
      type: 'select',
      options: [
        { _id: 'admin', name: 'Admin' },
        { _id: 'user', name: 'User' },
      ],
    },
  ];

  return (
    <GenericForm
      open={props.open}
      close={() => props.close()}
      title="New User"
      style="vertical"
      formElements={formElements}
      onSubmit={onFinish}
    />
  );
};

export default NewUser;
