import { shopActions } from '..';
import { exportExcel, numberWithCommas, requestFailed, setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { getSuppliersService, searchSuppliersService } from './suppliersService';

export const getShopSuppliers = (query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    const res = await getSuppliersService(query, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(
        appActions.setHttpResponse({
          statusCode: res?.response?.status,
          status: res?.response?.data?.status,
          message: res?.response?.data?.message,
        })
      );
    } else {
      dispatch(shopActions.setSuppliers(res.data));
      dispatch(appActions.setCount({ count: res.count }));
    }
    dispatch(appActions.setIsFetching(false));
    dispatch(shopActions.setFetchedSuppliers(true));
  };
};

// EXPORT EXCEL
export const exportSuppliersAction = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getSuppliersService(query, token);
      if (requestFailed(res)) {
        dispatch(setResponseAction(res, 'fail', res.message));
      } else {
        const debtors = res?.data?.filter((supplier: any) => supplier.debt > 0);
        const suppliers = debtors?.map((supplier: any) => {
          return {
            SupplierName: supplier?.name,
            Debt: numberWithCommas(supplier?.debt),
            Telephone: supplier?.contact ?? 'None',
            TinNumber: supplier?.tinNumber ?? 'None',
          };
        });
        exportExcel(suppliers, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchShopSuppliers = (projectId: string, query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    const res = await searchSuppliersService(projectId, query, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(
        appActions.setHttpResponse({
          statusCode: res?.response?.status,
          status: res?.response?.data?.status,
          message: res?.response?.data?.message,
        })
      );
    } else {
      dispatch(shopActions.setSuppliers(res.data));
      dispatch(appActions.setCount({ count: res.count }));
    }
    dispatch(appActions.setIsFetching(false));
    dispatch(shopActions.setFetchedSuppliers(true));
  };
};
