import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { appActions } from '../../../../store/app';
import Storage from '../../../../utils/Storage';
import { Manufacturer } from '../../../../interfaces/shop/manufacturers';
import GenericForm from '../../../../components/GenericForm';
import { LooseObject } from '../../../../interfaces/construction/constants';
import { addAction, updateAction } from '../../../../store/shop/actions/globalActions';
import { addItemService, updateItemService } from '../../../../store/items/itemsService';
// import { ObjectId } from 'bson';
import { Form } from 'antd';
import { addProductService, updateProductService } from '../../../../store/products/productService';
import { productActions } from '../../../../store/products';

interface SelectElement {
  name: string;
}

interface NewProductProps {
  product?: any;
}

export default function NewProduct(props: NewProductProps) {
  const [manufacturer, setManufacturer] = useState({} as Manufacturer);
  const manufacturers = useSelector((state: any) => state.shop.manufacturers);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const appState = useSelector((state: any) => state.app);

  const token = Storage.getToken();

  useEffect(() => {
    if (appState?.openForm?.action === 'update') {
      console.log('props product ', props?.product);
      form.setFieldsValue({
        name: props?.product?.name?.toUpperCase(),
        unitPrice: props?.product?.unitPrice,
        profit: props?.product?.profit,
      });
    }
  }, []);

  const newItemFormElements = [
    {
      label: 'Item Name',
      name: 'name',
      type: 'text',
      placeholder: 'Enter Item Name',
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Unit Price',
      name: 'unitPrice',
      type: 'number',
      placeholder: 'Price',
    },
    {
      label: 'Estimated profit',
      name: 'profit',
      type: 'number',
      placeholder: 'Price yo gucuruza',
    },
  ];

  const submit = async (values: any) => {
    let data: LooseObject = {};
    data.name = values.name.toLowerCase();
    data.unitPrice = +values.unitPrice;
    data.profit = +values.profit;
    data.project = Storage.getProject()._id;

    console.log('dataaaaaaaaaa ', data);

    if (appState.openForm.action === 'add') {
        dispatch(addAction(addProductService, data, token, () => dispatch(productActions.setFetchedProducts(false))));
    } else {
      if (props?.product?._id)
        dispatch(
          updateAction(updateProductService, props?.product._id, data, token, () =>
            dispatch(productActions.setFetchedProducts(false))
          )
        );
    }
  };

  return (
    <GenericForm
      title={appState.openForm.action === 'add' ? 'New Product' : 'Update Product'}
      onSubmit={submit}
      form={form}
      formElements={newItemFormElements}
      open={appState.openForm.open}
      style="vertical"
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: '' }));
      }}
    />
  );
}
