import React, { useState } from 'react';
import styles from '../index.module.scss';
import store from 'store';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../../components/LoadingContainer';
import { appActions } from '../../../store/app';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { numberWithCommas } from '../../../utils/functions';
import { SelectedElement } from '../../../interfaces';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { shopActions, ShopState } from '../../../store/shop';
import { deletePurchasesService } from '../../../store/purchases/purchasesService';
import { shopPaymentData, shopPaymentsInColumns } from '../../../interfaces/shop/payments';
import { useTranslation } from 'react-i18next';
import { itemActions } from '../../../store/items';

export default function PaymentsTable(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [purchase, setPurchase] = useState({} as SelectedElement);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const projectState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = store.get('x-auth-token');
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createData = (
    id: string,
    date: string,
    customer: string,
    debt: string,
    amountPaid: string
  ): shopPaymentData => {
    return {
      id,
      date,
      customer,
      debt: debt || 'N/A',
      amountPaid,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
  };

  const handlePurchaseDelete = () => {
    dispatch(
      deleteAction(deletePurchasesService, purchase.id, token, 'Purchase', () =>
        dispatch(appActions.setUpdateNeeded(true))
      )
    );
    dispatch(itemActions.setFetchedItems(false));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paymentsIn = shopState?.payments.filter((payment: any) => payment.type === 'in');

  const rows = paymentsIn?.map((payment: any) =>
    createData(
      payment._id,
      payment.date?.substring(0, 10),
      payment?.customer?.name,
      numberWithCommas(payment.debt),
      numberWithCommas(payment?.amountPaid)
    )
  );

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     if (searchText) {
  //
  //       dispatch(searchShopPurchases(`?name=${searchText}&project=${id}`, token));
  //     } else {
  //       if (!projectState.isFetching && !projectState.updateNeeded) {
  //         dispatch(appActions.setUpdateNeeded(true));
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [dispatch, id, projectState.isFetching, projectState.updateNeeded, searchText, token]);

  return (
    <>
      {!projectState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={shopPaymentsInColumns}
            rowClickedHandler={() => {}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseAction}>
                <VisibilityIcon />
                <Typography>{t('view')}</Typography>
              </MenuItem>
              <MenuItem onClick={handleEditAction}>
                <AutorenewIcon />
                <Typography>{t('update')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                <Typography>{t('delete')}</Typography>
              </MenuItem>
            </Menu>
          )}
        </>
      )}
      {projectState.isFetching && <LoadingContainer />}
      {/* {appState.openForm.open && (
        <NewShopPurchase close={() => dispatch(appActions.setOpenForm({ open: false, action: '' }))} />
      )} */}
      <ConfirmModal
        onConfirm={() => {
          handlePurchaseDelete();
        }}
        content={`Please confirm to delete Purchase Transaction of ${purchase.name}`}
      />
    </>
  );
}
