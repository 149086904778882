import React, { useState } from 'react';
import styles from '../index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../../components/LoadingContainer';
import { appActions } from '../../../store/app';
import { Customer, shopCustomerColumns, shopCustomersData } from '../../../interfaces/shop/customers';
import { numberWithCommas } from '../../../utils/functions';
import Storage from '../../../utils/Storage';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { deleteCustomerService } from '../../../store/shop/customers/customersServices';
import { TableProps } from '../../../interfaces';
import PayCustomer from './PayCustomer';
import { shopActions, ShopState } from '../../../store/shop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CustomersTable(props: TableProps) {
  const { count, page, setPage, rowsPerPage, setRowsPerPage } = props;
  const [customer, setCustomer] = useState({} as Customer);
  const [viewCustomer, setViewCustomer] = useState(false);
  const shopState : ShopState = useSelector((state: any) => state.shop);
  const [payCustomer, setPayCustomer] = useState(false);
  const appState = useSelector((state: any) => state.app);
  const projectState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteCustomer = () => {
    dispatch(
      deleteAction(deleteCustomerService, customer._id, token, 'Customer', () => shopActions.setFetchedCustomers(false))
    );
  };

  const handleEditAction = () => {
    handleClose();
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(shopActions.setFetchedCustomers(false));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(shopActions.setFetchedCustomers(false));
  };
  const createData = (customer: Customer): shopCustomersData => {
    return {
      id: customer._id,
      name: customer.name,
      contact: customer?.contact || 'N/A',
      tinNumber: customer.tinNumber ? `${customer?.tinNumber}` : 'N/A',
      debt: numberWithCommas(customer?.debt),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setCustomer(customer);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.customers?.map((customer: Customer) => createData(customer));

  return (
    <>
      {!projectState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={shopCustomerColumns}
            rowClickedHandler={() => {}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);

                  dispatch(shopActions.setCustomer(customer));
                  navigate('/shop/sales/customers/customer');
                }}
              >
                <VisibilityIcon />
                <Typography>{t('view')}</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                }}
              >
                <AutorenewIcon />
                <Typography>{t('update')}</Typography>
              </MenuItem>
              <MenuItem>
                <ReceiptLongIcon />
                <Typography>Invoice</Typography>
              </MenuItem>
            </Menu>
          )}
        </>
      )}
      {projectState.isFetching && <LoadingContainer />}
      {payCustomer && <PayCustomer customer={customer} open={payCustomer} setOpen={setPayCustomer} />}
      {/* {viewCustomer && <CustomerDetail customer={customer} open={viewCustomer} setOpen={setViewCustomer} />} */}
      <ConfirmModal
        onConfirm={() => {
          handleDeleteCustomer();
        }}
        content={`Please confirm to delete ${customer.name} Customer`}
      />
    </>
  );
}
