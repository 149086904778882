import { shopActions } from '../shop';
import { NewSalesData, Sale } from '../../interfaces/shop/sales';
import { exportExcel, setResponseAction } from '../../utils/functions';
import { appActions } from '../app';
import { addSalesService, getShopSalesNewService, getShopSalesService, searchSalesService } from './salesServices';
import { salesActions } from '.';
import { Customer } from '../../interfaces/shop/customers';

export const getSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      console.log('getting saless');
      dispatch(appActions.setIsFetching(true));
      const res = await getShopSalesNewService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(salesActions.setSales(res.data));
        dispatch(salesActions.setSalesCount(res?.count ?? 1));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(salesActions.setFetchedSales(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const newSaleAction = (data: NewSalesData, existingSales: Sale[], customer: Customer, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsSubmitting(true));
      const res = await addSalesService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(salesActions.setSales([{ ...res.data, customer }, ...existingSales]));
      }
      dispatch(appActions.setIsSubmitting(false));
      dispatch(appActions.setOpenForm({ open: false, action: '' }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomerSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getShopSalesNewService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let saleItems: any = [];
        res.data.forEach((sale: Sale) => saleItems.push(...sale.list));
        dispatch(salesActions.setCustomerSales(saleItems));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(salesActions.setFetchedSales(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchShopSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchSalesService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(salesActions.setSales(res.data));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(salesActions.setFetchedSales(true));
    } catch (err: any) {
      console.log(err);
    }
  };
};

export const exportSalesReport = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getShopSalesNewService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let salesItems: any = [];
        res.data.forEach((sale: Sale) => {
          var list: any = [];
          sale.list.map((item: any) => {
            list.push({
              customer: sale?.customer ?? 'Customer',
              paymentMethod: sale?.paymentMethod ?? 'N/A',
              ...item,
            });
          });
          salesItems.push(...list);
        });

        let exportData = salesItems.map((inData: any, index: number) => {
          return {
            Number: index + 1,
            Item: inData?.item?.name ?? 'Item',
            Quantity: inData?.quantity,
            UnitPrice: inData?.unitPrice,
            TotalPrice: inData?.quantity * inData?.unitPrice,
            Customer: inData?.customer?.name ?? 'Customer',
            Payment: inData?.isCredit ? 'Credit' : 'Paid',
            Profit: (inData?.unitPrice - inData?.buyingPrice) * inData?.quantity,
          };
        });
        exportExcel(exportData, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeDeletedSale = (allSales: Sale[], sellId: string) => {
  return async (dispatch: any) => {
    let newItems = allSales;
    newItems = newItems.filter((sell: Sale) => sell._id !== sellId);
    dispatch(salesActions.setSales(newItems));
  };
};
