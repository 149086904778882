import { shopActions } from '..';
import { setResponseAction } from '../../../utils/functions';
import { appActions } from '../../app';
import { getManufacturersService } from './manufacturersService';

export const getShopManufacturers = (query: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsFetching(true));
    try {
      const res = await getManufacturersService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        setResponseAction(res);
      } else {
        dispatch(shopActions.setManufacturers(res.data));
        dispatch(shopActions.setFetchedManufacturers(true));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
