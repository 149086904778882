import { useDispatch, useSelector } from 'react-redux';
import GenericForm from '../../../components/GenericForm';
import { Customer } from '../../../interfaces/shop/customers';
import { shopActions, ShopState } from '../../../store/shop';
import { addAction } from '../../../store/shop/actions/globalActions';
import { payCustomerService } from '../../../store/shop/customers/customersServices';
import { getNumberWithCommas } from '../../../utils/functions';
import Storage from '../../../utils/Storage';
import { Form } from 'antd';
import { useEffect } from 'react';
import dayjs from 'dayjs';

interface payCustomerProps {
  open: boolean;
  customer: Customer;
  setOpen: Function;
}

export default function PayCustomer(props: payCustomerProps) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const shopState: ShopState = useSelector((state: any) => state.shop);

  const onAmountPaidChanged = (value: any) => {
    const remaining = shopState?.customer?.debt - +form.getFieldValue('amount');
    form.setFieldValue('remaining', remaining);
  };

  const validateAmountPaid = (_: any, value: string) => {
    if (parseFloat(value) > props.customer.debt) {
      return Promise.reject('Amound paid can not exceed Customer Debt');
    } else if (parseFloat(value) < 0) {
      return Promise.reject('Amound paid can not be less than 0');
    }
    return Promise.resolve();
  };

  useEffect(() => {
    form.setFieldsValue({
      customer: shopState?.customer?.name,
      date: dayjs(),
      debt: shopState?.customer?.debt,
    });
  }, []);

  const newCustomerElements = [
    {
      label: 'Customer',
      name: 'customer',
      type: 'text',
      disabled: true,
    },
    {
      label: 'Debt',
      name: 'debt',
      type: 'text',
      defaultValue: props.customer.debt,
      disabled: true,
    },
    {
      label: 'Amount Paid',
      name: 'amount',
      type: 'number',
      onChange: onAmountPaidChanged,
      placeholder: 'Enter amount the customer is paying',
      rules: [{ required: true, message: 'Amount is Required field' }, { validator: validateAmountPaid }],
    },
    {
      label: 'Remaining',
      name: 'remaining',
      type: 'text',
      placeholder: 'Reste / Asigaye',
      rules: [{ required: true, message: 'Amount is Required field' }],
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      rules: [{ required: true, message: 'Field Required' }],
    },
    {
      label: 'Description',
      name: 'description',
      type: 'text',
      placeholder: 'Description of payment, if any',
    },
  ];

  const paymentHandler = async (data: any) => {
    data.customer = shopState?.customer?._id;
    data.debt = shopState?.customer?.debt;
    delete data.remaining;
    data.date = form.getFieldValue('date')?.toDate() ?? new Date();
    data.project = Storage.getProject()._id;
    data.amount = data.amount;
    if (!data.description) delete data.description;

    dispatch(
      addAction(payCustomerService, data, token, () => {
        dispatch(shopActions.setFetchedCustomers(false));
        props.setOpen(false);
      })
    );
  };

  return (
    <GenericForm
      title="Pay Customer"
      onSubmit={paymentHandler}
      formElements={newCustomerElements}
      style="horizontal"
      form={form}
      open={props.open}
      close={() => {
        props.setOpen(false);
      }}
    />
  );
}
