import { Box, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import styles from '../index.module.scss';
import StockTable from './StockTable';
import './stock_styles.css';
import Storage from '../../../utils/Storage';
import Layout from '../../../components/Layout';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportShopStockAction,
  getShopCustomStock,
  getShopStock,
  searchShopStock,
} from '../../../store/shop/stock/stockActions';
import TopActions from '../../../components/TopActions';
import { exportExcel, setResponseAction } from '../../../utils/functions';
import { Typography } from 'antd';
import { getExtendedStockService, getShopStockService } from '../../../store/shop/stock/stockService';
import { shopActions, ShopState } from '../../../store/shop';

const Stock = () => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [manufacturer, setManufacturer] = useState('');
  const [query, setQuery] = useState('');
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [dateString, setDateString] = useState(`${new Date().toISOString().substring(0, 10)}`);
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const [isInit, setIsInit] = useState(true);
  const [initialFetch, setInitialFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const project = Storage.getProject();

  useEffect(() => {
    if (manufacturer) {
      console.log('man changeddddddd ', manufacturer);
      setQuery(`&manufacturer=${manufacturer}`);
      setInitialFetch(true);
      dispatch(shopActions.setFetchedStock(false));
    }
  }, [manufacturer]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        console.log('searchhhhhh ');
        dispatch(searchShopStock(`?text=${searchText}&project=${project._id}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && shopState.fetchedStock && !isInit) {
          dispatch(shopActions.setFetchedStock(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    // console.log(
    //   'conditions ',
    //   !shopState.fetchedStock,
    //   '- ',
    //   !searchText,
    //   ' - ',
    //   dateString === new Date().toISOString().substring(0, 10)
    // );
    if (!shopState.fetchedStock && initialFetch && dateString === new Date().toISOString().substring(0, 10)) {
      console.log('get shopppppppp 1');
      dispatch(getShopStock(`?project=${project._id}&sort=name&page=${page}&limit=${rowsPerPage}${query}`, token));
      setInitialFetch(false);
    }
  }, [page, rowsPerPage, token, shopState.fetchedStock, dispatch, project]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (dateString !== new Date().toISOString().substring(0, 10)) {
        console.log('use effect 5 custom stockkkkk');
        dispatch(
          getShopCustomStock(
            `?upToDate=${dateString}&project=${project._id}&sort=name&page=${page}&limit=${rowsPerPage}${query}`,
            token
          )
        );
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [dateString]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setInitialFetch(true);
    dispatch(shopActions.setFetchedStock(false));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setInitialFetch(true);
    dispatch(shopActions.setFetchedStock(false));
  };

  const exportStock = async () => {
    dispatch(
      exportShopStockAction(
        `?project=${project._id}&limit=20000&sort=name`,
        token,
        shopState.manufacturers,
        `${Storage.getProject().name} STOCK`
      )
    );
  };

  const exportExtendedStock = async () => {
    setLoading(true);
    const res = await getExtendedStockService(`?project=${project._id}&limit=20000`, token);
    setLoading(false);
    if (res.status === 200) {
      const data = res.data.map((currentData: any) => {
        return {
          Name: currentData.name,
          Purchases: currentData.purchases,
          Sales: currentData.sales,
          CurrentStock: currentData.closingStock,
        };
      });
      exportExcel(data, `${project.name} ALL Stock`);
    } else {
      dispatch(setResponseAction(res, 'fail', res.message));
    }
  };

  return (
    <Layout title={`${Storage.getProject().name} • STOCK`} navMenu={<ShopNavMenu select={4} />}>
      <TopActions addNew={false} setSearchText={setSearchText} export={exportStock} />
      <div className="flex justify-between items-center">
        <Box className={styles.itemsCategoryBox}>
          <Box className={styles.filterElements}>
            <Typography className="font-bold">Category: </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={`${styles.input} ${styles.inputSelect}`}
              value={manufacturer}
              onChange={(event: any) => {
                setManufacturer(event.target.value);
              }}
            >
              {shopState?.manufacturers?.map((option: any) => {
                return (
                  <MenuItem key={option?._id} value={option?._id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box className={styles.filterElements}>
            <Typography className="font-bold">Date: </Typography>
            <TextField
              type="date"
              defaultValue={new Date().toISOString().substring(0, 10)}
              onChange={(e) => {
                setDateString(e.target.value);
              }}
              className="dateInput"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
        <div
          className="bg-primaryColor text-white rounded-md py-2 cursor-pointer px-4 uppercase"
          onClick={() => exportExtendedStock()}
        >
          {loading ? (
            <CircularProgress style={{ color: 'white', height: '1.5rem', width: '1.5rem' }} />
          ) : (
            'Stock Reconciliation'
          )}
        </div>
      </div>
      <StockTable
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </Layout>
  );
};

export default Stock;
