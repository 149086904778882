import Drawer from '@mui/material/Drawer';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { SelectedElement } from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getItemHistory } from '../../../store/items/itemsActions';
import Storage from '../../../utils/Storage';
import LoadingContainer from '../../../components/LoadingContainer';
import TableComponent from '../../../components/TableComponent';
import { ItemHistoryColumns } from '../../../interfaces/shop/shopItems';
import { createItemHistoryData } from '../items/ItemView';
import { ShopState } from '../../../store/shop';
import { ItemsState } from '../../../store/items';

interface itemViewProps {
  item: SelectedElement;
  open: boolean;
  close: Function;
}

export default function ItemHistory(props: itemViewProps) {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const appState = useSelector((state: any) => state.app);
  const itemsState: ItemsState = useSelector((state: any) => state.items);

  useEffect(() => {
    dispatch(getItemHistory(props.item.id, token));
  }, []);

  const rows = itemsState?.item?.history?.map((history: any) =>
    createItemHistoryData(
      history.date.substring(0, 10),
      history.action,
      `${(history.quantity / history.item.details.quantity).toFixed(2)}`,
      `${history.stockBalance / history.item.details.quantity}`
    )
  );
  return (
    <Drawer
      PaperProps={{
        sx: { width: '60vw', padding: '2rem 1rem', background: '#f4f5f7' },
      }}
      anchor={'right'}
      open={props.open}
      onClose={() => props.close()}
    >
      {appState.drawerFetching && <LoadingContainer />}
      {!appState.drawerFetching && (
        <>
          <Box className={`${styles.aRight}`}>
            <CloseIcon onClick={() => props.close()} className={`${styles.cPointer}`} />
          </Box>
          <Typography className={`${styles.header}`}>{itemsState?.item?.name} Stock History</Typography>
          <Box className={styles.item_history}>
            <TableComponent
              rows={rows}
              columns={ItemHistoryColumns}
              rowClickedHandler={() => {}}
              count={itemsState?.item?.history?.length}
              page={0}
              rowsPerPage={100}
              handlePageChange={() => {}}
              handleRowsPerPageChange={() => {}}
            />
          </Box>
        </>
      )}
    </Drawer>
  );
}

interface detailItemProps {
  label: string;
  value: any;
}

export const DetailItem = (props: detailItemProps) => {
  return (
    <>
      <Box className={styles.item_detail}>
        <Typography>{props.label}</Typography>
        <Typography>{props.value}</Typography>
      </Box>
    </>
  );
};
