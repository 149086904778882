import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Storage from '../../../utils/Storage';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { PurchaseItem } from '../../../interfaces/shop/purchaseItem';
import { calculateTotalPaid, calculateTotalPrice, renderMoney } from '../../../utils/functions';
import { appActions } from '../../../store/app';
import ConfirmModal from '../../../components/ConfirmModal';
import { deletePurchasesService } from '../../../store/purchases/purchasesService';
import { shopActions } from '../../../store/shop';
import { Purchase } from '../../../interfaces/shop/purchase';
import { purchasesActions } from '../../../store/purchases';

export interface PurchaseDetailsProps {
  purchase: Purchase | null;
  close: Function;
}

interface PurchaseItemRowProps {
  index: number;
  name: string;
  unitPrice: string;
  quantity: number;
  totalPrice: string;
}

const PurchaseItemRow = (props: PurchaseItemRowProps) => {
  return (
    <div style={{ background: `${props.index % 2 == 0 ? '#f5faff' : 'white'}` }} className="flex py-2 ps-2">
      <div className="basis-1/12">{props.index}</div>
      <div className="basis-4/12 text-center whitespace-nowrap truncate">{props.name}</div>
      <div className="basis-2/12 text-center">{props.unitPrice}</div>
      <div className="basis-2/12 text-center">{props.quantity}</div>
      <div className="basis-3/12 text-center">{props.totalPrice}</div>
    </div>
  );
};

const PurchaseDetails = (props: PurchaseDetailsProps) => {
  const appState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const dispatch = useDispatch();
  const token = Storage.getToken();

  const deletePurchaseHandler = async () => {
    if (props.purchase?._id) {
      // if (props.purchase?.isCredit) {
      //   await increaseSupplierDebtService(
      //     props.purchase?.supplier._id,
      //     {
      //       amount:
      //         props.purchase?.amountPaid[0].value -
      //         calculateTotalPriceNumber(props.purchase?.list),
      //     },
      //     token
      //   );
      // }
      dispatch(
        deleteAction(deletePurchasesService, props.purchase?._id, token, 'Purchase', () => {
          props.close();
          dispatch(purchasesActions.setFetchedPurchases(false));
        })
      );
    }
  };

  return (
    <>
      {props.purchase && (
        <div className="h-[81vh] overflow-y-scroll bg-white">
          {props.purchase?.list && (
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="bg-gray-100 rounded-lg p-2 cursor-pointer" onClick={() => props.close()}>
                  <ArrowBackIosNewIcon />
                </div>
                <div className="bg-gray-100 rounded-lg p-2 cursor-pointer">
                  <MoreVertIcon
                    onClick={(event: any) => {
                      setAnchorEl(event.target);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="font-semibold text-xl mb-2">{Storage.getUser()?.company?.name}</div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Address:</div>
                    <div>{'Address'}</div>
                  </div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Contact :</div>
                    <div>{'Phone'}</div>
                  </div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Tin Number :</div>
                    <div>{Storage.getUser()?.company?.tinNumber}</div>
                  </div>
                </div>
                <div>
                  <div className="font-semibold text-xl mb-2">Purchase</div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Time:</div>
                    <div>{new Date(props.purchase.date).toLocaleTimeString()}</div>
                  </div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Date:</div>
                    <div>{new Date(props.purchase.date).toLocaleDateString()}</div>
                  </div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">P.O Number:</div>
                    <div>{props.purchase.purchaseReceipt}</div>
                  </div>
                  <div className="text-sm flex">
                    <div className="font-semibold me-2">Supplier Name:</div>
                    <div>{props.purchase?.supplier?.name ?? 'Supplier'}</div>
                  </div>
                </div>
              </div>
              <div className="flex mt-8 bg-slate-100 py-2 ps-2">
                <div className="basis-1/12">SL</div>
                <div className="basis-4/12 text-center">Item Name</div>
                <div className="basis-2/12 text-center">Unit Price</div>
                <div className="basis-2/12 text-center">Quantity</div>
                <div className="basis-3/12 text-center">Total Price</div>
              </div>
              {props.purchase?.list?.map((purchaseItem: PurchaseItem, index: number) => (
                <PurchaseItemRow
                  index={index + 1}
                  name={purchaseItem?.item?.name}
                  unitPrice={renderMoney(purchaseItem.unitPrice)}
                  quantity={purchaseItem.quantity}
                  totalPrice={renderMoney(purchaseItem.quantity * purchaseItem.unitPrice)}
                />
              ))}

              <div className="flex justify-between">
                <div className="">
                  <div className="font-semibold mt-10 mb-4">Payment Info:</div>
                  <div className="font-semibold">Amount Paid: {calculateTotalPaid(props.purchase.list)}</div>
                  <div className="font-semibold">Payment Method:</div>
                  <div className="font-semibold">A.C Name:</div>
                </div>
                <div className="mt-10 w-4/12">
                  <div className="flex justify-between">
                    <div className="font-semibold">SubTotal:</div>
                    <div>{calculateTotalPrice(props.purchase.list)}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold">Tax</div>
                    <div>0</div>
                  </div>
                  <div className="flex font-bold text-xl justify-between text-primaryColor mt-4">
                    <div>Total:</div>
                    <div>{calculateTotalPrice(props.purchase.list)}</div>
                  </div>
                </div>
              </div>
              <div className="my-8 font-semibold">Approved by:</div>
              <div className="flex justify-between border-t-2 border-gray-200 pt-2">
                <div>Authorized Signature</div>
                <div className="me-12">Date</div>
              </div>
            </div>
          )}
        </div>
      )}
      {Boolean(anchorEl) && (
        <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          <MenuItem
            onClick={() => {
              dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
              setAnchorEl(null);
            }}
          >
            <AutorenewIcon />
            Update
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(appActions.setIsDeleting(true));
              setAnchorEl(null);
            }}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        </Menu>
      )}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={() => deletePurchaseHandler()}
          content={`Are you sure you want to delete Purchase of ${
            props.purchase?.list?.length
          } on ${props.purchase?.date?.substring(0, 10)} by ${props.purchase?.supplier?.name}`}
        />
      )}
    </>
  );
};

export default PurchaseDetails;
