import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../../components/LoadingContainer';
import { appActions } from '../../../store/app';
import { searchShopSales } from '../../../store/sales/salesActions';
import Storage from '../../../utils/Storage';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { getShopManufacturers } from '../../../store/shop/manufacturers/manufacturersActions';
import { Manufacturer, manufacturerColumns } from '../../../interfaces/shop/manufacturers';
import { deleteManufacturersService } from '../../../store/shop/manufacturers/manufacturersService';
import { useTranslation } from 'react-i18next';
import { ShopState } from '../../../store/shop';

export default function ManufacturersTable(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [supplierView, setSupplierView] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [manufacturer, setManufacturer] = useState({} as any);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const appState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
    setSupplierView(true);
  };

  const deleteManufacturerHandler = () => {
    dispatch(
      deleteAction(deleteManufacturersService, manufacturer._id, token, 'Manufacturer', () =>
        dispatch(appActions.setUpdateNeeded(true))
      )
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (manufacturer: any): Manufacturer => {
    return {
      _id: manufacturer._id,
      name: manufacturer.name,
      supplier: manufacturer.supplier ? manufacturer.supplier.name : 'N/A',
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setManufacturer(manufacturer);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.manufacturers?.map((manufacturer: any) => createData(manufacturer));

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    dispatch(
      getShopManufacturers(`?project=${project}&page=${page}&limit=${rowsPerPage}&sort=-date&isActive=true`, token)
    );
  }, []);

  useEffect(() => {
    if (appState.updateNeeded) {
      dispatch(
        getShopManufacturers(`?project=${project}&page=${page}&limit=${rowsPerPage}&sort=-date&isActive=true`, token)
      );
    }
  }, [page, rowsPerPage, query, token, appState.updateNeeded, dispatch, project]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopSales(searchText, token));
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token]);

  return (
    <>
      {!appState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={manufacturerColumns}
            rowClickedHandler={() => {}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEditAction}>
                <AutorenewIcon />
                <Typography>{t('update')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                <Typography>{t('delete')}</Typography>
              </MenuItem>
            </Menu>
          )}
        </>
      )}
      {appState.isFetching && <LoadingContainer />}
      <ConfirmModal
        onConfirm={() => {
          deleteManufacturerHandler();
        }}
        content={`Please confirm to delete ${manufacturer.name} Manufacturer`}
      />
    </>
  );
}
