import { shopActions } from '../shop';
import { LooseObject } from '../../interfaces/construction/constants';
import { exportExcel, setResponseAction } from '../../utils/functions';
import isOnline from 'is-online';
import { appActions } from '../app';
import {
  addProductService,
  getProductHistoryService,
  getProductsService,
  searchProductsService,
} from './productService';
import { productActions } from '.';
import { Product } from '../../interfaces/shop/product';
// import { ObjectId } from 'bson';

// CREATE ONE
export const addProduct = (data: LooseObject, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await addProductService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(
          appActions.setHttpResponse({
            statusCode: res?.response?.status,
            status: res?.response?.data?.status,
            message: res?.response?.data?.message,
          })
        );
      } else {
        dispatch(appActions.setIsFetching(false));
        dispatch(productActions.setFetchedProducts(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(appActions.setIsFetching(false));
    }
  };
};

export const newProduct = (data: any, existingProducts: Product[], token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsSubmitting(true));
      var hasInternet: boolean = await isOnline();
      if (!hasInternet) {
        dispatch(appActions.setIsSubmitting(false));
        // console.log('Creating product locallyyy ', { _id: new ObjectId(), ...data });
        return;
      }
      const res = await addProductService(data, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(productActions.setProducts([{ ...res.data }, ...existingProducts]));
      }
      dispatch(appActions.setIsSubmitting(false));
      dispatch(appActions.setOpenForm({ open: false, action: '' }));
    } catch (err) {
      console.log(err);
    }
  };
};

// GET ALL
export const getProducts = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getProductsService(query, token);
      console.log('res for products ', res);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(productActions.setProducts(res.data));
        dispatch(productActions.setProductsCount(res.count));
        dispatch(productActions.setFetchedProducts(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//SEARCH
export const searchProducts = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchProductsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        dispatch(productActions.setProducts(res.data));
        dispatch(productActions.setProductsCount({ count: res.count }));
        dispatch(productActions.setFetchedProducts(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// EXPORT EXCEL
export const exportShopProducts = (query: string, token: string, excelName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getProductsService(query, token);
      if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
        dispatch(setResponseAction(res));
      } else {
        let exportData = res.data.map((inData: any, index: number) => {
          return {
            Number: index,
            Name: inData?.name,
            BuyingPrice: inData?.buyingPrice,
            SellingPrice: inData?.sellingPrice,
            Brand: inData?.manufacturer?.name,
          };
        });
        exportExcel(exportData, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductHistory = (productId: string, token: string) => {
  return async (dispatch: any) => {
    dispatch(appActions.setDrawerIsFetching(true));
    const res = await getProductHistoryService(productId, token);
    console.log('errr', res);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(productActions.setProduct(res.data));
    }
    dispatch(appActions.setDrawerIsFetching(false));
  };
};
