import { NewQuickSalesData } from '../../interfaces/shop/quickSales';
import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getQuickSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/quicksales${query}`, token);
};

export const addQuickSalesService = async (data: NewQuickSalesData, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/quicksales`, data, token);
};

export const addToQuickSalesListService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/quicksales/add/${saleId}`, data, token);
};

export const updateQuickSalesService = async (quickSaleId: string, data: string, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/quicksales/${quickSaleId}`, data, token);
};

export const updateQuickSalesDateService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/shop/quicksales/date/${saleId}`, data, token);
};

export const deleteQuickSellService = async (quickSellId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/quicksales/${quickSellId}`, token);
};

export const deleteSaleItemService = async (saleId: string, data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop/sales/remove/${saleId}`, data, token);
};

export const searchSalesService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/sales/search_item${query}`, token);
};
