import { useState } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';

export interface UploadImageProps {
  open: boolean;
  close: Function;
  title: string;
  uploading: boolean;
  uploadHandler: Function;
}

const UploadImage = (props: UploadImageProps) => {
  const [fileInputState, setFileInputState] = useState('');
  const [previewSource, setPreviewSource] = useState<any>();
  const [selectedFile, setSelectedFile] = useState('');

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleSubmitFile = () => {
    console.log('submitt');
    if (!selectedFile) return;
    let formdata = new FormData();
    formdata.append('file', selectedFile);
    props.uploadHandler(formdata);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-center">{props.title}</DialogTitle>
        <DialogContent className="w-[30vw] h-[45vh]">
          <Box className="">
            {previewSource && (
              <Box className="">
                <img className="h-[30vh] mx-auto" src={previewSource} alt="chosen" />
              </Box>
            )}
            {!previewSource && (
              <div className="bg-gray-100 h-[30vh] mb-3 flex items-center justify-center">
                <label htmlFor="contained-button-file">
                  <Button startIcon={<AttachFileIcon />} className="" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </div>
            )}
            {/* <input
                id="fileInput"
                type="file"
                name="image"
                onChange={handleFileInputChange}
                value={fileInputState}
                className={classes.image}
              /> */}
            <input
              accept="image/*"
              className=""
              onChange={handleFileInputChange}
              id="contained-button-file"
              value={fileInputState}
              multiple
              hidden
              type="file"
            />
            {previewSource && (
              <Box className="flex justify-center">
                <div
                  className="mx-2 cursor-pointer bg-gray-300 rounded-md border border-gray-400 mt-6 px-5 py-2"
                  onClick={() => setPreviewSource(null)}
                >
                  <CloseIcon />
                </div>
                {!props.uploading && (
                  <div
                    id="submitButton"
                    className="mx-2 cursor-pointer bg-gray-300 rounded-md border border-gray-400 mt-6 px-5 py-2"
                    onClick={() => handleSubmitFile()}
                  >
                    <CheckIcon />
                  </div>
                )}
                {props.uploading && (
                  <Button className="" disabled={true} type="submit">
                    <CircularProgress className="" />
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        {/* <DialogActions className={classes.actions}>
          <Button onClick={props.handleClose}>Discard</Button>
          <Button
            className={classes.addImageButton}
            // onClick={props.handleClose}
            autoFocus
            type="submit"
          >
            Save
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default UploadImage;
