export interface expenseColumn {
  id: 'description' | 'amount' | 'date' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const expenseColumns: expenseColumn[] = [
  { id: 'description', label: 'Description', align: 'center' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];
export interface expenseData {
  id: string;
  description: string;
  amount: string;
  date: string;
  action: any;
}

export interface Expense {
  _id: string;
  description: string;
  amount: number;
  paid: number;
  date: string;
  project: string;
  createdBy?: string;
  updatedAt?: string;
}
