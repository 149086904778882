import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, Box, InputBase, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import store from 'store';
import { authActions } from '../store/auth';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import styles from '../pages/shop/index.module.scss';
import i18n from '../i18n';
import { LoadingButton } from '@mui/lab';
import { AppState } from '../store/app';

const TopActions = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appState: AppState = useSelector((state: any) => state.app);
  const authToken = store.get('x-auth-token');
  const authUser = store.get('user');
  let searchPlaceHolder: string = i18n.t('search');

  const authState = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!authToken || !authUser) {
      navigate('/login');
    } else if (!authState.isAuth) {
      dispatch(authActions.login({ token: authToken, data: { user: authUser }, status: 200 }));
    }
  }, [dispatch, authState, authToken, authUser, navigate]);

  return (
    <>
      {(!authToken || !authUser || !authState.isAuth) && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box className={styles.topActions}>
        <Box className={styles.topActionsLeft}>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={searchPlaceHolder}
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                props.setSearchText(event.target.value);
              }}
            />
          </div>
          {/* {props?.filter && (
            <Button variant="contained" className={styles.normalButton} onClick={() => props.setFilter(true)}>
              Filter
            </Button>
          )} */}
        </Box>
        <Box className={styles.topActionsRight}>
          <LoadingButton
            variant="contained"
            startIcon={<DescriptionIcon />}
            loading={appState.isExportingExcel}
            className={styles.normalButton}
            style={{fontFamily:'Poppins'}}
            onClick={() => props?.export()}
          >
            Export Excel
          </LoadingButton>
          {props?.addNew && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={styles.newButton}
              style={{fontFamily:'Poppins'}}
              onClick={() => props.setNew(true)}
            >
              Add New {props?.entity}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TopActions;
