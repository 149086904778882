import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styles from '../../../components/index.module.scss';
import { Customer } from '../../../interfaces/shop/customers';

import moment from 'moment';
import FormInputs from '../../../components/inputs/FormInputs';
import { ShopState } from '../../../store/shop';

export default function FilterExpenses(props: any) {
  const [fetching, setFetching] = useState(false);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const [formState, setFormState] = useState({
    payment: {} as any,
    customer: {} as Customer | any,
    customerText: '' as string | any,
    dateFrom: null as any,
    dateTo: null as any,
  });

  const payments = [
    {
      name: 'paid',
    },
    {
      name: 'credit',
    },
  ];

  const filterSaleElements = [
    {
      label: 'Date From',
      identifier: 'dateFrom',
      inputComponent: 'date',
      value: formState.dateFrom,
      placeholder: 'Enter  Of Purchase',
      onChange: (dateFrom: string) => setFormState({ ...formState, dateFrom }),
      required: true,
    },
    {
      label: 'Date To',
      identifier: 'dateTo',
      inputComponent: 'date',
      value: formState.dateTo,
      placeholder: 'Enter Date Of Purchase',
      onChange: (dateTo: string) => setFormState({ ...formState, dateTo }),
      required: true,
    },
  ];

  const filter = () => {
    let chipData = [];
    let key = 0;
    if (formState.customer.name) {
      chipData.push({
        key,
        filter: formState.customer._id,
        id: 'customer',
        label: 'Customer',
        value: formState.customer.name,
      });
      key++;
    }
    if (formState?.payment?.name) {
      chipData.push({
        key,
        filter: formState.payment.name === 'credit' ? true : false,
        id: 'isCredit',
        label: 'Payment',
        value: formState.payment.name,
      });
      key++;
    }
    if (formState.dateFrom) {
      chipData.push({
        key,
        filter: moment(formState.dateFrom).startOf('day').toDate(),
        id: 'date[gte]',
        label: 'Date From',
        value: formState.dateFrom,
      });
      key++;
    }
    if (formState.dateTo) {
      chipData.push({
        key,
        filter: moment(formState.dateTo).endOf('day').toDate(),
        id: 'date[lte]',
        label: 'Date To',
        value: formState.dateTo,
      });
      key++;
    }

    if (chipData.length > 0) {
      props.filterHandler(chipData);
      props.setOpen(false);
    } else {
      props.setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={styles.genericForm_header}>
          <Box className={styles.center}>
            <Typography className={styles.form_title}>FILTER EXPENSES</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          {fetching && (
            <Box className={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!fetching && (
            <Box>
              {filterSaleElements.map((element: any, index: number) => {
                return <FormInputs key={index} element={element} />;
              })}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box className={styles.filterButtonsContainer}>
            <Button onClick={() => filter()} variant="contained" className={styles.filterButton}>
              Filter
            </Button>
            <Button onClick={() => props.setOpen(false)} variant="contained" className={styles.cancelButton}>
              Clear
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
