import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopActions from '../../../components/TopActions';
import ExpensesTable from './ExpensesTable';
import store from 'store';
import { appActions } from '../../../store/app';
import Layout from '../../../components/Layout';
import FilterListIcon from '@mui/icons-material/FilterList';
import Storage from '../../../utils/Storage';
import ShopNavMenu from '../../../components/NavMenu/ShopNavMenu';
import {
  exportShopExpenses,
  getShopExpenses,
  searchShopExpenses,
} from '../../../store/shop/expenses/shopExpenseActions';
import { shopActions, ShopState } from '../../../store/shop';
import { Chip, Stack } from '@mui/material';
import { ChipData } from '../../../interfaces/construction/constants';
import FilterExpenses from './FilterExpenses';

const ShopExpenses = () => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [isInit, setIsInit] = useState(true);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const project = store.get('project');
  const token = Storage.getToken();
  const [query, setQuery] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [chipData, setChipData] = useState<any>([]);
  const appState = useSelector((state: any) => state.app);
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const dispatch = useDispatch();

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  // useEffect(() => {
  //   if (!appState.isFetching) {
  //     setCount(appState.count);
  //   }
  // }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (chipData.length > 0) {
      let queryString = '';
      chipData.forEach((data: any) => {
        queryString += `&${data.id}=${data.filter}`;
      });
      setQuery(queryString);
      dispatch(shopActions.setFetchedShopExpenses(false));
    } else if (chipData.length === 0 && query !== '') {
      setQuery('');
      dispatch(shopActions.setFetchedShopExpenses(false));
    }
  }, [chipData]);

  useEffect(() => {
    if (!shopState.fetchedShopExpenses && project._id)
      dispatch(
        getShopExpenses(
          `?project=${project._id}&page=${page}&limit=${rowsPerPage}&sort=-date${query}`,
          store.get('x-auth-token')
        )
      );
  }, [shopState.fetchedShopExpenses, project._id, dispatch]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchShopExpenses(`?text=${searchText}&project=${project._id}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && shopState.fetchedShopExpenses && !isInit) {
          dispatch(shopActions.setFetchedShopExpenses(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips: any[]) => chips.filter((chip: ChipData) => chip.key !== chipToDelete.key));
  };

  // const exportSales = () => {
  //   if (query.includes('date[gte]') && query.includes('date[lte]')) {
  //     dispatch(
  //       exportSalesReport(
  //         `?project=${project}&limit=20000&sort=-date&action=sales${query}`,
  //         token,
  //         `${Storage.getProject().name} • SALES REPORT`
  //       )
  //     );
  //   }
  // };

  const exportExpensesHandler = () => {
    if (query.includes('date[gte]') && query.includes('date[lte]')) {
      dispatch(
        exportShopExpenses(`?project=${project._id}&sort=-date&limit=20000${query}`, token, `${project.name} Expenses`)
      );
    }
  };

  return (
    <Layout title={`${Storage.getProject().name} • EXPENSES`} navMenu={<ShopNavMenu select={8} />}>
      <div className="flex">
        <div className="mr-4 mt-3 cursor-pointer">
          <FilterListIcon className="mt-4" onClick={() => setShowFilter(true)} />
        </div>
        <div className="w-full items-center">
          <TopActions
            setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
            entity="Expense"
            setSearchText={setSearchText}
            export={exportExpensesHandler}
            addNew={true}
          />
        </div>
      </div>
      {chipData.length > 0 && (
        <Stack direction="row" spacing={1} style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          {chipData.map((data: any) => {
            const value = data?.value ? data.value : data.filter;
            return (
              <Chip
                key={data.key}
                label={`${data.label}: ${value}`}
                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              />
            );
          })}
        </Stack>
      )}
      <ExpensesTable
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      {showFilter && <FilterExpenses filterHandler={filterHandler} open={showFilter} setOpen={setShowFilter} />}
    </Layout>
  );
};

export default ShopExpenses;
