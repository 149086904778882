import moment from 'moment';
import XLSX from 'xlsx';
import i18n from '../i18n';
import { appActions } from '../store/app';

export const numberWithCommas = (x: number) => {
  x = Math.round(x);
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const renderMoney = (x: number) => {
  return numberWithCommas(x) + ' RWF';
};

export const calculateTotalPrice = (list: any, type: string = 'string') => {
  let sum = 0;
  list.forEach((item: any) => {
    sum += item.unitPrice * item.quantity;
  });

  return type === 'string' ? numberWithCommas(sum) : sum;
};

export const calculateTotalPaid = (list: any, type: string = 'string') => {
  let sum = 0;
  list.forEach((item: any) => {
    sum += item.totalPaid;
  });

  return type === 'string' ? numberWithCommas(sum) : sum;
};

export const getNumberWithCommas = (x: string) => {
  return +x.replaceAll(',', '');
};

export const setResponseAction = (err: any, status?: string, message?: string) => {
  return (dispatch: any) => {
    dispatch(
      appActions.setHttpResponse({
        statusCode: err?.response?.status,
        status: err?.response?.data?.status || status,
        message: err?.response?.data?.message || message,
      })
    );
  };
};

export const catchErrorMiddleware = (err: any, status?: string, message?: string) => {
  return (dispatch: any) => {
    dispatch(
      appActions.setHttpResponse({
        statusCode: err?.response?.status,
        status: err?.response?.data?.status || status,
        message: err?.response?.data?.message || message,
      })
    );
  };
};

export const exportExcel = (Dataset: any, fileName: String) => {
  /* Create a worksheet */
  var ws = XLSX.utils.json_to_sheet(Dataset);

  /* Create a new empty workbook, then add the worksheet */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'sheet1');

  /* Generate xlsx files */
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

// Function to export excel
export const exportStockExcel = (jsonData: any, sheets: any, excelName = 'Sheet') => {
  /* Create a new empty workbook, then add the worksheet */
  var wb = XLSX.utils.book_new();
  for (var i = 0; i < sheets.length; i++) {
    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(jsonData[i]);

    XLSX.utils.book_append_sheet(wb, ws, `${sheets[i].name}`);
  }

  // /* Generate xlsx files */
  XLSX.writeFile(wb, `${excelName}.xlsx`);
};

export const generateGreetings = () => {
  var currentHour = +moment().format('HH');

  if (currentHour >= 3 && currentHour < 12) {
    return i18n.t('goodMorning');
  } else if (currentHour >= 12 && currentHour < 17) {
    return i18n.t('goodAfterNoon');
  } else if (currentHour >= 17 && currentHour < 20) {
    return i18n.t('goodEvening');
  } else if (currentHour >= 20 && currentHour < 3) {
    return i18n.t('Good Night');
  } else {
    return 'Hello';
  }
};

export const requestFailed = (res: any) => {
  return (
    res.response?.data.status === 'fail' || res.response?.data.status === 'error' || res.message === 'Network Error'
  );
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: '#91adbf',
    },
    children:
      name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`,
  };
};

export const initials = (name: string) => {
  return name.split(' ').length > 1
    ? `${name.split(' ')[0][0]}${name?.split(' ')[1][0] ?? ''}`
    : `${name.split(' ')[0][0]}`;
};

export const roundToTwoDecimalPlaces = (number: number) => {
  // Check if the number has decimal places
  if (Number.isInteger(number)) {
    return number; // If it's an integer, return as is
  } else {
    return parseFloat(number.toFixed(2)); // If it has decimal places, round to 2 decimal places
  }
};

interface MyData {
  date: string;
  action: string;
  quantity: number;
}

export const sortByDateDescending = (array: MyData[]): MyData[] => {
  // Custom comparison function for sorting by date in descending order
  function compareDates(a: MyData, b: MyData): number {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Compare dates in descending order
    return dateB.getTime() - dateA.getTime();
  }

  // Sort the array using the compareDates function
  array.sort(compareDates);

  return array;
};

export const calculateAccumulatedBalance = (transactions: any[]): number[] => {
  let accumulatedBalance: number[] = [];

  let balance: number = 0;

  for (let i = transactions.length - 1; i >= 0; i--) {
    const transaction: any = transactions[i];

    if (transaction.action === 'purchase' || transaction.action === 'adj_in') {
      balance += transaction.quantity;
    } else if (transaction.action === 'sales' || transaction.action === 'adj_out') {
      balance -= transaction.quantity;
    }

    accumulatedBalance.unshift(balance);
  }

  return accumulatedBalance;
};
