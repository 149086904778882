import axios from 'axios';
import { SERVER_URL } from '../../utils/constants';

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${SERVER_URL}/project/users/login`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const signUpUserService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/project/users/signup`,
      data,
    });

    return res.data;
  } catch (err: any) {
    return err;
  }
};


