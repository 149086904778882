import { Manufacturer } from "./manufacturers";

export interface shopItemColumn {
  id: 'creationDate' | 'name' | 'manufacturer' | 'buyingPrice' | 'sellingPrice' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopItemColumns: shopItemColumn[] = [
  { id: 'creationDate', label: 'Creation Date', align: 'center' },
  { id: 'name', label: 'Item Name', align: 'center' },
  { id: 'manufacturer', label: 'Manufacturer', align: 'center' },
  { id: 'buyingPrice', label: 'Buying Price', align: 'center' },
  { id: 'sellingPrice', label: 'Selling Price', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopItemData {
  id: string;
  creationDate: string;
  name: string;
  manufacturer: string;
  buyingPrice: string;
  sellingPrice: string;
  action: any;
}

export interface ItemDetails {
  measurement: string,
  quantity: number
}

export interface Item {
  details: ItemDetails,
  _id: string,
  name: string,
  buyingPrice: number,
  sellingPrice: number,
  manufacturer: Manufacturer,
  project: string,
  barCode: string,
  isDetailed: boolean,
  bigUnit: string,
  image: {
    url: string;
    cloudinary_id: string;
  },
  stock: number,
  isActive: boolean,
  createdAt: string,
  updatedAt: string
}

export interface ItemHistoryColumn {
  id: 'date' | 'action' | 'quantity' | 'stockBalance';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const ItemHistoryColumns: ItemHistoryColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'action', label: 'Transaction', align: 'center' },
  { id: 'quantity', label: 'Quantity', align: 'center' },
  { id: 'stockBalance', label: 'Stock Balance', align: 'center' },
];