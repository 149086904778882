import { ItemDetails } from './shopItems';

export interface shopStockColumn {
  id: 'date' | 'item' | 'openingStock' | 'purchases' | 'sales' | 'closingStock' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopStockColumns: shopStockColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'item', label: 'Item Name', align: 'center' },
  { id: 'openingStock', label: 'Opening Stock', align: 'center' },
  { id: 'purchases', label: 'Purchases', align: 'center' },
  { id: 'sales', label: 'Sales', align: 'center' },
  { id: 'closingStock', label: 'Closing Stock', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopStockData {
  id: string;
  date: string;
  item: string;
  openingStock: number;
  purchases: number;
  sales: number;
  closingStock: number;
  action: any;
}

export interface Stock {
  _id: string;
  name: string;
  details: ItemDetails;
  openingStock: number;
  date: string;
  purchases: number;
  sales: number;
  closingStock: number;
}
