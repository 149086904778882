import { Typography } from 'antd';
import { ShopState } from '../../../store/shop';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { calculateAccumulatedBalance, renderMoney, sortByDateDescending } from '../../../utils/functions';
import React, { useEffect, useState } from 'react';
import { getAdjustmentsAction } from '../../../store/shop/adjustments/adjustmentsActions';
import Storage from '../../../utils/Storage';
import { Adjustment } from '../../../interfaces/shop/adjustment';
import { Menu, MenuItem } from '@mui/material';
import { AppState, appActions } from '../../../store/app';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConfirmModal from '../../../components/ConfirmModal';
import { deleteAction } from '../../../store/shop/actions/globalActions';
import { deleteAdjustmentsService } from '../../../store/shop/adjustments/adjustmentsService';
import AdjustStock from '../stock/AdjustStock';
import LoadingContainer from '../../../components/LoadingContainer';
import NoData from '../../../components/NoData';
import items, { ItemsState } from '../../../store/items';

const ItemAdjustments = () => {
  const shopState: ShopState = useSelector((state: any) => state.shop);
  const appState: AppState = useSelector((state: any) => state.app);
  const itemsState: ItemsState = useSelector((state: any) => state.items);
  let stockBalance: number[] = [];
  const [adjustStock, setAdjustStock] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [adjustment, setAdjustment] = useState<Adjustment | null>(null);
  const open = Boolean(anchorEl);
  const [fetchedAdjustments, setFetchedAdjustments] = useState(false);

  const { t } = useTranslation();

  const token = Storage.getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetchedAdjustments) {
      dispatch(getAdjustmentsAction(`?item=${itemsState?.item?._id}&sort=-date`, token));
      setFetchedAdjustments(true);
    }
  }, [fetchedAdjustments]);

  if (itemsState?.item?.history?.length > 0) {
    stockBalance = calculateAccumulatedBalance(itemsState?.item?.history);
  }

  const deleteAdjustmentHandler = () => {
    if (adjustment) {
      dispatch(
        deleteAction(deleteAdjustmentsService, adjustment._id, token, 'Adjustment', () => setFetchedAdjustments(false))
      );
    }
  };

  return (
    <div className="w-[70%] mx-auto">
      <Typography className="font-bold text-2xl text-blue-500 mb-2">{itemsState.item?.name}</Typography>
      <div className="flex mt-6 py-2 font-semibold">
        <div className="basis-1/12 text-center">Nbr</div>
        <div className="basis-3/12 text-center">Date</div>
        <div className="basis-3/12 text-center">Type</div>
        <div className="basis-3/12 text-center">Quantity</div>
        <div className="basis-2/12 text-center">Action</div>
      </div>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          <div className="h-[47vh] overflow-y-scroll border">
            {shopState?.adjustments?.length > 0 &&
              shopState?.adjustments?.map((adjustment: Adjustment, index: number) => (
                <div
                  key={index}
                  style={{
                    background: `${index % 2 === 0 ? '#f5faff' : 'white'}`,
                  }}
                  className="flex py-2 border-b-2 border-gray-100"
                >
                  <div className="basis-1/12 text-center">{index + 1}</div>
                  <div className="basis-3/12 text-center">{adjustment.date.substring(0, 10)}</div>
                  <div className="basis-3/12 text-center">{t(adjustment.type)}</div>
                  <div
                    className="basis-3/12 text-center"
                    style={{
                      color: adjustment.type === 'in' ? 'green' : 'red',
                    }}
                  >
                    {adjustment.quantity}
                  </div>
                  <button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAdjustment(adjustment);
                      setAnchorEl(event.currentTarget);
                    }}
                    className="basis-2/12 text-center cursor-pointer border-none bg-transparent"
                  >
                    •••
                  </button>
                </div>
              ))}
            {shopState?.adjustments?.length === 0 && <NoData />}
          </div>
          <div className="text-center">
            <button
              className="bg-blue-600 text-white rounded-md mt-5 py-1 px-8 text-sm mx-auto"
              onClick={() => setAdjustStock(true)}
            >
              ADJUST
            </button>
          </div>
        </>
      )}
      {adjustStock && <AdjustStock open={adjustStock} close={() => setAdjustStock(false)} item={itemsState?.item} />}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={deleteAdjustmentHandler}
          content={`Please confirm to delete the selected adjustment`}
        />
      )}
      {open && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={appActions.closeForm}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(appActions.setIsDeleting(true));
            }}
          >
            <DeleteIcon />
            <Typography>{t('delete')}</Typography>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default ItemAdjustments;
