import store from 'store';
import { User } from '../interfaces/Project/user';

class Storage {
  public static getToken() {
    return store.get('x-auth-token');
  }

  public static getProject() {
    return store.get('project');
  }

  public static getUser(): User {
    return store.get('user');
  }
}

export default Storage;
