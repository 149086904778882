import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from '../../../interfaces/shop/suppliers';
import { appActions } from '../../../store/app';
import { shopActions } from '../../../store/shop';
import { addAction } from '../../../store/shop/actions/globalActions';
import { addManufacturersService } from '../../../store/shop/manufacturers/manufacturersService';
import { addShopSupplierService } from '../../../store/shop/suppliers/suppliersService';
import Storage from '../../../utils/Storage';
import GenericForm from '../../../components/GenericForm';

export default function NewManufacturer() {
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const [supplier, setSupplier] = useState({} as Supplier);
  const appState = useSelector((state: any) => state.app);

  const newManufacturerElements = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: 'Type of Stock i.e. BASCO',
      rules: [{ required: true, message: 'Please enter the Name' }],
    },
    {
      label: 'Supplier',
      name: 'supplier',
      type: 'select',
      placeholder: 'Enter Customer Supplier Number',
    },
  ];

  const addManufacturerHandler = async (data: any) => {
    data.project = Storage.getProject()._id;
    delete data.supplier;
    if (supplier.name) data.supplier = data.supplier._id;

    dispatch(
      addAction(addManufacturersService, data, token, () => dispatch(shopActions.setFetchedManufacturers(false)))
    );
  };

  return (
    <GenericForm
      title="New Manufacturer"
      onSubmit={addManufacturerHandler}
      formElements={newManufacturerElements}
      style="vertical"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: '' }));
      }}
    />
  );
}
