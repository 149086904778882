import { SERVER_URL } from '../../../utils/constants';
import HttpRequest from '../../../utils/HttpRequest';

export const getManufacturersService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop/manufacturers${query}`, token);
};

export const addManufacturersService = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/shop/manufacturers`, data, token);
};

export const updateManufacturersService = async (manufacturerId: string, data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/shop/manufacturers/${manufacturerId}`, data, token);
};

export const deleteManufacturersService = async (manufacturerId: string, token: string) => {
  return HttpRequest.delete(`${SERVER_URL}/shop/manufacturers/${manufacturerId}`, token);
};
