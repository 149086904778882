import { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Grid, Paper } from '@mui/material';
import store from 'store';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './index.module.scss';
import ReceiptItem from './ReceiptItem';
import { calculateTotalPrice, initials, numberWithCommas } from '../utils/functions';
import Storage from '../utils/Storage';
import { Typography } from 'antd';

const ReceiptComponent = (props: any) => {
  const project = Storage.getProject();
  const [date, setDate] = useState(new Date().toLocaleString().substring(0, 17));
  const newPurchase = useSelector((state: any) => state.shop.newPurchase);
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <script src="https://cdn.tailwindcss.com"></script>
            <style>
              @media print {
                @page {
                  size: auto; /* Adjusting for A4 paper size */
                  margin: 0; /* For removing the default margin */
                }
                body {
                  width: 88mm;
                  height: 297mm;
                  margin: 5mm 5mm 5mm 25mm;
                  text-align: center;
                }
                .title {
                  text-style: italic;
                  text-transform: uppercase;
              }
              #itemsheading {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            }
            #itemname {
              flex-basis: 40%;
              text-style: italic;
              text-transform: uppercase;
            }
            #itemspec {
            flex-basis: 20%;
      }
            #totalprice {
            display: flex;
              align-items: center;
              justify-content: space-between;
              margin-left: 20px;
              margin-right: 20px;
            }
      }
            </style>
          </head>
          <body>
            <h1>Welcome to our Shop</h1>
            <h1>${project?.name}</h1>
            <h4>Tel: ${project?.contact ?? store.get('user').company.contact}</h4>
            <h4>Tin: ${project?.tinNumber ?? store.get('user').company.tinNumber}</h4>
            <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
            <h4 class="title"}>${props?.title.toLowerCase() == 'sale' ? 'Customer' : 'Supplier'} Tin: ${
        props?.tin ?? 'N/A'
      }</h4>
            <h4 class="title"}>${props?.title.toLowerCase() == 'sale' ? 'Customer' : 'Supplier'} Name: ${
        props?.name ?? 'N/A'
      }</h4>
            <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
            <h4 class="title"}>${props?.title} Receipt</h4>
            <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
            <p id="itemsheading"}><span id="itemname">item</span><span id="itemspec">Qty</span><span id="itemspec">U.Price</span><span id="itemspec">Total</span></p>
            ${props?.list
              ?.map((item: any, index: number) =>
                ReactDOMServer.renderToString(
                  <p id="itemsheading">
                    <span id="itemname">{item.item?.name}</span>
                    <span id="itemspec">{item.quantity}</span>
                    <span id="itemspec">{item.unitPrice}</span>
                    <span id="itemspec">{item.quantity * item.unitPrice}</span>
                  </p>
                )
              )
              .join('')}
              <p>-</p>
              <p id="totalprice"><span>Total Price :</span> <span>${calculateTotalPrice(props.list)}</span></p>
            <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
            <p> SDC INFORMATION </p>
            <p>Date: ${date.substring(0, 10)}         Time: ${date.substring(11, 19)}</p>
            <p>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</p>
            <p>Thank you for choosing our shop!</p>
            <p>End of Legal Receipt\n\n\n\n\n</p>
            <p>-</p>
            <p>-</p>
          </body>

        </html>
      `);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.onafterprint = function () {
          printWindow.close();
        };
      };
    }
  };

  return (
    <>
      <Box className={styles.purchasesReceipt}>
        <h4 className={styles.receipt_title}>{props?.title} Receipt</h4>
        <Paper elevation={2} className={styles.purchasesReceipt_header}>
          <Box>
            {project?.logo?.url && <img className="h-[5rem] w-[5rem]" alt="logo" src={project?.logo?.url} />}
            {!project?.logo?.url && (
              <div className="mx-auto">
                <Typography className="font-bold text-primaryPurple text-[2.5rem] ms-3">
                  {initials(project?.name)}
                </Typography>
              </div>
            )}
          </Box>
          <Box>
            <Typography className={`${styles.centerText} ${styles.receipt_text}`}>{project?.name}</Typography>
            <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
              TEL: {project?.contact ?? store.get('user').company.contact}
            </Typography>
            <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
              TIN: {project?.tinNumber ?? store.get('user').company.tinNumber}
            </Typography>
          </Box>
        </Paper>
        <Paper elevation={2} className={styles.receipt_table}>
          {newPurchase?.items[0]?.supplier?._id && (
            <>
              <Typography className={`${styles.centerText} ${styles.receipt_text}`}>
                Supplier: {newPurchase?.items[0]?.supplier?.name}
              </Typography>
            </>
          )}
          <Paper sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}>Item</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}>Qty</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}>
                  U.Price
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={`${styles.centerText} ${styles.bold} ${styles.receipt_text}`}>Total</Typography>
              </Grid>
            </Grid>
          </Paper>
          {props?.list?.map((item: any, index: number) => (
            <ReceiptItem
              key={index}
              index={index}
              onItemClicked={props.onItemClicked}
              item={item.item?.name}
              quantity={item.quantity}
              price={item.unitPrice}
            />
          ))}
          <Typography className={styles.receiptTotal}>Total: {calculateTotalPrice(props.list)} Rwf</Typography>
          {/* </Box> */}
        </Paper>
        <Typography className={`${styles.centerText} ${styles.receipt_text}`}>Done at {date}</Typography>
      </Box>
      <Box className={styles.receiptActions}>
        <Button
          className={styles.receipt_print_btn}
          onClick={() => handlePrint()}
          startIcon={<PrintIcon />}
          variant="contained"
        >
          <Typography className="text-blue-700">Print</Typography>
        </Button>
        {!isSubmitting && !props?.updating && (
          <Button
            className={styles.receipt_save_btn}
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => {
              props?.onSave();
            }}
            variant="contained"
          >
            <Typography className="text-white">Save</Typography>
          </Button>
        )}
        {isSubmitting && (
          <Button className={styles.receipt_save_btn} variant="contained">
            <CircularProgress size={30} color="inherit" className={styles.circularProgress} />
          </Button>
        )}
      </Box>
    </>
  );
};

export default ReceiptComponent;
